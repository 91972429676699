import classes from "../PublishedLinks.module.css";
import ImageImport from "../../../../../utils/ImageImports";
import { useContext } from "react";
import { Store } from "../../../../../Store";
const PublishedLink = ({ id, title, handleEditPublishLink, url }: { id: number; title: string; url: string; handleEditPublishLink(id: number): void }) => {
  const { mic, pencil, link } = ImageImport;
  const { header: {data: { user },},} = useContext(Store);

  return (
    <div className={classes.publishedLink}>
      <div className={`${classes.header} ${classes.mb20}`}>
        <a className={classes.inlineflex} style={{ color: "#666", textDecoration: "none" }} href={`${url.indexOf("http") < 0 ? "https://" : ""}${url}`} target="_blank" rel="noreferrer noopener">
          <span className={classes.iconwrapper}>
            <img src={link} alt="link" />
          </span>
          <span className={classes.title}>{title}</span>
        </a>

        <div className="">{user.portalAdmin && user.mpInternal && <img src={pencil} onClick={() => handleEditPublishLink(id)} style={{ width: "15px", cursor: "pointer" }} alt="edit" />}</div>
      </div>
    </div>
  );
};
export default PublishedLink;
