import React, { CSSProperties, HTMLProps, useEffect, useRef, useState } from 'react';
import useWindowSize from "../../customHooks/useWindowSize";
import './Tooltip.css'
import ReactDatePicker from 'react-datepicker';

export interface tooltipActionItem {
    img?: string;
    text: string;
    onClick(e: React.MouseEvent, data?: any): void;
    showOnlyOn?: ("BulkActions" | "ItemActions")[];
    removeWhenDeleted?: boolean;
}

interface toolTipProps extends HTMLProps<HTMLDivElement> {
  shown: boolean;
  setShown?: React.Dispatch<React.SetStateAction<boolean>>;
  data?: any,
  listItems: tooltipActionItem[],
  parentId?: string
}

function BulkActionTooltip({shown, setShown, data, parentId, listItems, ...props}: toolTipProps) {

	const { isDesktop } = useWindowSize();
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [styles, setStyles] = useState<CSSProperties>({
        right: isDesktop ? 0 : 30
    });

    useEffect(() => {
        const handleClick = (e: any) => {
            if (parentId && e.target.closest(`#${parentId}`) === null) {
                setShown?.(false);
            }
        };
        document.body.addEventListener('click', handleClick);
        return () => document.body.removeEventListener('click', handleClick);
    }, [parentId]);

    useEffect(() => {
        if (shown && tooltipRef.current) {
            if (parentId) {
                const parentRect = document.getElementById(parentId)?.getBoundingClientRect();
                if (parentRect) {
                    let left = parentRect.left;
                    if (tooltipRef.current.clientWidth + left > window.innerWidth) {
                        left = left + (document.body.clientWidth - (tooltipRef.current.clientWidth + left) - 30)
                    }
                    setStyles({
                        left,
                        top: parentRect.bottom + window.scrollY + 4,
                        marginTop: 'unset',
                        marginLeft: 'unset'
                    });
                } else {
                    const rect = tooltipRef.current.getBoundingClientRect();
                    if (rect.x - 10 < 0) {
                        setStyles({ left: 0 })
                    }
                }
            }
        }
    }, [shown, parentId]);

    return (
        <>
            {shown && (
                <div ref={tooltipRef} {...props} className='tooltip-actions' style={styles}>
                    {listItems.map((listItem, i) => (
                        <div key={i} className="tooltip-action-item" onClick={e => listItem.onClick(e, data)}>
                            {listItem.img && <img alt={listItem.text} src={listItem.img} />}
                            <span>{listItem.text}</span>
                        </div>
                    ))}
            </div>
            )}
        </>
    );
}

export default BulkActionTooltip;
