import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { Store } from "../../../Store";
import Button from "../../../components/Button/Button";
import PlainDragDropField from "../../../components/UI/Form/DragDropField/PlainDragDropField";
import { DropDownOptionItem, PlainDropDown } from "../../../components/UI/Form/DropDownSelect/PlainDropDown";
import MiniTooltip from "../../../components/UI/MiniTooltip/MiniTooltip";
import ImageImports from "../../../utils/ImageImports";
import { DocumentGroup } from "../../../utils/interface";
import { useGetFile_TBReport } from "../../DocumentList/ReportsHttpServices";
import { AddDocumentRequiredProps } from "../DocumentsContainer";
import classes from "./Document.module.css";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";

const { trash, tooltip } = ImageImports;

const AddEditDocument: FunctionComponent<AddDocumentRequiredProps> = ({ documentModule, documentGroups, onSave, isEdit, setDeleteDocument, handleCloseModal }) => {
	const [reportName, setReportName] = useState<string>("");
	const [year, setYear] = useState<number>();
	const [documentGroup, setDocumentGroup] = useState<DocumentGroup>();
	const [attachment, setAttachment] = useState<File>();
  const [editedFlag, setEditedFlag] = useState<boolean>(false);
  const {
    plantConfig, 
    document,
    showError,
    header: {
      data: { user },
    },
  } = useContext(Store);

	useEffect(() => {
		if (!isEdit)
			setYear(new Date().getFullYear());
	}, []);

	useEffect(() => {
		setReportName(attachment?.name || "");
	}, [attachment]);

	useEffect(() => {
		if (document) {
			setDocumentGroup(documentGroups.find((dg) => dg.id === document.itemTypeId));
			if (document.year) {
				setYear(document.year);
      }
		}
	}, [document]);

	const { isFetching: isFetchingFile, isLoading: isLoadingFile } = useGetFile_TBReport(document.globalItemId, {
		enabled: document.globalItemId > 0 && isEdit,
		onSuccess: (data: Blob) => {
			setAttachment(new File([data], document.displayName, { type: data.type }));
		},
		onError: (error: any) => {},
	});

	const handleDocumentGroupSelection = (selectedOption: DropDownOptionItem) => {
		setDocumentGroup(documentGroups.find((dg) => dg.id === selectedOption.id));
		setEditedFlag(true);
	};

	const saveReport = () => {
		if (attachment && documentGroup) {
			const formData = new FormData();
			formData.append("BaseOrgId", `${plantConfig.data.baseOrgId}`);
			if (!(isEdit && reportName === document.displayName)) {
				formData.append("File", attachment);
			}
			formData.append("ItemTypeId", `${documentGroup.id}`);
			formData.append("DisplayName", reportName);
			formData.append("Year", year?.toString() || "");
			formData.append("accessType", documentGroup.accessType?.toString() || "");
			if (isEdit && document) {
				onSave([document.globalItemId, formData]);
			} else {
				onSave(formData);
			}
		}
	};

	return (
		<div className={`${classes.flex_column} ${classes.add_document_form}`}>
      {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
			<div className={`flex flex-col md:flex-row ${classes.add_document_field}`}>
				<span className={classes.field_label}>Document Name*</span>
				<input type="text" value={reportName} disabled onChange={(e) => setReportName(e.target.value)} />
			</div>
			<div className={`flex flex-col md:flex-row ${classes.add_document_field}`}>
				<span className={classes.field_label}>Document Group*</span>

				<PlainDropDown
					value={documentGroup?.id || false}
					className={classes.select_container}
					selectClass={`${classes.flex_row} ${classes.select}`}
					optionsClass={`${classes.flex_column} ${classes.select_options}`}
					options={[{ id: false, name: "Select document group" }, ...documentGroups]}
					onSelection={handleDocumentGroupSelection}
				/>
			</div>
			<div className={`flex flex-col md:flex-row ${classes.add_document_field}`}>
				<span style={{ display: "flex", alignItems: "center" }} className={classes.field_label}>
					Year*
					<span className={classes.cursor_pointer}>
						<MiniTooltip text={"Please enter the year this document was created."} space={15} placement={"top"}>
							<img src={tooltip} style={{ maxWidth: "16px", display: "block", marginLeft: "6px" }} className={classes.tooltip_icon} alt="tool tip" />
						</MiniTooltip>
					</span>
				</span>
				<input type="number" min="0" step="1" className={classes.attachment_name} value={year || ''} onChange={(e) => { setYear(parseInt(e.currentTarget.value)); setEditedFlag(true); }}/>
			</div>
			<div className={`${classes.flex_row} ${classes.add_document_field}`}>
				<span className={classes.field_label}>Attachment*</span>
				{attachment && (
					<>
						<input type="text" disabled className={!document.isDeleted ? classes.attachment_name : ''} defaultValue={reportName} />
            {!document.isDeleted && 
              <img className={classes.delete_file} onClick={() => setAttachment(undefined)} src={trash} alt="Remove Attachment" />
            }
					</>
				)}
			</div>
			<PlainDragDropField onChange={e => {setAttachment(e); setEditedFlag(true);}} attachment={attachment} disabled={document.isDeleted} fileLoading={isFetchingFile || isLoadingFile} />
			<div className={classes.warning_text}>
				<p>Accepted file types: PDF, JPG, PNG; Max file size: 1 GB</p>
				{isEdit ? (
					<p>WARNING: The document filename will automatically populate the Report Name field. This CANNOT be modified after clicking on Save Document.</p>
				) : (
					<p>WARNING: The document filename will automatically populate the Report Name field. This CANNOT be modified after clicking on Add Document.</p>
				)}
			</div>
      <div className="flex flex-col md:flex-row items-center">
        <div className="flex-grow-1 flex flex-col md:flex-row gap-x-4">
          {isEdit ? (
            <>
              <Button className={`darkBlue`} disabled={!editedFlag || !(reportName.length > 0 && documentGroup && attachment && year) || (isFetchingFile || isLoadingFile)} text="Save Changes" onClick={saveReport} />
              {user?.mpInternal && user?.portalAdmin &&
                <Button className={`redDelete`} text="Delete" onClick={() => setDeleteDocument(true)} disabled={isFetchingFile || isLoadingFile || document.isDeleted} />
              }
            </>
          ) : (
            <>
              <Button className={`darkBlue`} disabled={!(reportName.length > 0 && documentGroup && attachment && year)} text="Add Document" onClick={saveReport} />
              <Button className={`whiteBtn`} text="Cancel" onClick={handleCloseModal} />
            </>
          )}
        </div>
        <span className="justify-center md:justify-end text-sm font-light">*Required field</span>
      </div>
		</div>
	);
};

export default AddEditDocument;
