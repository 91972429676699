
import { useContext } from "react";
import { Store } from "../../../../../Store";
import ImageImport from "../../../../../utils/ImageImports";
import { ContactsProps } from "../../../../../utils/interface";
import ContactToolTipData from "../ContactToolTipData/ContactToolTipData";
import classes from "../Contacts.module.css";
const Contact = ({contact,handleEditContact}:ContactsProps) => {
    const { mic, pencil } = ImageImport;
    const { toggleTooltip, setToolTipContent,header:{data:{user}} } = useContext(Store);
    return (
        <div className={classes.contact}>
            <div className={`${classes.header} ${classes.mb20}`}>
                <div
                className="text-dark-blue font-bold hover:cursor-pointer"
                onBlur={() => {
                    if (toggleTooltip !== undefined) {
                      toggleTooltip(false);
                    }
                  }}
                  tabIndex={1}
                  onClick={() => {
                    if (toggleTooltip !== undefined) {
                      toggleTooltip(true);
                      setToolTipContent && setToolTipContent(<ContactToolTipData data={contact} />);
                    }
                  }}
                
                
                >{contact.firstName} {contact.lastName}</div>
                <div className="">
                  {
                    user.portalAdmin && user.mpInternal && <img  src={pencil} onClick={() => handleEditContact(contact.id)} style={{ width: "15px" ,cursor:"pointer"}} alt="edit" />
                  }
                    
                </div>
            </div>
            <div className={classes.email}>
                <strong>E-mail:</strong> <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </div>
        </div>
    )
}
export default Contact;