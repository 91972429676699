import React, { Dispatch, useContext, useEffect, useState } from "react";

import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useQueryClient } from "react-query";
import { Store } from "../../../Store";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import { CPApiResponse, deleteInterface, FormDataPortalUpdate, PortalUpdateData } from "../../../utils/interface";

import { useOnError } from "../../../customHooks/useOnError";

import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import ModalGlobal from "../../../components/UI/Modal/ModalGlobal";

import SimpleDelete from "../../../components/FormsPages/FormDelete/SimpleDelete";
import Loader from "../../../components/Loader/Loader";
import DragDropFieldFormik from "../../../components/UI/Form/DragDropField/DragDropFieldFormik";
import TextInputField from "../../../components/UI/Form/TextInputField/TextInputField";
import { queryKeys } from "../../../react-query/queryKeys";
import ImageImports from "../../../utils/ImageImports";
import {
  useCreate_PortalUpdates,
  useDelete_PortalUpdates,
  useGet_PortalUpdate,
  useGetFile_PortalUpdate,
  useUpdate_PortalUpdates,
} from "../HttpServices/PortalUpdatesHttpServices";
import classes from "./Forms.module.css";

const AddEditInsuranceUpdates = ({ isEdit, modaltitle, modaltitlename, id, portalUpdateData, setPortalUpdateData }: PublishedLinkAddEditProps) => {
  const { isModal, setIsModal, showError } = useContext(Store);
  const queryClient = useQueryClient();

  const setErrorData = useOnError();
  const [attachment, setAttachment] = useState<File | Blob>();
  const { trash } = ImageImports;

  const [PortalUpdateID, setPortalUpdateId] = useState<number>(0);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [imgBase64, setImgBase64] = useState("");
  
  const { mutate: CreatePortalUpdate, isLoading: isCreating } = useCreate_PortalUpdates();
  const { mutate: UpdateePortalUpdate, isLoading: isUpdating } = useUpdate_PortalUpdates();
  const { mutate: DeletePortalUpdate, isLoading: isDeleting } = useDelete_PortalUpdates();

  const handleDeletePortalUpdate = () => {
    DeletePortalUpdate(portalUpdateData?.id, {
      onSuccess: (responseData: deleteInterface) => {
        queryClient.invalidateQueries(queryKeys.PortalUpdateApi);
        setIsModal!({
          ...CloseAllModal,
          isDeleteSuccess: {
            ...isModal.isDeleteSuccess,
            isInsuranceUpdate: true,
          },
        });
      },
      onError: (error: unknown) => {
        
        setErrorData(error);
      },
    });
  };

  useEffect(() => {
    if (id) {
      setPortalUpdateId(id);
    }
  }, [id]);

  // --------------------------------------------------------//

  const {isLoading} =  useGet_PortalUpdate(PortalUpdateID, {
    enabled: !!PortalUpdateID && isEdit && !isDelete,
    onSuccess: (AnnouncementData: CPApiResponse<PortalUpdateData>) => {
      setPortalUpdateData(AnnouncementData.data);
    },
    onError: (error: unknown) => {
      setErrorData(error);
    },
  });

  // --------------------------------------------------------//
  // --------------------------------------------------------//

  const {isFetching:isloadingImage} = useGetFile_PortalUpdate(PortalUpdateID, {
    enabled: !!PortalUpdateID && isEdit && !isDelete,
    onSuccess: (File: Blob) => {
      setAttachment(File);
    },
    onError: (error: unknown) => {
      setErrorData(error);
    },
  });

  // --------------------------------------------------------//

  const handleOnSubmit = (values: FormDataPortalUpdate, formikHelpers: FormikHelpers<FormDataPortalUpdate>) => {
    if (!isEdit) {
      CreatePortalUpdate(values, {
        onSuccess: (responseData: CPApiResponse<PortalUpdateData>) => {
          setPortalUpdateData(responseData.data);
          queryClient.invalidateQueries(queryKeys.PortalUpdateApi);
          formikHelpers.setSubmitting(false);
          setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isAddedSuccess: {
                ...isModal.isAddedSuccess,
                isInsuranceUpdate: true,
              },
            });
        },
        onError: (error: unknown) => {
          formikHelpers.setSubmitting(false);
          setErrorData(error);
        },
      });
    } else {
      UpdateePortalUpdate(values, {
        onSuccess: (responseData: CPApiResponse<PortalUpdateData>) => {
          setPortalUpdateData(responseData.data);
          queryClient.invalidateQueries(queryKeys.PortalUpdateApi);
          formikHelpers.setSubmitting(false);
          setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isEditedSuccess: {
                ...isModal.isEditedSuccess,
                isInsuranceUpdate: true,
              },
            });
        },
        onError: (error: unknown) => {
          formikHelpers.setSubmitting(false);
          setErrorData(error);
        },
      });
    }
  };

  let initialValues: FormDataPortalUpdate;
  let validationSchema;

  initialValues = {
    id: portalUpdateData.id,
    title: portalUpdateData.title,
    file: portalUpdateData.title,
    displayOrder: portalUpdateData.displayOrder,
    originalFileName: portalUpdateData.originalFileName,
  };
  validationSchema = Yup.object({
    title: Yup.string().required("This field is required"),
    displayOrder: Yup.number().positive().required("This field is required"),
  });

  if (!isEdit) {
    initialValues = {
      title: "",
      file: "",
      originalFileName: "",
      displayOrder: initialValues.displayOrder || 1,
    };
    validationSchema = Yup.object({
      title: Yup.string().required("This field is required"),
      displayOrder: Yup.number().positive().required("This field is required"),
      file: Yup.mixed().required(),
    });
  }

  return (
    <ModalGlobal modaltitle={modaltitle} modaltitlename={modaltitlename} isModalHeader={true}>
      {!isDelete ? (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
          {(formikProps) => {
            return (
              <>
                {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
                <Form>
                  {isLoading || isUpdating || isCreating || isloadingImage ? (
                    <Loader />
                  ) : (
                    <>
                      <div>
                        <TextInputField
                          name="title"
                          labelName="Update Title*"
                          disabled={true}
                          placeholder="Filename"
                          className=""
                          details="Document filename will auto-populate this field."
                          tooltip={{
                            text: "Please enter the title of the update here. (ex: 2021 Mid-Year Update)",
                          }}
                        />
                      </div>

                      <div>
                        <TextInputField
                          name="displayOrder"
                          labelName="Display Order*"
                          type="number"
                          className=""
                          placeholder="ex. 1"
                          tooltip={{
                            text: "The order in which this contact displays on the Insurance Portal homepage, relative to other updates in the list.",
                          }}
                        />
                      </div>
                      <div>
                        <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                          <span className={classes.input_label}>Attachment*</span>
                          {
                            isEdit && <div className={classes.form_group}>
                            <input
                              type="text"
                              name="originalFileName"
                              disabled
                              className={classes.input}
                              style={{}}
                              value={formikProps.values.title}
                            />
                            <img
                              className={classes.deleteFile}
                              onClick={() => {
                                formikProps.setFieldValue("title", "");
                                setAttachment(undefined);
                                setImgBase64("");
                              }}
                              src={trash}
                              alt="Remove Attachment"
                            />
                          </div>
                          }
                          
                        </div>
                        <DragDropFieldFormik
                          name="file"
                          onChange={setAttachment}
                          displayName={"title"}
                          previewBase24={imgBase64}
                          attachment={attachment}
                          isEdit={isEdit ? true : false}
                        />
                        <div className={`${classes.form_field_row}`}>
                          <div className={classes.warning_text}>
                            <div>Accepted file types: .PDF, .DOC, .XLSX, .JPG, .PNG</div>
                            <div>Maximum file upload size: 2 MB</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="flex flex-col md:flex-row items-center">
                    <div className="flex-grow-1 flex flex-col md:flex-row gap-x-4">
                      {isEdit ? (
                        <>
                          <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                            Save Changes
                          </button>
                          <button type="button" onClick={() => setIsDelete(true)} className="primaryBtn redDelete">
                            Delete
                          </button>
                        </>
                      ) : (
                        <>
                          <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                            Add Update Document
                          </button>
                        </>
                      )}
                      <button type={"button"} className="primaryBtn whiteBtn" onClick={() => {setIsModal?.({ ...CloseAllModal })}}>
                        Cancel
                      </button>
                    </div>
                    <span className="justify-center md:justify-end text-sm font-light">*Required field</span>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      ) : (
        <SimpleDelete
          id={0}
          subDescription={portalUpdateData.title}
          description={`Are you sure you want to delete this update? This update will no longer be visible to Insurance Portal users. This cannot be undone.`}
          handleDelete={handleDeletePortalUpdate}
          isDeleting={isDeleting}
        />
      )}
    </ModalGlobal>
  );
};

interface PublishedLinkAddEditProps {
  modaltitle: string;
  modaltitlename: string;
  isEdit: boolean;
  id?: number;
  setPortalUpdateData: Dispatch<React.SetStateAction<PortalUpdateData>>;
  portalUpdateData: PortalUpdateData;
}

export default AddEditInsuranceUpdates;
