import React from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../customHooks/useWindowSize";
import { PrivacyPolicy } from "../../utils/globalVariables";
import "./Footer.css";

function Footer() {
  const { isMobile } = useWindowSize();
  return (
    <div className="card footer">
      <div className="column row-item">
        <div className=" flex flex-col md:flex-row column-item">
          <div className={`row-to-col md:w-1/2 basis-[33%] lg:basis-[33%]`}>
            <div className="col-item text-fade">
              <strong className="marBot5">Insurance Portal </strong>
            </div>
            <div className="col-item">
              <Link
                to={"/"}
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <span className="marBot5 text-white">Home</span>
              </Link>
            </div>
            <div className="col-item">
              <Link to={"documents/Documents"}>
                <span className="marBot5 text-white">Documents</span>
              </Link>
            </div>
          </div>
          <div className="row-item md:w-1/2 row-to-col">
            <div className="col-item text-fade">
              <strong className="marBot5">Mitsubishi Power Links</strong>
            </div>
            <div className="col-item">
              <a href="https://www.mhi.com/" target="_blank" rel="noreferrer">
                <span className="marBot5">Mitsubishi Heavy Industries</span>
              </a>
            </div>
            <div className="col-item">
              <a href="https://power.mhi.com/" target="_blank" rel="noreferrer">
                <span className="marBot5">Mitsubishi Power, Ltd.</span>
              </a>
            </div>
            <div className="col-item">
              <a href="https://power.mhi.com/group/aero/" target="_blank" rel="noreferrer">
                <span className="marBot5">Mitsubishi Power Aero LLC</span>
              </a>
            </div>
            <div className="col-item">
              <a href="https://power.mhi.com/regions/emea/" target="_blank" rel="noreferrer">
                <span className="marBot5">Mitsubishi Power Europe</span>
              </a>
            </div>
            <div className="col-item">
              <a href="https://power.mhi.com/regions/amer/" target="_blank" rel="noreferrer">
                <span className="marBot5">Mitsubishi Power Americas</span>
              </a>
            </div>
            <div className="col-item">
              <a href="https://power.mhi.com/regions/apac/" target="_blank" rel="noreferrer">
                <span className="marBot5">Mitsubishi Power Asia Pacific</span>
              </a>
            </div>
          </div>
        </div>
        {isMobile ? (
          <div className="">
            <div className="row-item row-to-col">
             
              <div className="col-item">
                <span>
                  <a target="_blank" rel="noreferrer" href={PrivacyPolicy}>
                    Privacy Policy
                  </a>
                </span>
              </div>
            </div>
            <div className="row-item row-to-col">
              <span style={{ color: "#9BB3BB" }}>© Mitsubishi Power All Rights Reserved</span>
            </div>
          </div>
        ) : (
          <div className="flex justify-between">
            <div className="marRight5">
              <span style={{ color: "#9BB3BB" }}>© Mitsubishi Power All Rights Reserved</span>
            </div>
            <div className="marRight5">
              <span>
                <a target="_blank" rel="noreferrer" href={PrivacyPolicy}>
                  Privacy Policy
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Footer;
