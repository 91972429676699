import React, { useContext, useState } from "react";
import FormSuccess from "../../../components/FormsPages/FormSuccess/FormSuccess";
import { Store } from "../../../Store";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import { AnnouncementArray, AnnouncementData } from "../../../utils/interface";
import AddEditAnnouncement from "../Forms/AddEditAnnouncement";
import Announcement from "./Announcement/Announcement";

export const Announcements = ({ announcements }: AnnouncementArray) => {
  const { showError, isModal, setIsModal } = useContext(Store);
  const [announcementData, setAnnouncementData] = useState<AnnouncementData>({
    id: 0,
    title: "",
    messageContent: "",
    displayOrder: 0,
    created: "",
    createdBy: "",
    modified: "",
    modifiedBy: "",
    userGroupId: 0,
  });
  const [announcementID, setAnnouncementID] = useState<number>(0);

  const handleAddAnnouncement = () => {
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isAdd: {
          ...isModal.isAdd,
          isAnnouncement: true,
        },
      });
  };

  const handleEditAnnouncement = (id: number) => {
    setAnnouncementID(id);
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isEdit: {
          ...isModal.isEdit,
          isAnnouncement: true,
        },
      });
  };

  return (
    <>
      {announcements.length > 0 ? (
        announcements.map((a) => (
          <Announcement
            key={a.id}
            id={a.id}
            title={a.title}
            date={a.created}
            handleEditAnnouncement={handleEditAnnouncement}
            description={a.messageContent}
          />
        ))
      ) : (
        <p style={{ margin: "1%" }}>No announcements to display</p>
      )}

      {/* Announcement Modals START */}
      {isModal.isAdd.isAnnouncement && (
        <AddEditAnnouncement
          modaltitle={"Post an Announcement"}
          announcementData={{...announcementData, displayOrder: announcements.length + 1}}
          setAnnouncementData={setAnnouncementData}
          modaltitlename={""}
          isEdit={false}
        />
      )}
      {isModal.isEdit.isAnnouncement && (
        <AddEditAnnouncement
          id={announcementID!}
          announcementData={announcementData}
          setAnnouncementData={setAnnouncementData}
          modaltitle={"Edit Announcement"}
          modaltitlename={""}
          isEdit={true}
        />
      )}
      {isModal.isAddedSuccess.isAnnouncement && (
        <FormSuccess
          modaltitlename={"Announcement Successfully Posted"}
          modaltitle={""}
          isTitleCenter={true}
          onClick={handleAddAnnouncement}
          buttonName="Post Another Announcement"
          comment={`The “${announcementData?.title}” announcement has been posted to the Insurance Portal.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={true}
        />
      )}
      {isModal.isEditedSuccess.isAnnouncement && (
        <FormSuccess
          modaltitlename={"Changes Saved"}
          modaltitle={""}
          isTitleCenter={true}
          comment={`The “${announcementData?.title}” announcement has been successfully updated.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {isModal.isDeleteSuccess.isAnnouncement && (
        <FormSuccess
          modaltitlename={"Announcement Successfully Deleted"}
          modaltitle={""}
          isTitleCenter={true}
          comment={`You successfully deleted the “${announcementData?.title}” announcement.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {/* Announcement Modals END */}
    </>
  );
};
