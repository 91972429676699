import axios, { AxiosResponse } from "axios";
import { apiDocuments } from "../../endpoints/endpoints";
import { CPApiResponse, PlantPermissionedReports, Document, DocumentGroup } from "../../utils/interface";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";
import { IPItemTypes } from "../../utils/IPItemTypes";

// interface starts here //
export interface DocumentModuleApiMethods<T> {
  getAll: (itemType?: DocumentGroup) => Promise<PlantPermissionedReports>;
  bulkDelete: (ids: number[], reason: string, accessType?: number) => Promise<boolean>;
  bulkMove: (ids: number[], newItemTypeId: number, accessType?: number) => Promise<boolean>;
}
// end interface //

export const DocumentsAPI: DocumentModuleApiMethods<Document> = {
  getAll: async (itemType?: DocumentGroup): Promise<PlantPermissionedReports> => {
    const response: AxiosResponse<CPApiResponse<Document[]>> = await axios(
      `${apiDocuments}${itemType?.accessType === IPItemTypes.IPDocument ? "" : "-la"}/list?ItemTypeId=${itemType?.id}`
    );
    const defaultPlantPermissions: Pick<PlantPermissionedReports, "canAdd" | "canAdmin" | "canDelete" | "canEdit" | "canView" | "items"> = {
      canAdd: true,
      canAdmin: true,
      canDelete: true,
      canEdit: true,
      canView: true,
      items: [],
    };
    return {
      ...defaultPlantPermissions,
      items: response.data.data.map(doc => ({...doc, accessType: itemType?.accessType})),
    };
  },
  bulkDelete: async (globalItemIds: number[], deletionReason: string, accessType?: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiDocuments}${accessType === IPItemTypes.IPDocument ? "" : "-la"}/BulkDelete`, {
      method: "DELETE",
      data: {
        globalItemIds,
        deletionReason,
      },
    });
    return handleAxiosResponse(response);
  },
  bulkMove: async (globalItemIds: number[], newItemTypeId: number, accessType?: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiDocuments}${accessType === IPItemTypes.IPDocument ? "" : "-la"}/Move`, {
      method: "POST",
      data: {
        globalItemIds,
        newItemTypeId,
      },
    });
    return handleAxiosResponse(response);
  },
};