import axios from "axios";
import { UseQueryOptions, useQuery, useMutation } from "react-query";
import { apiGetAllGroupPublishedLinks, apiPublishedLinks } from "../../../endpoints/endpoints";
import { queryKeys } from "../../../react-query/queryKeys";
import { CPApiResponse, deleteInterface, PublishedLinksData } from "../../../utils/interface";

interface PublishedLinksCurdMethods<A, B, D> {
  get: (id: number) => Promise<B>;
  getAll: () => Promise<A>;
  create: (formdata: any) => Promise<B>;
  update: (formdata: any) => Promise<B>;
  delete: (id: number) => Promise<D>;
}

export const PublishLinksApi: PublishedLinksCurdMethods<CPApiResponse<PublishedLinksData[]>, CPApiResponse<PublishedLinksData>, deleteInterface> = {
  get: async (id: number): Promise<CPApiResponse<PublishedLinksData>> => {
    const response = await axios({
      url: `${apiPublishedLinks}/${id}`,
      method: "GET",
    });
    return response.data;
  },
  getAll: async (): Promise<CPApiResponse<PublishedLinksData[]>> => {
    const response = await axios({
      url: `${apiGetAllGroupPublishedLinks}`,
      method: "GET",
    });
    return response.data;
  },
  create: async (data: any): Promise<CPApiResponse<PublishedLinksData>> => {
    const response = await axios({
      url: `${apiPublishedLinks}`,
      method: "POST",
      data: data,
    });
    return response.data;
  },
  update: async (data: any): Promise<CPApiResponse<PublishedLinksData>> => {
    const response = await axios({
      url: `${apiPublishedLinks}/${data.id}`,
      method: "PUT",
      data: data,
    });
    return response.data;
  },
  delete: async (id: number): Promise<deleteInterface> => {
    const response = await axios({
      url: `${apiPublishedLinks}/${id}`,
      method: "DELETE",
    });
    return response.data;
  },
};

export const useGetAll_PublishedLinks = (options: Omit<UseQueryOptions<CPApiResponse<PublishedLinksData[]>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<PublishedLinksData[]>>(queryKeys.PublishLinksApi, PublishLinksApi.getAll, {
    ...options,
  });
};

export const useGet_PublishedLink = (id: number, options: Omit<UseQueryOptions<CPApiResponse<PublishedLinksData>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<PublishedLinksData>>([queryKeys.PublishLinksApi, id], () => PublishLinksApi.get(id), {
    ...options,
  });
};


export const useCreate_PublishedLinks = () => {
  return useMutation(PublishLinksApi.create);
};

export const useUpdate_PublishedLinks = () => {
  return useMutation(PublishLinksApi.update);
};
export const useDelete_PublishedLinks = () => {
  return useMutation(PublishLinksApi.delete);
};
