import React, { useContext } from "react";
import { Store } from "../../../Store";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import Loader from "../../Loader/Loader";

function SimpleDelete({ id, description, subDescription, handleDelete, isDeleting }: FormIdObj) {
  const { setIsModal } = useContext(Store);

  return (
    <>
      {isDeleting ? (
        <Loader />
      ) : (
        <>
          <div className="flex align-center marginBot30">
            <div className="flex-grow-1">{description}</div>
          </div>
          {subDescription && <div style={{ color: "#666", marginBottom: "50px", fontSize: "14px" }}>{subDescription}</div>}
        </>
      )}

      <div className="flex flex-col md:flex-row items-center gap-x-4">
        <button type={"submit"} onClick={handleDelete} className="primaryBtn redDelete ">
          Yes, Delete
        </button>
        <button
          type={"button"}
          className="primaryBtn whiteBtn"
          onClick={() => {
            setIsModal!({
              ...CloseAllModal,
            });
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

interface FormIdObj {
  description: string;
  subDescription?: string;
  handleDelete(): void;
  id: number;
  isDeleting: boolean;
}
export default SimpleDelete;
