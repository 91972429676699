import React from 'react';
import spinner from "../../assets/loader.gif";
const Loader = (props:LoaderProps) => {


const style = {
    marginBottom: props.marginBottom,
    marginTop: props.marginTop,
}
  return (
    <div style={style} className="loader text-center"><img src={spinner} style={props.iconStyle} alt="loading"/> </div>
  )
}
interface LoaderProps {
    marginBottom: string;
    marginTop: string;
    iconStyle?: React.CSSProperties;
}

Loader.defaultProps = {
    marginBottom:"40px",
    marginTop:"0px"
}

export default Loader