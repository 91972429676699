
import { FunctionComponent, HTMLProps } from 'react';
import ImageImports from "../../../../utils/ImageImports";
import classes from './Checkbox.module.css';

const { checkbox, unchecked_checkbox, disabled_checkbox } = ImageImports

interface CheckboxProps extends HTMLProps<HTMLDivElement> {
    isChecked: boolean;
    onChange: () => void;
    label?: string;
    labelClass?: string;
    disabled?: boolean;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({isChecked, onChange, label, labelClass, disabled = false, ...props}) => (
    <div className={classes.checkmark_container} {...props}>
        <div className={classes.checkbox}>
            {/* <input type="checkbox" checked={isChecked} onChange={() => {}} />
            <span className={classes.checkmark} onClick={onChange} /> */}
            {disabled ?
              <img src={disabled_checkbox} className={"cursor-pointer"} alt="checkbox" style={{display: "inherit"}} />
            :
              <>
                <img src={checkbox} className={"cursor-pointer"} alt="checkbox" onClick={onChange} style={isChecked ? {display: "inherit"} : {display: "none"}} />
                <img src={unchecked_checkbox} className={"cursor-pointer"} alt="checkbox" onClick={onChange} style={!isChecked ? {display: "inherit"} : {display: "none"}} />
              </>
            }
            
            {label && (<span className={`${classes.label} ${labelClass}`} onClick={onChange}>{label}</span>)}
        </div>
    </div>
);