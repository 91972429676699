import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageImports from "../../utils/ImageImports";
import "./SearchGlobal.css";

function SearchGlobal(props: SearchGlobalProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchText, setSearchText] = useState<string>("");
  const { searchText: urlData } = useParams();
  const { magnifyGlass, closeImg } = ImageImports;

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onCickSearch = () => {
    var historyReplace = false;
    if (location.pathname.indexOf("/search") >= 0)
      historyReplace = true;
    if (searchText.trim().length > 0) {
      navigate(`search/${searchText}`, { replace: historyReplace });
    }
  };
  const onSubmitSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    var historyReplace = false;
    if (location.pathname.indexOf("/search") >= 0)
      historyReplace = true;
    if (searchText.trim().length > 0) {
      navigate(`search/${searchText}`, { replace: historyReplace });
    }
  };
  const clearSearch = () => {
    setSearchText("");
    const regex = /^\/search\//;
    if (regex.exec(location.pathname) !== null) {
      navigate(-1);
    }
  };

  useEffect(() => {
    const regex = /^\/search\//;
    if (regex.exec(location.pathname) === null) {
      setSearchText('');
    }
  }, [location]);

  useEffect(() => {
    if (urlData && urlData.length > 0 && searchText.length === 0) {
      setSearchText(urlData);
    }
  }, [urlData, searchText]);

  return (
    <div className="input-box keysearch" style={props.style}>
      <button onClick={onCickSearch} className="btn btn-outline-success search-icon" type="button">
        <img src={magnifyGlass} className="text-black" alt="search" />
      </button>
      <form onSubmit={onSubmitSearch}>
        <input type={"text"} className="form-control" name="searchText" value={searchText} id="searchStr" onChange={handleSearchText} placeholder={props.placeholder} />
      </form>

      {searchText && (
        <button className="btn btn-outline-success closeIcon" type="button" onClick={clearSearch}>
          <img src={closeImg} className="" alt="close" />
        </button>
      )}
    </div>
  );
}

interface SearchGlobalProps {
  placeholder: string;
  style?: React.CSSProperties;
}

SearchGlobal.defaultProps = {
  placeholder: "Keyword Search",
};

export default SearchGlobal;
