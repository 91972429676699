import { ChangeEvent, FunctionComponent, useContext, useState } from "react";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import { DocumentGroup, Document } from "../../utils/interface";
import classes from './DocumentList.module.css';
import {CloseAllModal} from "../../utils/CloseAllModal";

interface Props {
    documentGroupName: string;
    documentGroups: DocumentGroup[]
    documents: Document[];
    onClose: () => void;
    move: (from: string, to: DocumentGroup) => void;
}

const MoveDocumentModal: FunctionComponent<Props> = ({documentGroupName, documentGroups, documents, onClose, move}) => {
    const {selectedPlant, setIsModal} = useContext(Store);
    const [selectedGroup, setSelectedGroup] = useState<DocumentGroup>();
    const close = () => {        
        setIsModal && setIsModal!({ ...CloseAllModal });
    };

    const handleGroupSelection = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedGroup = documentGroups.find(d => d.name === e.target.value);
        setSelectedGroup(selectedGroup);
    };

    return (
        <ModalGlobal modaltitle={`Move (${documents.length}) Document(s)?`} isModalHeader={true} modalClose={() => {
            close();
            onClose();
        }}>
            <div className={`${classes.move_modal_body} ${classes.flex_column}`}>
                <p>Please select a new destination document group for the following {selectedPlant.name} document(s):</p>
                <ul>
                    {documents.map(doc => (
                        <li key={doc.globalItemId}>{doc.displayName}</li>
                    ))}
                </ul>
                <div className={`${classes.flex_row} ${classes.move_document_preposition}`}>
                    <span className={classes.move_document_preposition_label}>Move From</span>
                    <select value={documentGroupName} disabled>
                        <option value={documentGroupName}>{documentGroupName}</option>
                    </select>
                </div>
                <div className={`${classes.flex_row} ${classes.move_document_preposition}`}>
                    <span className={classes.move_document_preposition_label}>Move To*</span>
                    <select className={''} value={selectedGroup?.name} onChange={handleGroupSelection}>
                        <option value="">Select destination document group</option>
                        {documentGroups.filter(d => d.name !== documentGroupName).map(documentGroup => (
                            <option key={documentGroup.id} value={documentGroup.name}>{documentGroup.name}</option>
                        ))}
                    </select>
                </div>
                <div className={`${classes.flex_row} ${classes.move_document_footer}`}>
                    <div className={`${classes.flex_row} ${classes.move_document_footer_left}`}>
                        <Button className={`darkBlue`} disabled={!selectedGroup} text={"Move Documents"} onClick={() => {
                            close();
                            if (selectedGroup) {
                                move(documentGroupName, selectedGroup);
                            }
                        }} />
                        <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => {
                            close();
                            onClose();
                        }} />
                    </div>
                    <span>*Required Fields</span>
                </div>
            </div>
        </ModalGlobal>
    );
}

export default MoveDocumentModal;