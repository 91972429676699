import { AxiosError } from "axios";
import React, { CSSProperties, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import Tooltip from "../../components/PopoutTooltip/Tooltip";
import { useGet_AccountSettings, useGet_HeaderData } from "../../containers/PlantInformation/DashboardHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import { AccountSettingsDTO, HeaderDTO } from "../../utils/interface";
import MobileMenu from "../MobileMenu/MobileMenu";
import SearchGlobal from "../Search/SearchGlobal";
import AccountSettingsModal from "./AccountSettingsModal";
import "./Navbar.css";
import classes from "./Navbar.module.css";
import SignOutModal from "./SignOutModal";

function Navbar({ headerHeight }: NavbarProps) {
  const { logo, menuOpenIcon } = ImageImports;
  const { isDesktop } = useWindowSize();
  const {
    setHeader,
	  setAccountSettings,
    accountSettings: { data: accountSettings },
    mobileNavToggle,
    toggleMobileNav,
    header: { data },
  } = useContext(Store);
  const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false);
  const [modal, setModal] = useState<ReactElement | undefined>();

  const modals = {
	  account: <AccountSettingsModal headerData={data} closeModal={() => setModal(undefined)} />,
	  signOut: <SignOutModal closeModal={() => setModal(undefined)} />
  };

  const setErrorData = useOnError();

  // Fetch Plant Details Start

  const onSuccess = (data: HeaderDTO) => {
    setHeader && setHeader(data);
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  const { isLoading } = useGet_HeaderData(onSuccess, onError);
  // Fetch Plant Details End

  useGet_AccountSettings((accountSettings: AccountSettingsDTO) => setAccountSettings?.(accountSettings), setErrorData);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = useState<CSSProperties>({
    minWidth: 300
  });
  useEffect(() => {
    if (showAccountMenu && tooltipRef.current) {
        const rect = tooltipRef.current.getBoundingClientRect();
        if (rect.left + 300 > window.innerWidth) {
          setStyles({minWidth: 300, right: 0});
        }
        else {
          setStyles({minWidth: 300});
        }
    }
}, [showAccountMenu]);

useEffect(() => {

document.body.style.overflow = mobileNavToggle ? "hidden" : "auto";
},[mobileNavToggle])

  let desktopHeaderContent = (
    <>
      <div className="nav-item">
        <SearchGlobal style={{minWidth: 502}} />
      </div>
      <div className="nav-item login-details">
        <div className="initials" onClick={() => setShowAccountMenu(!showAccountMenu)}>
          <img src={data.user.pictureUrl} alt="" className="hover:cursor-pointer" />
          <Tooltip shown={showAccountMenu} style={styles} backdrop={true}>
            <div className={classes.accountDropdown}>
              <div className={`${classes.flex_row} ${classes.flex_column}`}>
                <div className="initials">
                  <img src={data.user.pictureUrl} alt="" />
                </div>
                {accountSettings.email}
              </div>
              <div className="flex flex-col gap-5">
                <div className="inline-block"><span onClick={() => setModal(modals.account)} className="hover:cursor-pointer">Account & Settings</span></div>
                <div className="inline-block"><span onClick={() => setModal(modals.signOut)} className="hover:cursor-pointer">Sign Out</span></div>
              </div>
            </div>
          </Tooltip>
        </div>
        <div>
          <div className="name">
            {data.user.firstName} {data.user.lastName}
          </div>
        </div>
      </div>
    </>
  );

  if (!isDesktop) {
    desktopHeaderContent = <></>;
  }
  return (
    <>
      <div className="navbar">
        <div className="nav-item">
          <Link to={"/"} className="link_text">
            <img src={logo} className="App-logo" alt="logo" />
            </Link>
        </div>
        {desktopHeaderContent}
        <div className="nav-item">
          <div className="nav__action_wrp">
			      {isDesktop && <div style={{ minWidth: 40 }}></div>}
            {!isDesktop && (
              <div
                className="nav__action"
                onClick={() => {                  
                  toggleMobileNav && toggleMobileNav(!mobileNavToggle);
                 
                }}
              >
                <img src={menuOpenIcon} alt="open menu" />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isDesktop && <MobileMenu />}
	  {modal}
    </>
  );
}

interface NavbarProps {
  headerHeight?: number;
}

export default Navbar;
