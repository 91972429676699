import React, {
  Fragment,
  MouseEventHandler,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import ReactDOM from "react-dom";
import { Store } from "../../../Store";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import classes from "./ModalGlobal.module.css";

import ImageImport from "../../../utils/ImageImports";

const Backdrop = (props: BackdropProps) => {
  return <div className={classes.backdrop} onClick={props.handleModalClose} />;
};

const ModalOverLay = (props: ModalOverLayProps) => {
  const { closeImg } = ImageImport; 

  const funcCalcSize = () => {
    let size = { maxWidth: "700px" } as any;
    if (props.modalSize === "md") {
      size = { maxWidth: "700px" };
    } else if (props.modalSize === "sm") {
      size = { maxWidth: "500px", textAlign: "center" };
    } else if (props.modalSize === "lg") {
      size = { maxWidth: "1392px" };
    } else if (props.modalSize === "special") {
      size = { maxWidth: "700px", width: "564px" };
    } else if (props.modalSize === "RTE") {
      size = { maxWidth: "700px", maxHeight: "895px" };
      return size;
    }
  };

  return (
    <div className={`${classes.modal} ${props.modalClass} focus:outline-none focus-visible:outline-none fallback-focus`}
    style={funcCalcSize()}
    >
      {
        props.isModalHeader && <div className={classes.modalTitle}>
        <div className={props.isTitleCenter ? `${classes.flexGrow1} ${props.titleClassName}` : `${classes.flexGrow2} ${props.titleClassName}`}>
          {props.modaltitle}
          <span className="modal__title_name">
            {" "}
            {props.modaltitlename}{" "}
          </span>{" "}
        </div>
        <span className={classes.close_modal} onClick={props.handleModalClose}>
          <img src={closeImg} className="close-modal-img" alt="" />
        </span>
      </div>
      }
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays")!;

const ModalGlobal = (props: ModalGlobalProps) => {
  const storeContext = useContext(Store);
  const { setIsModal,setShowError } = storeContext;

  useEffect(() => {
    document.body.classList.add("noScroll");
    setShowError &&
        setShowError({
          isError: false,
          title: "",
          ErrorType: "danger",
        });
        
        return () => {
          document.body.classList.remove("noScroll");
        }
  }, []);


  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop
          handleModalClose={() => {        
            setIsModal && setIsModal!({ ...CloseAllModal });
          }}
        />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverLay
          modaltitle={props.modaltitle}
		      titleClassName={props.titleClassName}
          modaltitlename={props.modaltitlename}
          isTitleCenter={props.isTitleCenter}
          modalClass={props.modalClass || ""}
          modalSize={props.modalSize ? props.modalSize : "md" }
          handleModalClose={ props.modalClose ? props.modalClose : (
            () => {        
            setIsModal && setIsModal!({ ...CloseAllModal });
          })}
          isModalHeader={props.isModalHeader}
        >
          {props.children}
        </ModalOverLay>,
        portalElement
      )}
    </Fragment>
  );
};

interface BackdropProps {
  handleModalClose: MouseEventHandler<HTMLDivElement> | undefined;
}

interface ModalOverLayProps {
  children: ReactNode;
  handleModalClose: MouseEventHandler<HTMLDivElement> | undefined;
  modaltitle: string;
  titleClassName?: string;
  modaltitlename: string;
  modalSize: string;
  modalClass: string;
  isModalHeader: boolean;
  isTitleCenter: boolean;
}
interface ModalGlobalProps {
  children: ReactNode;
  modaltitle: string;
  titleClassName?: string;
  modaltitlename: string;
  modalSize?: string;
  modalClass?: string;
  modalClose?: () => void;
  isModalHeader: boolean;
  isTitleCenter: boolean;
  
}

ModalGlobal.defaultProps = {
  modaltitlename: "",
  modalSize: "",
  modalClass: "",
  isTitleCenter:false

};
export default ModalGlobal;
