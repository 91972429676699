import axios, { AxiosResponse } from "axios";
import { apiDocuments } from "../../endpoints/endpoints";
import { IPItemTypes } from "../../utils/IPItemTypes";
import {
  CPApiResponse,
  DocumentGroup,
  DocumentGroupCreatable,
  DocumentGroups,
} from "../../utils/interface";

function handleAxiosResponse<T = any>(
  response: AxiosResponse<CPApiResponse<T>>
): T {
  if (response.status >= 400) throw response.statusText;
  if (response.data.success !== true) throw response.data.message;
  return response.data.data;
}

export interface CrudMethods<T, TC, T2> {
  update: (data: T) => Promise<T>;
  create: (data: TC) => Promise<T>;
  del: (data: T) => Promise<boolean>;
  getAll: () => Promise<T2>;
}

export const DocumentsAPI: CrudMethods<
  DocumentGroup,
  DocumentGroupCreatable,
  DocumentGroups
> = {
  update: async (documentGroup: DocumentGroup): Promise<DocumentGroup> => {
    const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(
      `${apiDocuments}${
        documentGroup.accessType === IPItemTypes.IPDocument ? "" : "-la"
      }/Group/${documentGroup.accessType}/${documentGroup.id}`,
      {
        method: "PUT",
        data: documentGroup,
      }
    );
    return handleAxiosResponse({
      ...response,
      data: {
        ...response.data,
        data: { ...response.data.data, accessType: documentGroup.accessType },
      },
    });
  },

  create: async (data: DocumentGroupCreatable): Promise<DocumentGroup> => {
    const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(
      `${apiDocuments}${
        data.accessType === IPItemTypes.IPDocument ? "" : "-la"
      }/Group`,
      {
        method: "POST",
        data: { name: data.name },
      }
    );
    return handleAxiosResponse(response);
  },

  del: async (data: DocumentGroup): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(
      `${apiDocuments}${
        data.accessType === IPItemTypes.IPDocument ? "" : "-la"
      }/Group/${data.id}`,
      {
        method: "DELETE",
      }
    );
    return handleAxiosResponse(response);
  },

  getAll: async (): Promise<DocumentGroups> => {
    const groupsResponse: AxiosResponse<CPApiResponse<DocumentGroups>> = await axios(`${apiDocuments}/GetSubTypes`);
    const laGroupsResponse: AxiosResponse<CPApiResponse<DocumentGroups>> = await axios(`${apiDocuments}-la/GetSubTypes`);
    const response = { ...groupsResponse };

    if (response.status === 200) {
      response.data.data.items = response.data.data.items?.map((item) => ({
        ...item,
        accessType: IPItemTypes.IPDocument,
      }));
    }
    if (![200, 403].includes(laGroupsResponse.status)) {
      return handleAxiosResponse(laGroupsResponse);
    } else {
      if (laGroupsResponse.status === 200 && laGroupsResponse.data.data.items?.length) {
        if (laGroupsResponse.data.data.canView || laGroupsResponse.data.data.canAdmin) {
          response.data.data.items = response.data.data.items?.concat(
            laGroupsResponse.data.data.items?.map((item) => ({
              ...item,
              accessType: IPItemTypes.IPDocumentLA,
            }))
          );
        }
      }
    }

    return handleAxiosResponse(response);
  },
};
