import { useContext, useState } from "react";
import Button from "../../../../components/Button/Button";
import SimpleDelete from "../../../../components/FormsPages/FormDelete/SimpleDelete";
import FormSuccess from "../../../../components/FormsPages/FormSuccess/FormSuccess";
import { Store } from "../../../../Store";
import { CloseAllModal } from "../../../../utils/CloseAllModal";
import { PublishedLinksData } from "../../../../utils/interface";
import AddEditPublishedLink from "../../Forms/AddEditPublishedLink";
import ResourcesCard from "../ResourcesCard";
import PublishedLink from "./PublishedLink/PublishedLink";
import classes from "./PublishedLinks.module.css";
const PublishedLinks = ({ publishedLinks }: PublishedLinksInterface) => {
  const { showError, isModal, setIsModal } = useContext(Store);

  const [publishLinkData, setPublishLinkData] = useState<PublishedLinksData>({
    id: 0,
    title: "",
    url: "",
    displayOrder: 0,
    created: "",
    createdBy: "",
    modified: "",
    modifiedBy: "",
    userGroupId: 0,
  });
  const [publishlinkID, setPublishlinkID] = useState<number>(0);

  const handleDeletePublishLink = (id: number) => {
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isDeleteSuccess: {
          ...isModal.isDeleteSuccess,
          isPublishedLink: true,
        },
      });
  };

  const handleAddPublishedLink = () => {
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isAdd: {
          ...isModal.isAdd,
          isPublishedLink: true,
        },
      });
  };

  const handleEditPublishLink = (id: number) => {
    setPublishlinkID(id);
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isEdit: {
          ...isModal.isEdit,
          isPublishedLink: true,
        },
      });
  };
  return (
    <ResourcesCard onClick={handleAddPublishedLink} title={"Published Links"} buttonName={"Add Link"}>
      {publishedLinks.map((c) => (
        <PublishedLink key={c.id} {...c} handleEditPublishLink={handleEditPublishLink} />
      ))}

      {/* Published Link ModalsSTART */}
      {isModal.isAdd.isPublishedLink && (
        <AddEditPublishedLink
          publishLinkData={{...publishLinkData, displayOrder: publishedLinks.length + 1}}
          setPublishLinkData={setPublishLinkData}
          modaltitle={"Add a Published Link"}
          modaltitlename={""}
          isEdit={false}
        />
      )}
      {isModal.isEdit.isPublishedLink && (
        <AddEditPublishedLink
        id={publishlinkID}
          publishLinkData={publishLinkData}
          setPublishLinkData={setPublishLinkData}
          modaltitle={"Edit Published Link"}
          modaltitlename={""}
          isEdit={true}
        />
      )}
      {isModal.isAddedSuccess.isPublishedLink && (
        <FormSuccess
          modaltitlename={"Published Link Successfully Added"}
          modaltitle={""}
          isTitleCenter={true}
          onClick={handleAddPublishedLink}
          buttonName="Add Another Link"
          comment={`The “${publishLinkData.title}” Published Link has been added to the Insurance Portal.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={true}
        />
      )}
      {isModal.isEditedSuccess.isPublishedLink && (
        <FormSuccess
          modaltitlename={"Changes Saved"}
          modaltitle={""}
          isTitleCenter={true}
          comment={`The “${publishLinkData.title}” Published Link has been updated.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}      
      {isModal.isDeleteSuccess.isPublishedLink && (
        <FormSuccess
          modaltitlename={`Published Link Successfully Deleted`}
          modaltitle={""}
          isTitleCenter={true}
          comment={`You successfully deleted the “${publishLinkData.title}” published link.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {/* Published Link Modals END */}
    </ResourcesCard>
  );
};

interface PublishedLinksInterface {
  publishedLinks: {
    id: number;
    title: string;
    url: string;
  }[];
}
export default PublishedLinks;
