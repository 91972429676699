import { FunctionComponent } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import Link from "../Link/Link";

export const Forbidden: FunctionComponent = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="flex flex-col mx-4 p-8 relative mt-12" style={{gap: '24px'}}>
                <h1>Access Denied</h1>
                <p className="text-lg mblx-2">Unfortunately, you do not have permission to access this page or resource. If you think you require access to this page, please contact your Mitsubishi Power administrator.</p>
                <div><Button className="darkBlue" text={'Return to Home Page'} onClick={() => navigate('/')} /></div>
            </div>
        </div>
    )
}