export const baseURL = process.env.REACT_APP_API_URL as string;

export const apiHeader = `${baseURL}/api/ins-usergroup-portal/Header`;
export const apiHeaderSearch = `${baseURL}/api/Header/Search`;

export const apiAccountSettings = `${baseURL}/api/AccountSettings`;


export const apiHealth = `${baseURL}/api/Health`;


export const apiPartsAndServices = `${baseURL}/api/Home/PartsAndServices`;
export const apiTechnicalDocumentation = `${baseURL}/api/Home/TechnicalDocumentation`;
export const apiIssueReports = `${baseURL}/api/Home/IssueReports`;
export const apiQuoteRequests = `${baseURL}/api/Home/QuoteRequests`;
export const apiPlantSiteDetails = `${baseURL}/api/Home/PlantSiteDetails`;

export const apiFile = `${baseURL}/api/ins-usergroup-portal/File`;

export const apiPlantConfig = `${baseURL}/api/PlantConfig`;
export const apiPlantConfigImage = `${baseURL}/api/PlantConfig/GetFile`;


export const apiUnit = `${baseURL}/api/Unit`;
export const apiUnitGetAll = `${baseURL}/api/Unit/GetAllByPlantId`;


export const apiPlantProfileTypes = `${baseURL}/api/PlantProfileType/GetAll`;
export const apiPlantFuelTypes = `${baseURL}/api/FuelType/GetAll`;
export const apiProductTypes= `${baseURL}/api/ProductType/GetAll`;
export const apiEquipmentAttachmentTypes = `${baseURL}/api/EquipmentAttachmentType/GetAll`;
export const apiAssociatedUnitTypes= `${baseURL}/api/AssociatedUnit/GetAll`;
export const apiManufacturerTypes = `${baseURL}/api/Manufacturer/GetAll`;
export const apiUnitConfigurationTypes = `${baseURL}/api/UnitConfiguration/GetAll`;
export const apigetEquipmentModelNameTypes = `${baseURL}/api/EquipmentModel/GetAll`;


export const apigetAllQuoteRequestTypes = `${baseURL}/api/cpitem/quote-request/GetAllQuoteRequestTypes`;
export const apigetAllRequestPriority = `${baseURL}/api/RequestPriority/GetAll`;
export const apiGetAllOutageSeasons = `${baseURL}/api/cpitem/quote-request/GetOutageSeasons`;
export const apiGetAllPartsRequestReasons = `${baseURL}/api/cpitem/quote-request/GetPartsRequestReasons`;
export const apiGetAllQuoteTypes = `${baseURL}/api/cpitem/quote-request/GetAllQuoteTypes`;
export const apiquoterequest = `${baseURL}/api/cpitem/quote-request`;
export const apiquoterequestCreateAttachment = `${baseURL}/api/cpitem/quote-request/CreateAttachment`;


export const apiPlantEquipment= `${baseURL}/api/PlantEquipment`
export const apiGetAllEquipmentsByPlantID= `${baseURL}/api/PlantEquipment/GetAllByPlantId`
export const apiGetAllEquipmentsByUnitID= `${baseURL}/api/PlantEquipment/GetAllByUnitID`
export const apiGetAllPlantEquipmentsAttachments= `${baseURL}/api/EquipmentAttachment/GetAllByPlantEquipmentId`
export const apiEquipmentAttachment= `${baseURL}/api/EquipmentAttachment`
export const apiGetEquipmentAttachmentFile= `${baseURL}/api/EquipmentAttachment/GetFile`
export const apiSiteUnits = `${baseURL}/api/Unit`;


export const apiPlantContacts= `${baseURL}/api/PlantContact/GetAllByPlantId`
export const apiPlantContact= `${baseURL}/api/PlantContact`
export const apiPlantContactGetAvatar= `${baseURL}/api/PlantContact/GetAvatar`

export const apiDocuments = `${baseURL}/api/cpitem/ip-documents`;
export const apiReportDownloadFile = `${baseURL}/api/ins-usergroup-portal/File/DownloadFile`;


export const apiItemTypeCatalog = `${baseURL}/api/ItemTypeCatalog`;
export const apiPartsCatalog = `${baseURL}/api/cpitem/parts-catalog`;

export const apiMessage = `${baseURL}/api/message-threads/Message`;
export const apiMessageThreads = `${baseURL}/api/message-threads/Thread`;
export const apiMessageAttachment = `${baseURL}/api/message-threads/MessageAttachment`;

export const omManualApiUrl = `${baseURL}/api/cpitem/om-manual`;
export const technicalBulletinApiUrl = `${baseURL}/api/cpitem/technical-bulletin`;

export const issueReportApiUrl = `${baseURL}/api/cpitem/issue-report`;
export const QuoteRequestApiUrl = `${baseURL}/api/cpitem/quote-request`;
export const getSearchResults = `${baseURL}/api/ins-usergroup-portal/search/GetSearchResults`;

export const userApiUrl = `${baseURL}/api/ins-usergroup-portal/User`;
export const controllerApiUrl = `${baseURL}/api/controller`;

// Insuarance portal apis

//Announcement apis
export const apiAnnouncement = `${baseURL}/api/ins-usergroup-portal/announcement`;
export const apiGetAllannouncement = `${baseURL}/api/ins-usergroup-portal/announcement/List`;

// Group Contacts apis
export const apiGroupContact = `${baseURL}/api/ins-usergroup-portal/groupcontact`;
export const apiGetAllGroupContact = `${baseURL}/api/ins-usergroup-portal/groupcontact/List`;

// Publish links apis
export const apiPublishedLinks = `${baseURL}/api/ins-usergroup-portal/link`;
export const apiGetAllGroupPublishedLinks = `${baseURL}/api/ins-usergroup-portal/link/List`;

// Portal Update apis
export const apiPortalUpdate = `${baseURL}/api/ins-usergroup-portal/portalupdate`;
export const apiGetAllPortalUpdates = `${baseURL}/api/ins-usergroup-portal/portalupdate/List`;
export const apiGetFilePortalUpdates = `${baseURL}/api/ins-usergroup-portal/portalupdate/GetFile`;


export const roleApiUrl = `${baseURL}/api/roles-and-permissions/Role`;
export const reactivateUserApiUrl = `${baseURL}/api/ins-usergroup-portal/User/ReactivateUser`;
export const baseOrgRoleApiUrl = `${baseURL}/api/roles-and-permissions/user-baseorg-role`;
export const apiEmailOptOut = `${baseURL}/api/EmailOptOut`;

export const apiRoleUrl = `${baseURL}/api/ins-usergroup-portal/Role`;
export const apiUserUrl = `${baseURL}/api/ins-usergroup-portal/User`;
export const apiGenerateAccessRequestReportUrl = `${controllerApiUrl}/GeneratePortalAccessRequestReport`;
export const apiGenerateUserReportUrl = `${baseURL}/api/ins-usergroup-portal/BaseOrg/GenerateUserAccessReport`;
export const apiCountryOfCitizenship = `${baseURL}/api/CountryOfCitizenship`;