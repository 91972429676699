import Button from "../Button/Button";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from '../../utils/ImageImports';

const { Arrow } = ImageImports;

interface PaginationProps {
    resultsPerPage: number;
    resultCount: number;
    pageNumbers: number[];
    currentPage: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    setResultsPerPage: (n: number) => void;
    previousPage: () => void;
    nextPage: () => void;
    setCurrentPage: (n: number) => void;
    totalRecords: number;
}

export const Pagination = ({
    resultsPerPage,
    resultCount,
    pageNumbers,
    currentPage,
    hasPreviousPage,
    hasNextPage,
    setResultsPerPage,
    previousPage,
    nextPage,
    setCurrentPage,
    totalRecords
}: PaginationProps) => {
    const { isMobile, isTablet, isDesktop } = useWindowSize();
    return (
        <div className={`flex ${isDesktop || isTablet ? 'flex-row' : 'flex-col'} items-center justify-between w-full text-sm`}>
            <div className={`flex flex-row gap-4`}>
                <span className="items-center flex">
                    {Math.min(resultsPerPage, resultCount)} of {totalRecords} Result{totalRecords !== 1 && "s"}
                </span>
                {totalRecords > 0 && totalRecords > 16 && resultsPerPage !== totalRecords && 
                    <Button
                        className="whiteBtn show_all"
                        text={"Show All"}
                        onClick={() => setResultsPerPage(totalRecords)}
                        style={{ order: isMobile ? "-1" : "unset" }}
                    />
                }
            </div>
            <div className={`flex flex-row items-start`}>
                <img
                src={Arrow}
                className={`pagination-left-arrow flex flex-col justify-center padding-3 h-9 w-9 `}
                alt="arrow"
                style={hasPreviousPage ? {filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)"} : {}}
                onClick={() => {
                        if (hasPreviousPage) {
                            previousPage();
                        }
                    }}
                />
                {pageNumbers.map((pageNumber) => (
                    <span
                        key={pageNumber}
                        className={`flex flex-col justify-center p-3 text-dark-blue relative cursor-pointer`}
                        onClick={() => {
                            setCurrentPage(pageNumber);
                        }}
                    >
                        <span className={`absolute top-[6px] left-[2px] h-6 w-6 rounded flex items-center justify-center ${pageNumber === currentPage ? 'font-bold bg-primary-20' : ""}`}>
                            {pageNumber}
                        </span>
                    </span>
                ))}
                <img
                    src={Arrow}
                    className="pagination-right-arrow flex flex-col justify-center padding-3 h-9 w-9"
                    alt="arrow"
                    style={hasNextPage ? {filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)"} : {}}
                    onClick={() => {
                        if (hasNextPage) {
                            nextPage();
                        }
                    }}
                />
            </div>
        </div>
    );
}