import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import { DropDownOptionItem, PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { Toggle } from "../../components/UI/Form/Toggle/Toggle";
// import { useGetAllBaseOrgGroups, useGetAllPlantsConfig } from "../../customHooks/PlantHttpServices";
import { useQueryClient } from "react-query";
import { Store } from "../../Store";
import MiniTooltip from "../../components/UI/MiniTooltip/MiniTooltip";
import { removeUserAssignment, updateUserMutation, updateUserOrganizationRoles, useDelete_User, useGetRoles, useGetUser, useGetUserRoleAssignments, useReactivate_User } from "../../customHooks/UserHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { queryKeys } from "../../react-query/queryKeys";
import ImageImports from "../../utils/ImageImports";
import { User, UserRole, UserRoleAssignments } from "../../utils/interface";
import { ConfirmRemoveUserAssignmentModal } from './ConfirmRemoveUserAssignmentModal';
import { ConfirmUserStatusChangeModal } from './ConfirmUserStatusChangeModal';

const { Arrow, help } = ImageImports;

interface ManageUserProps {
    userId: number
}

export const ManageUser = ({userId}: ManageUserProps) => {
    const [showConfirmUserStatusChangeModal, setShowConfirmUserStatusChangeModal] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const setErrorData = useOnError();
    const {header: {data: {user: currentUser}}} = useContext(Store);

    const nUserId: number = useMemo(() => +(userId || 0), [userId]);
    const [changableRole, setChangableRole] = useState<UserRole>();
    const [roleId, setRoleId] = useState<number>(0);
    const [isPortalAdmin, setIsPortalAdmin] = useState<boolean>(false);

    // const [assignments, setAssignments] = useState<UserRole[]>([]);
    // const [roleAssignments, setRoleAssignments] = useState<UserRoleAssignments>();
    
    const {refetch, data: user} = useGetUser(nUserId, {
        onSuccess: u => {
            setRoleId(u.insuranceRoleId);
            setIsPortalAdmin(u.portalAdmin);
        }
    });

    // const {data: userRoleAssignments, isFetched: fetchedRoleAssignments, refetch: refreshUserRoleAssignments} = useGetUserRoleAssignments(nUserId, {
    //     onSuccess: (data) => {
    //         setAssignments(data.roles);
    //         setRoleAssignments(roleAssignments);
    //     }
    // });

    const [updateUserSuccess, setUpdateUserSuccess] = useState<boolean>(false);

    const {mutate: updateUserApi } = useMutation(updateUserMutation, {
        onSuccess: () => {
            refetch();
        }
    });
    
    const {data: roles} = useGetRoles();    
    const { mutate: DeleteUser, isSuccess: deactivateUserSuccess, reset: resetDeactivateMutation } = useDelete_User();
    const { mutate: ReactivateUser, isSuccess: reactivateUserSuccess, reset: resetReactivateMutation } = useReactivate_User();

    const {mutate: addUpdateUserOrganizationRoles, isSuccess: updatedRoleAssignments, reset: resetUpdateMutation, isError: updateAssignmentError} = useMutation(updateUserOrganizationRoles, {
        onSuccess: () => {
            refetch();
        }
    });

    const updateUser = useCallback(() => {
        if (user) {
            updateUserMutation({
                ...user,
                insuranceRoleId: roleId,
                portalAdmin: isPortalAdmin
            }).then((value) => {
                if (value){
                    setUpdateUserSuccess(true);
                }
                else {
                    setUpdateUserSuccess(false);
                }
            });
        }
    }, [user, roleId, isPortalAdmin]);

    const handleDeactivateUser = (user: User) => {
        DeleteUser(user.id, {
            onError: (error: unknown) => {
              setErrorData(error);
            },
            onSuccess: (responseData) => {
              queryClient.invalidateQueries([queryKeys.user]);
              refetch();
              setShowConfirmUserStatusChangeModal(false);
            },
          });
    };

    const handleReactivateUser = (user: User) => {
        ReactivateUser(user.id, {
            onError: (error: unknown) => {
              setErrorData(error);
            },
            onSuccess: (responseData) => {
              queryClient.invalidateQueries([queryKeys.user]);
              refetch();
              setShowConfirmUserStatusChangeModal(false);
            },
          });
    };

    const canSaveAssignments = useMemo(() => {
        return Object.entries({
            roleId, isPortalAdmin
        }).toString() !== Object.entries({
            roleId: user?.insuranceRoleId,
            isPortalAdmin: user?.portalAdmin
        }).toString();
    }, [user, roleId, isPortalAdmin]);

    // useEffect(() => {
    //     if(userRoleAssignments){
    //         setAssignments(userRoleAssignments?.roles);
    //     }
    // }, [userRoleAssignments]);

    const resetMutations = () => {
        setUpdateUserSuccess(false);
        resetDeactivateMutation();
        resetReactivateMutation();
        resetUpdateMutation();
    };

    return (
        <div className={`card flex flex-col gap-[10px]`}>
            {showConfirmUserStatusChangeModal && user &&
                <ConfirmUserStatusChangeModal
                    shown={showConfirmUserStatusChangeModal} 
                    user={user}
                    onConfirmDeactivate={() => handleDeactivateUser(user)} 
                    onConfirmReactivate={() => handleReactivateUser(user)} 
                    onClose={() => setShowConfirmUserStatusChangeModal(false)} 
                    actionType= {`${user.accountDisabled ? 'reactivate' : 'deactivate'}`}
                />
            }
            {user && <div className="gap-8 flex flex-col items-start">
                <div className="flex flex-col items-start gap-8 w-full">
                    <Link to="/administration/user-management" className="no-underline dark-blue">
                        <div className="flex flex-row !pb-0 gap-[10px] items-center">
                            <img src={Arrow} title="Back to Insurance Portal Users" className="rotate-180 h-6 w-6" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                            <span className="font-bold text-sm">Back to Insurance Portal Users</span>
                        </div>
                    </Link>
                    {user.accountDisabled === true &&
                        <div className="flex flex-col items-start gap-8 w-full">
                            <Alert dismissible={false} type="info" onClose={() => {}}>
                                This user is currently deactivated.
                            </Alert>
                        </div>
                    }
                    {(updatedRoleAssignments || updateUserSuccess || deactivateUserSuccess || reactivateUserSuccess) && <Alert onClose={resetMutations} type="success">Changes saved.</Alert>}
                    <div className="flex flex-row !pb-0 items-start gap-6 self-stretch">
                        <div className="flex flex-col items-start gap-2 grow text-2xl font-bold">
                            {user.fName} {user.lName}
                        </div>
                    </div>
                    <hr className="m-0 text-[#B3B3B3] self-stretch" />
                </div>
                <div className="flex flex-col items-stretch gap-8 w-full">
                    {/* User Details */}
                    <div className="flex flex-col items-start gap-8">
                        <div className="flex flex-col items-start gap-2">
                            <span className="font-bold text-xl">User Details</span>
                            <span className="text-base">Data originally fed from Active Directory. To change user details, please contact Mitsubishi Power IT. </span>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-row !pb-0 items-start gap-6">
                                <span className="w-[120px]">First Name:</span>
                                <span>{user.fName}</span>
                            </div>
                            <div className="flex flex-row !pb-0 items-start gap-6">
                                <span className="w-[120px]">Last Name:</span>
                                <span>{user.lName}</span>
                            </div>
                            <div className="flex flex-row !pb-0 items-start gap-6">
                                <span className="w-[120px]">Email Address:</span>
                                <span>{user.email}</span>
                            </div>
                            <div className="flex flex-row !pb-0 items-start gap-6">
                                <span className="w-[120px]">User ID:</span>
                                <span>{user.id}</span>
                            </div>
                        </div>
                    </div>
                    {/* Role Assignments */}
                    <div className="flex flex-col items-start gap-8"> {/* Role_Assignments */}
                        <div className="flex flex-col items-start gap-6 self-stretch"> {/* Frame 6092 */}
                            <div className="flex flex-col items-start gap-6 self-stretch"> {/* Frame 6327 */}
                                <div className="flex flex-row !pb-0 items-center gap-4 self-stretch"> {/* Frame 6382 */}
                                    <Toggle checked={isPortalAdmin} onChange={(e) => setIsPortalAdmin(e.target.checked)} />
                                    <div className="flex flex-row !pb-0 gap-2 text-base items-center"> {/* Frame 6384 */}
                                        Enable Insurance Portal Administrator permissions for this user
                                        <span className="cursor-pointer">
                                            <MiniTooltip text={"If enabled, this user will be granted the highest administration permissions, and will be able to access all pages, modules and data in this portal."} space={15} placement={"top"}>
                                                <img src={help} style={{ maxWidth: "16px", display: "block", marginLeft: "6px" }} alt="Help" />
                                            </MiniTooltip>
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-start gap-2"> {/* Frame 6354 */}
                                    <span className="text-xl font-bold">Role Assignment(s)</span>
                                    <span className="lg:w-3/5">All users are assigned standard user permissions by default. If this user requires additional access to limited access document groups, you may change their role assignment below.</span>
                                </div>
                                {updateAssignmentError && <Alert onClose={() => {}} type="error" dismissible={false}>Changes cannot be saved. One or more role selections below are identical assignments. Please resolve and try saving changes again.</Alert>}
                                <div className="flex flex-col items-start self-stretch gap-6"> {/* Frame 6326 */}
                                <PlainDropDown selectClass="flex flex-row items-center border-solid border border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded w-full cursor-pointer"
                                                optionsClass="flex flex-col p-1 bg-white rounded max-h-80"
                                                dropdownStyles={{overflow: 'auto'}}
                                                itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                                                options={(roles ?? [])}
                                                onSelection={item => item.id? setRoleId(item.id) : setRoleId(0)}
                                                value={roleId}
                                                defaultText="Select a User Role" />
                                </div>
                            </div>
                        </div>
                        <hr className="m-0 text-[#B3B3B3] self-stretch" />
                    </div>
                    <div className="flex flex-row !pb-0 items-start gap-4"> {/* Actions */}
                        <Button className="darkBlue" text="Save Changes" disabled={!canSaveAssignments} onClick={updateUser} />
                        <Button 
                            className={`${user.accountDisabled  === true ? 'darkBlue' : 'redDelete'}`} 
                            text={`${user.accountDisabled === true ? 'Reactivate' : 'Deactivate'} User`} 
                            onClick={() => setShowConfirmUserStatusChangeModal(true)} 
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
};
