import { FunctionComponent, useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { DocumentsModuleOutletInterface } from "./DocumentsModuleContainer";


const DocumentsModuleIndex: FunctionComponent = () => {
    const {documentGroups} = useOutletContext<DocumentsModuleOutletInterface>();
    const navigate = useNavigate();
    useEffect(() => {
        if (documentGroups.length > 0) {
            navigate(`./${documentGroups[0].id}`, {
                replace: true
            });
        }
    }, [documentGroups]);
    return <></>;
};

export default DocumentsModuleIndex;