export enum ModuleIDs {
	Home = 1,
	Documents = 2,
	DocumentGroups = 3,
  DocumentsLA = 4,
	DocumentGroupsLA = 5,
	Administration = 6,
	UserManagement = 7,
	NotificationManagement = 8,
  PowerReports = 19,
	OMManuals = 3,
	PartsCatalog = 4,
	TechnicalBulletins = 21,
	IssueReports = 11,
	QuoteRequests = 14,
}