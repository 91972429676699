import { ModalsStateManagementDTO } from "./interface";

export const CloseAllModal:ModalsStateManagementDTO = {
    isCopy: {
      isCopyLinkModal: false
    },
    isView: {
      isNameplate:false,
    },
    isAdd: {
      isEquipment: false,
      isContactModal: false,
      isNameplate:false,
      isNameplateSamePage:false,
      isUnit:false,
      isPartsListDocument: false,
      isAttachNameplateAsset: false,
      isQuoteRequest: false,
      isInsuranceContacts: false,
      isInsuranceUpdate: false,
      isPublishedLink: false,
      isAnnouncement: false,
    },    
    isDelete: {
      isEquipment: false,
      isPlantConfig: false, 
      isNameplate:false,
      isUnit:false,
      isQuoteRequest: false,
      isInsuranceContacts: false,
      isInsuranceUpdate: false,
      isPublishedLink: false,
      isAnnouncement: false,
    },
    isEdit: {
      isEquipment: false,  
      isPlantConfig: false,   
      isUnit:false,
      isContactModal: false,
      isNameplate:false,
      isFaq:false,
      isPartsListDocument: false,
      isAttachNameplateAsset: false,
      isQuoteRequest: false,
      isInsuranceContacts: false,
      isInsuranceUpdate: false,
      isPublishedLink: false,
      isAnnouncement: false,
    },    
    isDeleteSuccess:{
      isEquipment: false,
      isContactModal: false,
      isPlantConfig: false,
      isNameplate:false,
      isUnit:false,
      isQuoteRequest: false,
      isInsuranceContacts: false,
      isInsuranceUpdate: false,
      isPublishedLink: false,
      isAnnouncement: false,
    },
    isAddedSuccess:{
      isEquipment: false,
      isContactModal: false, 
      isNameplate:false, 
      isNameplateSamePage:false,
      isUnit:false,
      isQuoteRequest: false,
      isInsuranceContacts: false,
      isInsuranceUpdate: false,
      isPublishedLink: false,
      isAnnouncement: false,
    },
    isEditedSuccess:{
      isEquipment: false,
      isUnit:false,
      isContactModal: false,  
      isPlantConfig: false,
      isNameplate:false,
      isQuoteRequest: false,
      isInsuranceContacts: false,
      isInsuranceUpdate: false,
      isPublishedLink: false,
      isAnnouncement: false,
    }
  };