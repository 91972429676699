
import classes from '../Documents.module.css';
import headerClasses from './Document.module.css';
import { useContext } from 'react';
import {Store} from '../../../Store';
import HeaderButtons from '../HeaderButtons';
import useWindowSize from '../../../customHooks/useWindowSize';

function DocumentHeader() {
    const {selectedPlant} = useContext(Store);
    const {isDesktop} = useWindowSize();
    return (
        <div className={`${classes.flex_row} ${headerClasses.header} ${isDesktop? '' : headerClasses.not_desktop}`} style={{justifyContent: isDesktop ? "flex-end" : ""}}>
            {isDesktop && <div className={`${headerClasses.header_content} ${classes.flex_row}`}>
                <div className={headerClasses.header_title}>
					&nbsp;
                </div>
                <div className={headerClasses.group_header_text}>
                	&nbsp;
                </div>
            </div>}
            {<HeaderButtons manageButtonClass={headerClasses.manage_button} addButtonClass={headerClasses.add_button} addButtonText='Add Document' />}
        </div>
    );
}

export default DocumentHeader;