import { useContext } from "react";
import { Store } from "../../Store";

import { TypeDTO, UnitGetAllDTO } from "../../utils/interface";
import { AxiosError } from "axios";
import { useGetAll_UnitData } from "../../containers/PlantInformation/UnitHttpServices";
import {
  useApiUnitConfigurationTypes,
  useApiGetManuFacturerTypes,
  useApiPlantFuelType,
  useApiProductType,
  useApiEquipmentModelNameTypes,
} from "../../containers/PlantInformation/TypesHttpServices";
import { useOnError } from "../../customHooks/useOnError";

const usePlantSiteDetailsApiCalls = () => {
  const {    
    selectedPlant,
    setUnitConfigurationTypes,
    setEquipmentModelNameTypes,
    setProductType,
    setPlantConfig,
    setFuelType,
    setManufacturerType,
    setPlantConfigImage,        
    setUnitGetAll,
    unitGetAll
  } = useContext(Store);
  const setErrorData = useOnError();

  ///////////////////////////////////////////
  // Fetch useGet_PlantConfigData Start//
  ///////////////////////////////////////////

  // const onSuccessPlantConfig = (data: PlantConfigDTO) => {
  //   setPlantConfig && setPlantConfig(data);
  // };
  // const onErrorPlantConfig = (error: AxiosError<string>) => {
  //   setErrorData(error);
  // };

  // useGet_PlantConfigData(selectedPlant.plantId, onSuccessPlantConfig, onErrorPlantConfig);



  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiPlantFuelType Start
  ///////////////////////////////////////////

  const onSuccess = (data: { data: TypeDTO }) => {
    // console.log("onSuccess", data);
    setFuelType && setFuelType(data?.data);
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useApiPlantFuelType(onSuccess, onError);

  ///////////////////////////////////////////
  // Fetch useApiPlantFuelType End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}
  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiPlantFuelType Start
  ///////////////////////////////////////////

  const onSuccessUnitData = (data: UnitGetAllDTO) => { 
    setUnitGetAll && setUnitGetAll(data);
  };
  const onErrorUnitData = (error: AxiosError<string>) => {
    setErrorData(error);
    setUnitGetAll &&
    setUnitGetAll({
        ...unitGetAll,
        data: [],
      });
  };
  const { isLoading}= useGetAll_UnitData(selectedPlant.plantId, onSuccessUnitData, onErrorUnitData);


  ///////////////////////////////////////////
  // Fetch useApiPlantFuelType End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiManufacturerGetAll Start
  ///////////////////////////////////////////

  const onSuccessManufacturerGetAll = (data: TypeDTO) => {
    // console.log("onSuccess", data);
    setManufacturerType && setManufacturerType(data);
  };
  const onErrorManufacturerGetAll = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useApiGetManuFacturerTypes(onSuccessManufacturerGetAll, onErrorManufacturerGetAll);
  ///////////////////////////////////////////
  // Fetch useApiManufacturerGetAll End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiProductType Start
  ///////////////////////////////////////////

  const onSuccessProductType = (data: TypeDTO) => {
    // console.log("onSuccess", data);
    setProductType && setProductType(data);
  };
  const onErrorProductType = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useApiProductType(onSuccessProductType, onErrorProductType);

  ///////////////////////////////////////////
  // Fetch useApiProductType End
  ///////////////////////////////////////////

  ///////////////////////////////////////////
  // Fetch Types Start
  ///////////////////////////////////////////

  const onSuccessUnitConfigTypes = (data: TypeDTO) => {
    setUnitConfigurationTypes && setUnitConfigurationTypes(data);
  };
  const onErrorUnitConfigTypes = (error: any) => {
    setErrorData(error);
  };

  useApiUnitConfigurationTypes(onSuccessUnitConfigTypes, onErrorUnitConfigTypes);

  ///////////////////////////////////////////
  // Fetch Types End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch Types Start
  ///////////////////////////////////////////

  const onSuccessFuelType = (data: TypeDTO) => {
    setFuelType && setFuelType(data);
  };
  const onErrorFuelType = (error: any) => {
    setErrorData(error);
  };

  useApiPlantFuelType(onSuccessFuelType, onErrorFuelType);

  ///////////////////////////////////////////
  // Fetch Types End
  ///////////////////////////////////////////

  ///////////////////////////////////////////
  // Fetch Types Start
  ///////////////////////////////////////////

  const onSuccessEquipmentModelName = (data: TypeDTO) => {
    setEquipmentModelNameTypes && setEquipmentModelNameTypes(data);
  };
  const onErrorEquipmentModelName = (error: any) => {
    setErrorData(error);
  };

  useApiEquipmentModelNameTypes(onSuccessEquipmentModelName, onErrorEquipmentModelName);

  ///////////////////////////////////////////
  // Fetch Types End
  ///////////////////////////////////////////

  return null;
};

export default usePlantSiteDetailsApiCalls;
