import dayjs, { Dayjs } from "dayjs";
import { useField, useFormikContext } from "formik";
import { forwardRef, useRef } from "react";
import DatePicker from "react-datepicker";
import useWindowSize from "../../../../customHooks/useWindowSize";
import ImageImports from "../../../../utils/ImageImports";
import Button from "../../../Button/Button";
import MiniTooltip from "../../MiniTooltip/MiniTooltip";
import classes from "./DatePickerField.module.css";

const { tooltip, calendar } = ImageImports;
const DatePickerFields = ({ ...props }: DatePickerFieldInterface) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const inputref = useRef(null);

  return (
    <div className={`${classes.form_field_row}`}>
      <label htmlFor={props.name} className={classes.input_label}>
        {props.labelName}
        {props.tooltip?.text.length && (
          <div className={classes.cursor_pointer}>
            <MiniTooltip text={props.tooltip.text} space={props.tooltip.space} placement={props.tooltip.placement}>
              <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
            </MiniTooltip>
          </div>
        )}
      </label>
      <div className={`${classes.form_group}`}>
        <div className={meta.touched && meta.error ? `${classes.input} ${classes.errorField} ${classes.error_text}` : `${classes.input}`}>
          <DatePicker
            placeholderText={props.placeholder}
            selected={(field.value && new Date(field.value)) || null}
            showYearPicker={props.showYearPicker}
            dateFormat={props.dateFormat}
            disabled={props.disabled}
            onChange={(val) => {
              if (val) {
                setFieldValue(field.name, `${val.toLocaleDateString()}`);
              }
            }}
            customInput={<DateInput inputref={inputref} />}
          />
        </div>

        {props.details && (
          <small className={classes.small} style={{ marginTop: "8px" }}>
            {props.details}
          </small>
        )}
      </div>
    </div>
  );
};

const DateInput = forwardRef((props: any, ref: any) => (
  <input disabled={props.disabled} {...props} ref={ref} style={props.disabled ? { background: `#F2F2F2 url(${calendar}) no-repeat right 10px center`, paddingRight: "45px" } : { background: `url(${calendar}) no-repeat right 10px center`, paddingRight: "45px" }} />
));

interface IDateInputWithCalendarToggleProps {
  name: string;
  disabled?: boolean;
  format?: string;
  toggleIcon: () => void;
  onBlur?: () => void;
  onChange: (val: Dayjs) => void;
  value?: string;
}

export const DateInputWithCalendarToggle = (props: IDateInputWithCalendarToggleProps) => {
  var customParseFormat = require('dayjs/plugin/customParseFormat')
  dayjs.extend(customParseFormat)
  const { isMobile } = useWindowSize();
  return (
    <div style={{ position: 'relative' }}>
      <input
        value={props.value}
        className={`text-current font-normal text-base pt-3 pb-3 pr-2 pl-4 w-4/5 rounded my-2 outline-none`}
        placeholder="Select date"
        pattern='MM/DD/YYYY'
        onChange={(e: any) => {
          props.onChange((e.currentTarget.value))
        }}
      />
      <Button
        style={{
          background: 'transparent',
          position: 'absolute',
          width: '20%',
          height: '60%',
          padding: 0,
          top: isMobile ? '20%' : '20%',
          right: isMobile ? '-23%' : '22%'
        }}
        disabled={props.disabled}
        className=""
        onClick={props.toggleIcon}
        img={<img src={calendar} alt="calendar-icon" />}
        text={""}
      />
    </div>
  )

}

DateInputWithCalendarToggle.defaultProps = {
  disabled: false,
  format: 'MM/DD/YYYY'
}

interface DatePickerFieldInterface {
  name: string;
  labelName: string;
  placeholder?: string;
  showYearPicker: boolean;
  disabled?: boolean;
  dateFormat: string;
  details?: string;
  tooltip?: {
    placement?: "bottom" | "top" | "right" | "left";
    isDisabled?: 0 | 1;
    space?: number;
    text: string;
  };
}
DatePickerFields.defaultProps = {
  placeholder: "Select a date",
  dateFormat: "MM/dd/yyyy",
  showYearPicker: false,
};

export default DatePickerFields;
