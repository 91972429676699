import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { DateInputWithCalendarToggle } from '../DatePickerField/DatePickerFields';
import { CustomSingleDatePicker } from './DatePicker';

interface IDateRangePickerProps {
    startDate: Dayjs;
    endDate: Dayjs;
    showEndCalendar: boolean;
    showStartCalendar: boolean;
    startName: string;
    endName: string;
    startDateChange: (val: Dayjs) => void;
    endDateChange: (val: Dayjs) => void;
    setToggleStartCalendarDisplay: (x: boolean) => void;
    setToggleEndCalendarDisplay: (x: boolean) => void;
}

const CustomDateRangePicker: React.FunctionComponent<IDateRangePickerProps> = (props) => {
    const { startDate, endDate, showEndCalendar, showStartCalendar, startName, endName, startDateChange, endDateChange, setToggleStartCalendarDisplay, setToggleEndCalendarDisplay } = props;
    var customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)
    return (
        <div style={{ width: '130%' }}>
            <label>From</label>
            <CustomSingleDatePicker
                selectedDate={startDate}
                onChange={val => {
                    startDateChange(dayjs(val))
                    showStartCalendar && setToggleStartCalendarDisplay(!showStartCalendar)
                }}
                openPicker={showStartCalendar}
                input={<DateInputWithCalendarToggle
                    onBlur={() => showStartCalendar && setToggleStartCalendarDisplay(!showStartCalendar)}
                    value={dayjs(startDate, 'MM/DD/YYYY').isValid() ? dayjs(startDate).format('MM/DD/YYYY') : undefined}
                    onChange={(val: Dayjs) => startDateChange(val)}
                    toggleIcon={() => setToggleStartCalendarDisplay(!showStartCalendar)}
                    name={startName} />}
            />
            <label>To</label>
            <CustomSingleDatePicker
                selectedDate={endDate}
                onChange={val => {
                    endDateChange(dayjs(val))
                    showEndCalendar && setToggleEndCalendarDisplay(!showEndCalendar)
                }}
                openPicker={showEndCalendar}
                input={<DateInputWithCalendarToggle
                    disabled={!dayjs(startDate, 'MM/DD/YYYY').isValid()}
                    onBlur={() => showEndCalendar && setToggleEndCalendarDisplay(!showEndCalendar)}
                    value={dayjs(endDate, 'MM/DD/YYYY').isValid() ? dayjs(endDate).format('MM/DD/YYYY') : undefined}
                    onChange={(val: Dayjs) => endDateChange(val)}
                    toggleIcon={() => setToggleEndCalendarDisplay(!showEndCalendar)}
                    name={endName} />}
            />
        </div>
    )
};

export default CustomDateRangePicker;
