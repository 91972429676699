import { Outlet } from "react-router-dom";
import ToolTip from "../../../components/ToolTip/ToolTip";
import useWindowSize from "../../../customHooks/useWindowSize";
import useScroll from "../../../customHooks/useScroll";
import Navbar from "../../../insurance-components/Navbar/Navbar";
import Sidebar from "../../../insurance-components/Sidebar/Sidebar";
import useHeaderHeight from "../../../customHooks/useHeaderHeight";

import { useRef } from "react";
import { useBaseOrgIdHeader } from '../../../customHooks/useBaseOrgIdHeader';
import { AddToHomeScreen } from "../../../components/AddToHomeScreen/AddToHomeScreen";

function Layout() {
  const { isDesktop } = useWindowSize();
  const { onScroll100Active } = useScroll();
  const AppHeaderHeight = useRef<HTMLDivElement>(null);
  const { headerHeight, headerHeightPadding } =
    useHeaderHeight(AppHeaderHeight);

  // we need to include the BaseOrgId in the header of all api calls
  // this will tell us when we have the base org id for api calls (and it attaches it to all calls) and we can continue loading the app
  // const hasBaseOrgIdForApiCalls = useBaseOrgIdHeader();

  return (
    <div className="App">
      <div
        className={onScroll100Active ? "App-header active" : "App-header"}
        ref={AppHeaderHeight}
      >
        {headerHeight !== null && <Navbar headerHeight={headerHeight} />}

      </div>
      {headerHeightPadding !== null && (

        <div className="App-body" style={{ paddingTop: headerHeightPadding }}>
          {
            isDesktop && <Sidebar />
          }
          {/* CONTINUE LOADING THE APP WHEN WE HAVE A BASEORGID */}
          {/* {hasBaseOrgIdForApiCalls && <Outlet />} */}
          {<Outlet />}
          <ToolTip headerHeight={headerHeightPadding} />

        </div>
      )}
      {/* <Footer /> */}
      <AddToHomeScreen />
    </div>
  );
}

export default Layout;
