import { useContext, useState } from "react";
import FormSuccess from "../../../../components/FormsPages/FormSuccess/FormSuccess";
import { Store } from "../../../../Store";
import { CloseAllModal } from "../../../../utils/CloseAllModal";
import { PortalUpdateData } from "../../../../utils/interface";
import AddEditInsuranceUpdates from "../../Forms/AddEditInsuranceUpdates";

import ResourcesCard from "../ResourcesCard";
import Update from "./Update/Update";

const Updates = ({ updates }: UpdatesInterface) => {
  const { showError, isModal, setIsModal}  = useContext(Store);


  const [portalUpdateData, setPortalUpdateData] = useState<PortalUpdateData>({
    id: 0,
    title: "",
    displayOrder: 0,
    created: "",
    createdBy: "",
    modified: "",
    modifiedBy: "",
    userGroupId: 0,
    file: "",
    fileType: 0,
    filePath: "",
    originalFileName: "",
    size: 0,
    fileTypeName: "",
    displaySize: "",
    fileTypeObj: null,
  });
  const [portalUpdateID, setPortalUpdateID] = useState<number>(0);

  const handleDeleteInsuranceUpdate = (id: number) => {    
      setIsModal?.({
        ...CloseAllModal,
        isDeleteSuccess: {
          ...isModal.isDeleteSuccess,
          isInsuranceUpdate: true,
        },
      });
  };

  const handleAddInsuranceUpdate = () => {    
      setIsModal?.({
        ...CloseAllModal,
        isAdd: {
          ...isModal.isAdd,
          isInsuranceUpdate: true,
        },
      });
  };

  const handleEditInsuranceUpdate = (id: number) => {
    setPortalUpdateID(id);    
      setIsModal?.({
        ...CloseAllModal,
        isEdit: {
          ...isModal.isEdit,
          isInsuranceUpdate: true,
        },
      });
  };
  return (
    <ResourcesCard onClick={handleAddInsuranceUpdate} title={"Updates"} buttonName={"Add Update"}>
      {updates.map((c) => (
        <Update key={c.id} {...c} handleEditInsuranceUpdate={handleEditInsuranceUpdate} />
      ))}

      {/* update Link ModalsSTART */}
      {isModal.isAdd.isInsuranceUpdate && <AddEditInsuranceUpdates portalUpdateData={{...portalUpdateData, displayOrder: updates.length + 1}} setPortalUpdateData={setPortalUpdateData} modaltitle={"Add an Update"} modaltitlename={""} isEdit={false} />}
      {isModal.isEdit.isInsuranceUpdate && <AddEditInsuranceUpdates id={portalUpdateID} portalUpdateData={portalUpdateData} setPortalUpdateData={setPortalUpdateData}  modaltitle={"Edit Update"} modaltitlename={""} isEdit={true} />}
      {isModal.isAddedSuccess.isInsuranceUpdate && (
        <FormSuccess
          modaltitlename={"Update Successfully Added"}
          modaltitle={""}
          isTitleCenter={true}
          onClick={handleAddInsuranceUpdate}
          buttonName="Add Another Update"
          comment={`The “${portalUpdateData.title}” Update has been added to the Insurance Portal.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={true}
        />
      )}
      {isModal.isEditedSuccess.isInsuranceUpdate && (
        <FormSuccess
          modaltitlename={"Changes Saved"}
          modaltitle={""}
          isTitleCenter={true}
          comment={`The “${portalUpdateData.title}” Update has been successfully updated.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
     
      {isModal.isDeleteSuccess.isInsuranceUpdate && (
        <FormSuccess
          modaltitlename={`Update Successfully Deleted`}
          modaltitle={""}
          isTitleCenter={true}
          comment={`You successfully deleted the “${portalUpdateData.title}” update.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {/* update Link Modals END */}
    </ResourcesCard>
  );
};

interface UpdatesInterface {
  updates: {
    id: number;
    title: string;
  }[];
}
export default Updates;
