import React, { useContext } from "react";
import { Store } from "../../../Store";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import ModalGlobal from "../../UI/Modal/ModalGlobal";


function FormSuccess(props: FormSuccessProps) {
  const { isModal, setIsModal } = useContext(Store);

  return (
    <ModalGlobal
      modaltitle={props.modaltitle}
      modaltitlename={props.modaltitlename}
      modalSize={props.modalSize}
      isModalHeader={true}
      isTitleCenter={props.isTitleCenter}
    >
      <div className="flex align-center marginBot40">
        <div className="flex-grow-1 text-center">{props.comment}</div>
      </div>
      <div className="flex align-center">
        {props.button_addmore && props.button_done ? (
          <div className="flex flex-col md:flex-row items-center justify-center gap-x-4">
            <button
              type="button"
              className="primaryBtn darkBlue"
              onClick={(e) => {
                e.preventDefault();
                props.onClick && props.onClick();
              }}
            >
              {props.buttonName}
            </button>
            <button
              type="button"
              className="primaryBtn whiteBtn"
              onClick={() => {               

                setIsModal && setIsModal({                  
                  ...CloseAllModal
                });
              }}
            >
              Done
            </button>
          </div>
        ) : props.button_addmore ? (
          <button
            type="button"
            className="primaryBtn darkBlue"
            onClick={(e) => {
              e.preventDefault();
              props.onClick && props.onClick();
            }}
          >
             {props.buttonName}
          </button>
        ) : (
          <button
            type="button"
            className="primaryBtn darkBlue"
            style={{ margin: "auto" }}
            onClick={() => {              
              setIsModal && setIsModal({             
                ...CloseAllModal,
              });
            }}
          >
            Done
          </button>
        )}
      </div>
    </ModalGlobal>
  );
}

interface FormSuccessProps {
  modaltitlename: string;
  modaltitle: string;
  comment: string;
  modalSize: "sm" | "md";
  button_done: boolean;
  button_addmore: boolean;
  isTitleCenter?: boolean;
  buttonName?: string;
  onClick?: () => void;
}
export default FormSuccess;
