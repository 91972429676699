import axios from "axios";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { apiGetAllPortalUpdates, apiGetFilePortalUpdates, apiPortalUpdate } from "../../../endpoints/endpoints";
import { queryKeys } from "../../../react-query/queryKeys";
import { CPApiResponse, deleteInterface, FormDataPortalUpdate, PortalUpdateData } from "../../../utils/interface";

interface PortalUpdateCurdMethod<A, B, D, E> {
  get: (id: number) => Promise<B>;
  getAll: () => Promise<A>;
  create: (formdata: E) => Promise<B>;
  update: (formdata: E) => Promise<B>;
  delete: (id: number) => Promise<D>;
  getFile: (id: number) => Promise<Blob>;
}

export const PortalUpdateApi: PortalUpdateCurdMethod<
  CPApiResponse<PortalUpdateData[]>,
  CPApiResponse<PortalUpdateData>,
  deleteInterface,
  FormDataPortalUpdate
> = {
  get: async (id: number): Promise<CPApiResponse<PortalUpdateData>> => {
    const response = await axios({
      url: `${apiPortalUpdate}/${id}`,
      method: "GET",
    });
    return response.data;
  },
  getAll: async (): Promise<CPApiResponse<PortalUpdateData[]>> => {
    const response = await axios({
      url: `${apiGetAllPortalUpdates}`,
      method: "GET",
    });
    return response.data;
  },
  create: async (data: FormDataPortalUpdate): Promise<CPApiResponse<PortalUpdateData>> => {
    const response = await axios({
      url: `${apiPortalUpdate}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  update: async (data: FormDataPortalUpdate): Promise<CPApiResponse<PortalUpdateData>> => {
    const response = await axios({
      url: `${apiPortalUpdate}/${data.id}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  delete: async (id: number): Promise<deleteInterface> => {
    const response = await axios({
      url: `${apiPortalUpdate}/${id}`,
      method: "DELETE",
    });
    return response.data;
  },
  getFile: async (id: number): Promise<Blob> => {
    const response = await axios({
      url: `${apiGetFilePortalUpdates}/${id}`,
      method: "GET",
      responseType: "blob",
    });

    return response.data;
  },
};

export const useGetAll_PortalUpdates = (options: Omit<UseQueryOptions<CPApiResponse<PortalUpdateData[]>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<PortalUpdateData[]>>(queryKeys.PortalUpdateApi, PortalUpdateApi.getAll, {
    ...options,
  });
};

export const useGet_PortalUpdate = (id: number, options: Omit<UseQueryOptions<CPApiResponse<PortalUpdateData>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<PortalUpdateData>>([queryKeys.PortalUpdateApi, id], () => PortalUpdateApi.get(id), {
    ...options,
  });
};

export const useGetFile_PortalUpdate = (id: number, options: Omit<UseQueryOptions<Blob>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<Blob>([queryKeys.PortalUpdateGetFileApi, id], () => PortalUpdateApi.getFile(id), {
    ...options,
  });
};

export const useCreate_PortalUpdates = () => {
  return useMutation(PortalUpdateApi.create);
};

export const useUpdate_PortalUpdates = () => {
  return useMutation(PortalUpdateApi.update);
};
export const useDelete_PortalUpdates = () => {
  return useMutation(PortalUpdateApi.delete);
};
