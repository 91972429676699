import React, { Dispatch, useContext, useEffect, useState } from "react";

import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useIsFetching } from "react-query";

import { Store } from "../../../Store";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import ModalGlobal from "../../../components/UI/Modal/ModalGlobal";
import { useOnError } from "../../../customHooks/useOnError";
import { CPApiResponse, ContactFormInInterface, GroupContactData, deleteInterface } from "../../../utils/interface";

import SimpleDelete from "../../../components/FormsPages/FormDelete/SimpleDelete";
import Loader from "../../../components/Loader/Loader";
import RadioButtonsGrouped from "../../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import TextInputField from "../../../components/UI/Form/TextInputField/TextInputField";
import { queryKeys } from "../../../react-query/queryKeys";
import { queryClient } from "../../../react-query/useQueryClientGet";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import {
  useCreate_GroupContacts,
  useDelete_GroupContacts,
  useGet_GroupContact,
  useUpdate_GroupContacts,
} from "../HttpServices/GroupContactHttpServices";

const AddEditInsuranceContacts = ({ isEdit, modaltitle, id, modaltitlename, setContactData, contactData }: FormAddContactsProps) => {
  const setErrorData = useOnError();
  const { isModal, setIsModal, showError } = useContext(Store);

  const [contactID, setContactID] = useState<number>(0);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const isFetching = useIsFetching([queryKeys.GroupContactApi]);

  const { mutate: CreateGroupContact, isLoading: isCreating } = useCreate_GroupContacts();
  const { mutate: UpdateeGroupContact, isLoading: isUpdating } = useUpdate_GroupContacts();
  const { mutate: DeleteGroupContact, isLoading: isDeleting } = useDelete_GroupContacts();

  useEffect(() => {
    if (id) {
      setContactID(id);
    }
  }, [id]);

  const handleInContactDelete = () => {
    DeleteGroupContact(contactData?.id, {
      onSuccess: (responseData: deleteInterface) => {
        queryClient.invalidateQueries(queryKeys.GroupContactApi);
        setIsModal &&
          setIsModal({
            ...CloseAllModal,
            isDeleteSuccess: {
              ...isModal.isDeleteSuccess,
              isInsuranceContacts: true,
            },
          });
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };

  // --------------------------------------------------------//

  useGet_GroupContact(contactID, {
    enabled: !!contactID && isEdit && !isDelete,
    onSuccess: (AnnouncementData: CPApiResponse<GroupContactData>) => {
      //console.log("AnnouncementData", AnnouncementData);
      setContactData(AnnouncementData.data);
    },
    onError: (error: unknown) => {
      setErrorData(error);
    },
  });

  // --------------------------------------------------------//

  let initialValues: ContactFormInInterface;

  if (contactData) {
  }
  initialValues = {
    id: contactData.id,
    firstName: contactData.firstName,
    lastName: contactData.lastName,
    email: contactData.email,
    jobTitle: contactData.jobTitle,
    primaryPhone: contactData.primaryPhone,
    primaryPhoneTypeId: `${contactData.primaryPhoneTypeId ? contactData.primaryPhoneTypeId : "0"}`,
    secondaryPhone: contactData.secondaryPhone,
    secondaryPhoneTypeId: `${contactData.secondaryPhoneTypeId ? contactData.secondaryPhoneTypeId : "0"}`,
    streetAddress: contactData.streetAddress,
    city: contactData.city,
    state: contactData.state,
    postalCode: contactData.postalCode,
    country: contactData.country,
    displayOrder: contactData.displayOrder,
  };

  if (!isEdit) {
    initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      primaryPhone: "",
      primaryPhoneTypeId: "0",
      secondaryPhone: "",
      secondaryPhoneTypeId: "0",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      displayOrder: initialValues.displayOrder || 1,
    };
  }

  const handleOnSubmit = (values: ContactFormInInterface, formikHelpers: FormikHelpers<ContactFormInInterface>) => {
    if (!isEdit) {
      CreateGroupContact(values, {
        onSuccess: (responseData: CPApiResponse<GroupContactData>) => {
          setContactData(responseData.data);
          queryClient.invalidateQueries(queryKeys.GroupContactApi);

          formikHelpers.setSubmitting(false);
          setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isAddedSuccess: {
                ...isModal.isAddedSuccess,
                isInsuranceContacts: true,
              },
            });
        },
        onError: (error: unknown) => {
          formikHelpers.setSubmitting(false);
          setErrorData(error);
        },
      });
    } else {
      UpdateeGroupContact(values, {
        onSuccess: (responseData: CPApiResponse<GroupContactData>) => {
          setContactData(responseData.data);
          queryClient.invalidateQueries(queryKeys.GroupContactApi);

          formikHelpers.setSubmitting(false);
          setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isEditedSuccess: {
                ...isModal.isEditedSuccess,
                isInsuranceContacts: true,
              },
            });
        },
        onError: (error: unknown) => {
          formikHelpers.setSubmitting(false);
          setErrorData(error);
        },
      });
    }
  };

  const phoneRegExp = /^\+?[0-9]{0,2}[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return (
    <>
      <ModalGlobal modaltitle={isDelete ? "Delete Contact" : modaltitle} modaltitlename={modaltitlename} isModalHeader={true}>
        {!isDelete ? (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={Yup.object({
              firstName: Yup.string().min(2).required("This field is required"),
              lastName: Yup.string().min(2).required("This field is required"),
              email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
              displayOrder: Yup.string().required("This field is required"),
              primaryPhone: Yup.string().nullable().matches(phoneRegExp, "Phone number is not valid"),
              secondaryPhone: Yup.string().nullable().matches(phoneRegExp, "Phone number is not valid"),
            })}
          >
            {(formikProps) => {
              return (
                <>
                  {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}

                  <Form>
                    {
                      <div>
                        {isFetching > 0 || isUpdating || isCreating ? (
                          <Loader marginTop={"0"} />
                        ) : (
                          <>
                            <div>
                              <TextInputField
                                name="firstName"
                                labelName="First Name*"
                                placeholder="First name"
                                className=""
                                
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="lastName"
                                labelName="Last Name*"
                                className=""
                                placeholder="Last name"
                                
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="email"
                                labelName="E-mail*"
                                className=""
                                placeholder="example@domain.com"
                                
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="jobTitle"
                                labelName="Job Title"
                                className=""
                                placeholder="Job Title or Description"
                                
                              />
                            </div>

                            <div>
                              <TextInputField name="primaryPhone" labelName="Primary Phone" className="" placeholder="(000) 000-0000" />
                            </div>
                            <div>
                              <RadioButtonsGrouped
                                name="primaryPhoneTypeId"
                                firstRadioName="office"
                                firstRadioValue="1"
                                SecondRadioName="mobile"
                                SecondRadioValue="2"
                                labelName=""
                                className=""
                              />
                            </div>
                            <div>
                              <TextInputField name="secondaryPhone" labelName="Secondary Phone" className="" placeholder="(000) 000-0000" />
                            </div>
                            <div>
                              <RadioButtonsGrouped
                                name="secondaryPhoneTypeId"
                                firstRadioName="office"
                                firstRadioValue="1"
                                SecondRadioName="mobile"
                                SecondRadioValue="2"
                                labelName=""
                                className=""
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="streetAddress"
                                labelName="Street Address"
                                className=""
                                placeholder="1234 Street"
                                
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="city"
                                labelName="City"
                                className=""
                                placeholder="City"
                                
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="state"
                                labelName="State/Province"
                                className=""
                                placeholder="State/Province"
                                
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="postalCode"
                                labelName="Zip/Postal Code"
                                className=""
                                placeholder="Zip/Postal Code"
                                
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="country"
                                labelName="Country"
                                className=""
                                placeholder="Country"
                                
                              />
                            </div>
                            <div>
                              <TextInputField
                                name="displayOrder"
                                labelName="Display Order*"
                                type="number"
                                className=""
                                placeholder="ex. 1"
                                tooltip={{
                                  text: "The order in which this contact displays on the Insurance Portal homepage, relative to other contacts in the list.",
                                }}
                              />
                            </div>
                          </>
                        )}
                        <div className="flex flex-col md:flex-row items-center">
                          <div className="flex-grow-1 flex flex-col md:flex-row gap-x-4">
                            {isEdit ? (
                              <>
                                <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                                  Save Contact
                                </button>
                                <button type="button" className="primaryBtn redDelete" onClick={() => setIsDelete(true)}>
                                  Delete
                                </button>
                              </>
                            ) : (
                              <>
                                <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                                  Add Contact
                                </button>
                              </>
                            )}
                          </div>
                          <span className="justify-center md:justify-end text-sm font-light">*Required field</span>
                        </div>
                      </div>
                    }
                  </Form>
                </>
              );
            }}
          </Formik>
        ) : (
          <SimpleDelete
            id={contactData.id}
            subDescription={`${contactData.firstName} ${contactData.lastName} `}
            description={`Are you sure you want to delete ${contactData.firstName} ${contactData.lastName}  from the Insurance Portal contacts? This cannot be undone.`}
            handleDelete={handleInContactDelete}
            isDeleting={isDeleting}
          />
        )}
      </ModalGlobal>
    </>
  );
};

interface FormAddContactsProps {
  modaltitle: string;
  modaltitlename: string;
  isEdit: boolean;
  id?: number;
  setContactData: Dispatch<React.SetStateAction<GroupContactData>>;
  contactData: GroupContactData;
}

export default AddEditInsuranceContacts;
