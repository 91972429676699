import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetFileAxiosResponseBlob } from "./FileHttpServices";
import { useOnError } from "./useOnError";

const useFileViewer = () => {
  const [getFileURL, setFileURL] = useState<string>("");
  const [isDownload, setIsDownload] = useState<{ isDownload: boolean; fileName: string }>({ isDownload: false, fileName: "" });
  const [getFileId, setFileId] = useState<number>(0);
  const [globalItemId, setGlobalItemId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const setErrorData = useOnError();


  const { refetch: GetFileBlob } = useGetFileAxiosResponseBlob(getFileId, {
    enabled: getFileId > 0,
    async onSuccess(response) {
      if(response.headers["content-type"].indexOf("application/json") > -1) {
        const permissionResp = JSON.parse(await response.data.text());
        if (!permissionResp.success) {
          navigate('/not-found');
          return;
        }
      }
      
      var file = new Blob([response.data], { type: response.data.type });
      var fileURL = URL.createObjectURL(file);
      setFileURL(fileURL);
      if (isDownload?.isDownload) {
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = isDownload.fileName;
        fileLink.click();
      } else {
        !!fileURL && window.open(fileURL);
      }

      URL.revokeObjectURL(fileURL);
      setIsLoading(false);
    },
    onError(error) {
      setErrorData(error);
      setIsLoading(false);
      // navigate("/something-went-wrong");
    },
    onSettled() {
      URL.revokeObjectURL(getFileURL);
      setIsLoading(false);
      setFileId(0);
    },
  });

  const handleFileView = (FileId: number, globalItemId?: number, isDownload?: { isDownload: boolean; fileName: string }) => {
    setFileId(FileId);
    setGlobalItemId(globalItemId);
    isDownload ? setIsDownload(isDownload) : setIsDownload({ isDownload: false, fileName: "" });
    !!getFileId && GetFileBlob();
    setIsLoading(true);
  };

  return { handleFileView, isLoading };
};

export default useFileViewer;
