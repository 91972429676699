import { AccountInfo, BrowserAuthError, EventMessage, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { QueryFunctionContext, useQuery } from "react-query";
import { baseURL, userApiUrl } from "../../endpoints/endpoints";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";
import { CPApiResponse } from "../../utils/interface";
import { AuthorizationContext, AuthorizationContextInterface } from "./AuthorizationContext";
import { scopes, msalFeatureFlag } from '../../utils/Auth/msalConfig';
import { Outlet, useNavigate } from "react-router-dom";

declare type CheckPortalAccessParameters = ['portalAccess', {userId: string | undefined}];

const checkPortalAccess = async ({queryKey}: QueryFunctionContext<CheckPortalAccessParameters>): Promise<boolean> => {
    const [_key, {userId}] = queryKey;
    if (!userId) {
        return Promise.reject(new Error('Invalid UserId'));
    }

    let hasAccess = true;
    if (msalFeatureFlag) {
        const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${userApiUrl}/HasRole`);
        hasAccess = handleAxiosResponse(response);
    }

    return hasAccess;
};

const isCpApiEndpoint = (url?: string): boolean => {
    return (url || '').toLowerCase().includes(baseURL);
}

export const AuthorizationProvider = () => {
    const [hasPortalAccess, setHasPortalAccess] = useState<boolean>(false);
    const [account, setAccount] = useState<AccountInfo>();
    const {accounts, instance} = useMsal();
    const [hasCheckedForAccess, setHasCheckedForAccess] = useState<boolean>(false);
    const navigate = useNavigate();

    /**
     * This function is set as an axios request interceptor\
     * For every axios request, before the request is made, this function will run.\
     * It gets an access from the cache or from azure automatically.\
     * It will add an authorization token to the headers for each request.\
     * There is no need to call this function directly
     * @param config
     * @returns config
     */
    const setAuthHeader = async (config: AxiosRequestConfig) => {
        if (account) {
            const request = {scopes, account};
            try {
                const response = await instance.acquireTokenSilent(request).catch(async (error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        return await instance.acquireTokenPopup({
                            ...request,
                            loginHint: account.username,
                            redirectUri: '/'
                        })
                    }
                });
                if (response) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${response.accessToken}`
                    };
                }
            } catch (e) {}
        }
        return config;
    };

    const handleApiErrorResponseCodes = async (err: AxiosError) => {
        if (account) {
            if (!(err instanceof BrowserAuthError) && isCpApiEndpoint(err.config.url)) {
                if (err.response) {
                    switch (err.response.status) {
                        case 401:
                            // if we get a 401 from the API, attempt to authenticate again. First silently, popup if necessary.
                            const req = {scopes, loginHint: account.username, redirectUri: '/'};
                            const res = await instance.ssoSilent(req).catch(async error => {
                                if (error instanceof InteractionRequiredAuthError) {
                                    return await instance.loginRedirect(req);
                                }
                            });
                            return axios(err.config);
                            break;
                        case 403:
                            if (err.config.url?.includes('HasRole')) {
                                setHasPortalAccess(false);
                            } else {
                                refetch();
                                navigate('/forbidden', { replace: true });
                            }
                            break;
                    }
                }
            }
        }
        return Promise.reject(err);
    };

    useEffect(() => {
        setAccount(accounts.find(a => a.tenantId === process.env.REACT_APP_MSAL_TENANT));
    }, [accounts]);

    useEffect(() => {
        const requestInterceptorId = axios.interceptors.request.use(setAuthHeader);
        const responseInterceptorId = axios.interceptors.response.use(response => response, handleApiErrorResponseCodes);
        return () => {
            axios.interceptors.request.eject(requestInterceptorId)
            axios.interceptors.response.eject(responseInterceptorId);
        };
    }, [account]);

    const userId = account?.localAccountId;
    const userName = account?.username;

    const {refetch} = useQuery<boolean, unknown, boolean, CheckPortalAccessParameters>(['portalAccess', {userId}], checkPortalAccess, {
        onSuccess: setHasPortalAccess,
        onSettled: () => {
            setHasCheckedForAccess(true);
        },
        enabled: !!userId,
        refetchOnWindowFocus: true
    });

    useEffect(() => {
        instance.addEventCallback((message: EventMessage) => {
            if (message.eventType === EventType.LOGIN_SUCCESS && !!userId) {
                refetch();
            }
        });
    }, []);

    const AuthorizationContextValue: AuthorizationContextInterface = {
        hasPortalAccess, account, userId, userName,
        hasCheckedForPortalAccess: hasCheckedForAccess,
        recheckPortalAccess: refetch
    };

    return (
        <AuthorizationContext.Provider value={AuthorizationContextValue}>
            <Outlet />
        </AuthorizationContext.Provider>
    );
};