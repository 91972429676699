import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useParams } from 'react-router-dom';
import { Store } from '../../Store';
import ErrorCard from '../../components/ErrorCard/ErrorCard';
import Footer from '../../components/Footer/Footer';
import Link from '../../components/Link/Link';
import Loader from '../../components/Loader/Loader';
import usePlantSiteDetailsApiCalls from '../../components/PlantSiteDetails/usePlantSiteDetailsApiCalls';
import { useOnError } from '../../customHooks/useOnError';
import useWindowSize from '../../customHooks/useWindowSize';
import { DocumentGroup, DocumentGroups, SiteUnit } from "../../utils/interface";
import { ModuleIDs } from "../../utils/moduleIDs";
import classes from "./Documents.module.css";
import AddEditDocument from "./ModuleSpecifics/AddEditDocument";
import DocumentHeader from "./ModuleSpecifics/DocumentHeader";
import {
  DocumentModuleApiMethods,
  DocumentsAPI as mitReportApi,
} from "./documentApiMethods";
import { CrudMethods, DocumentsAPI } from "./documentGroupApiMethods";

const documentModules: DocumentModuleInterface[] = [
  {
    id: ModuleIDs.PowerReports,
    name: "Documents",
    addEditHeaderName: "Document",
    moduleHeaderContent: <DocumentHeader />,
    addDocumentModalBodyContent: AddEditDocument,
    documentGroupApi: DocumentsAPI,
    documentApi: mitReportApi,
    addModalHeaderContent: [],
  },
];

export interface AddDocumentRequiredProps {
  onSave: (data: any) => void;
  documentGroups: DocumentGroup[];
  siteUnits: SiteUnit[];
  documentModule?: DocumentModuleInterface;
  isEdit: boolean;
  setDeleteDocument: (isDelete: boolean) => void;
  handleCloseModal: () => void;
}

export interface DocumentModuleInterface {
  name: string;
  id: number;
  addEditHeaderName: string;
  addModalHeaderContent: string[];
  moduleHeaderContent: React.ReactElement | string;
  addDocumentModalBodyContent: React.FunctionComponent<AddDocumentRequiredProps>;
  documentGroupApi: CrudMethods<any, any, any>;
  documentApi: DocumentModuleApiMethods<any>;
}

export interface DocumentsOutletInterface {
  documentModules: DocumentModuleInterface[];
  documentModule?: DocumentModuleInterface;
}

interface DocumentsContainerProps {}

function DocumentsContainer(props: DocumentsContainerProps) {
  const { isMobile } = useWindowSize();
  const { moduleName } = useParams();
  const [documentGroups, setDocumentGroups] = useState<DocumentGroup[]>([]);
  const { showError, setPagePermissions } = useContext(Store);
  const setErrorData = useOnError();
  usePlantSiteDetailsApiCalls();

  const sortByDisplayOrderAsc = (
    { displayOrder: a }: DocumentGroup,
    { displayOrder: b }: DocumentGroup
  ): number => {
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
  };

  useEffect(() => {
    setDocumentGroups([]);
  }, [moduleName]);

  const documentModule = documentModules.find(
    (module) => module.name === moduleName
  );

  const { refetch, isLoading, isFetching } = useQuery(
    `${documentModule?.name}.documentGroups.get`,
    documentModule
      ? documentModule.documentGroupApi.getAll
      : () => new Promise((resolve) => resolve([])),
    {
      refetchOnWindowFocus: true,
      onSuccess: (response: DocumentGroups) => {
        const { items } = response;

        setPagePermissions?.({
          canAdd: response.canAdd,
          canAdmin: response.canAdmin,
          canDelete: response.canDelete,
          canEdit: response.canEdit,
          canView: response.canView,
          pageKey: `${documentModule?.name}`,
        });
        setDocumentGroups(items?.sort(sortByDisplayOrderAsc) || []);
      },
      onError: (error: AxiosError<string>) => {
        setErrorData(error);
      },
    }
  );
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div
      className={`${classes.flex_column} ${classes.documentation_container} ${
        isMobile ? classes.is_mobile : ""
      }`}
    >
      <div className="content" style={{ marginBottom: 20 }}>
        {showError.isError && (
          <ErrorCard
            ErrorMessage={showError.title}
            onRefresh={handleRefresh}
            ErrorType={showError.ErrorType}
          />
        )}
        {documentModule && (
          <>
            <div className="path breadcrumb">
              <Link to={"/"} className="link_text">
                Insurance Portal Home
              </Link>{" "}
              / {documentModule.name}
            </div>
            <div className="login-header">{documentModule.name}</div>
            <div
              className={`card ${classes.flex_column} ${classes.documentation_container_outlet}`}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <div
                  className={`${classes.module_container} ${classes.flex_column}`}
                >
                  <Outlet
                    context={{
                      documentModules,
                      documentModule,
                      refetch,
                      documentGroups: isFetching ? [] : documentGroups,
                      setDocumentGroups,
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="content flex-grow-0 flex-end">
        <Footer />
      </div>
    </div>
  );
}

export default DocumentsContainer;