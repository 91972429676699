import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import { ItemListColumnDefinition, SortablePropertiesDefinition } from "../../components/List/ItemList";
import { TabbedSections } from "../../components/List/TabbedSections";
import Loader from "../../components/Loader/Loader";
import EmptyModal from "../../components/Modals/EmptyModal";
import Pill from "../../components/Pill/Pill";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import { downloadFileByUrl } from "../../customHooks/FileHttpServices";
import { createUser, useGetAllUsers, useSearchUserByEmail } from "../../customHooks/UserHttpServices";
import useWindowSize from "../../customHooks/useWindowSize";
import { apiGenerateAccessRequestReportUrl, apiGenerateUserReportUrl } from "../../endpoints/endpoints";
import ImageImports from '../../utils/ImageImports';
import { ShowErrorInterface, User } from "../../utils/interface";
import { MainContainer } from "../MainContainer";
import "./UserManagementContainer.css";
import classes from "./UserManagementContainer.module.css";

const { magnifyGlassBlack, spinner } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

type Section = 'internal'|'external'|'default';
interface UserManagementProps {
    section: Section;
}
type SectionFilters = {
    [key in Section]: (u: User) => boolean;
}

type FilterStatus = 'any'|'active'|'inactive';
interface FilterStructure {
    status: FilterStatus;
}

type DownloadReportType = "accessReport" | "userReport";

export const UserManagementContainer = ({section}: UserManagementProps) => {
  const [searchUsers, setSearchUsers] = useState<boolean>(false);
  const [userCreated, setUserCreated] = useState<User>();
  const [filters, setFilters] = useState<FilterStructure>({status: 'any'});
  const [showReportMenu, setShowReportMenu] = useState<boolean>(false);
  const [forceClose, setForceClose] = useState<number>(0);
  const [downloadReport, setDownloadReport] = useState<DownloadReportType | null>(null);
  const [loadingUserReport, setLoadingUserReport] = useState<boolean>(false);
  const {refetch: refetchUsers, data: users, isLoading, isFetching} = useGetAllUsers({
      initialData: []
  });

  const [addUserError, setAddUserError] = useState<ShowErrorInterface>({
      title: "",
      isError: false,
      ErrorType: null,
  });

  const handleClearErrors = () => {
      setAddUserError({
          title: "",
          isError: false,
          ErrorType: null,
      });
  }

  const navigate = useNavigate();
  const {mutate: createNewUser} = useMutation(createUser, {
      onSuccess: (u) => {
          setSearchUsers(false);
          setUserCreated(u);
          refetchUsers();
      }, 
  onError: (error: any) => {
          setAddUserError(
              {
                  ErrorType: "danger",
                  isError: true,
                  title: error.response?.data === 'User record could not be created: User already exists' ? 'This user has already been added to Insurance Portal.' : error.response?.data || "User record could not be created"
              })
  }
  });

  const sections = [
      {
          path: '/administration/user-management/external',
          text: 'External Users'
      },
      {
          path: '/administration/user-management/internal',
          text: 'Internal Users'
      }
  ];

  const handleDownloadReport = async (url: string) => {
    setLoadingUserReport(true);
    try {
      await downloadFileByUrl(url);
    } finally {
      setLoadingUserReport(false);
    }
  };

  const handleGenerateReport = async () => {
    switch (downloadReport) {
      case "accessReport":
        await handleDownloadReport(apiGenerateAccessRequestReportUrl);

        break;
      case "userReport":
        await handleDownloadReport(apiGenerateUserReportUrl);

        break;
    }
  };

  const handleCloseSearchUserModal = () => {
      setSearchUsers(false);
      handleClearErrors();
  };

  useEffect(() => {
      if (section === 'default') {
          navigate(sections[0].path);
      }
  }, [section]);

  const sectionFilters: SectionFilters = useMemo(() => {
      return {
          external: u => !u.mpInternal,
          internal: u => u.mpInternal,
          default: () => true
      }
  }, []);

  const filterCount = useMemo(() => {
      let c = 0;
      if (filters.status !== 'any') {
          c++;
      }
      return c;
  }, [filters]);

  const filterUsers = useCallback((user: User): boolean => {
      switch (filters.status) {
          case 'any':
              return true;
          case 'active':
              return !user.accountDisabled;
          case 'inactive':
              return user.accountDisabled;
      }
  }, [filters]);

  const sectionFilter = useCallback((user: User): boolean => {
      return sectionFilters[section](user);
  }, [section]);

  const onConfigure = useCallback((u: User) => {
      navigate(`/administration/user-management/${u.id}`);
  }, []);

  const columns: ItemListColumnDefinition<User>[] = [
      {
          title: 'User ID',
          key: 'id'
      },
      {
          title: 'First Name',
          key: 'fName',
      },
      {
          title: 'Last Name',
          key: 'lName'
      },
      {
          title: 'Email Address',
          key: 'email'
      },
      {
          title: 'Account Status',
          key: 'accountDisabled',
          component: (({data}) => <>{data.accountDisabled? 'Deactivated' : 'Active'}</>)
      },
      {
          title: '',
          key: 'aDuid',
          component: (({data}) => <Link className="text-ocean font-bold no-underline" to={`/administration/user-management/${data.id}`}>Manage</Link>)
      }
  ];
  const sortableProperties: SortablePropertiesDefinition<User>[] = [
        {
          label: "First Name: A to Z",
          key: "fName",
          direction: "asc",
        },
        {
          label: "First Name: Z to A",
          key: "fName",
          direction: "desc",
        },
        {
          label: "Last Name: A to Z",
          key: "lName",
          direction: "asc",
          default: true,
        },
        {
          label: "Last Name: Z to A",
          key: "lName",
          direction: "desc",
        },
        {
          label: "Clear Sort",
          key: "id",
          direction: "asc",
        }
  ];

  return <MainContainer breadCrumbs={['Administration', 'User Management']} title="User Management">
      {searchUsers && <SearchUsersModal onClose={handleCloseSearchUserModal} onCreateUser={createNewUser} addUserError={addUserError} setAddUserError={setAddUserError} clearErrors={handleClearErrors} />}
      {userCreated && <UserCreatedModal user={userCreated} onClose={() => setUserCreated(undefined)} onConfigure={() => onConfigure(userCreated)} />}
      <div className={`flex flex-col md:flex-row gap-6 justify-between`}>
          <div className="flex flex-col md:flex-row items-center gap-2 wrap">
              <div className="flex flex-col gap-2 grow">
                  <span className="font-bold text-2xl self-stretch">Insurance Portal Users</span>
                  <span className="text-base">See below for a list of all users of the Insurance Portal (active &amp; inactive):</span>
              </div>
          </div>
          <div className="flex flex-col md:flex-row items-start gap-2">
          <div
            className="flex flex-col grow gap-2 lg:gap-4"
            onClick={() => setShowReportMenu(!showReportMenu)}
          >
            <ButtonWithTooltip
              className="whiteBtn"
              text={
                loadingUserReport ? (
                  <Loader
                    marginTop="0"
                    marginBottom="0"
                    iconStyle={{ width: 18 }}
                  />
                ) : (
                  "Generate Report"
                )
              }
              forceClose={forceClose}
              toggleClickWatcher={false}
              tooltipContent={
                <div className="flex flex-col items-start p-4 gap-6 whitespace-nowrap">
                  <div className="flex flex-col items-start gap-2">
                    <span className="font-bold">Select report type to download:</span>
                    <label className="flex flex-row gap-2 items-center">
                      <input
                        type="radio"
                        name="downloadReportType"
                        value="accessReport"
                        onChange={(e) => setDownloadReport("accessReport")}
                        checked={downloadReport === "accessReport"}
                      />
                      Registration Report
                    </label>
                    <label className="flex flex-row gap-2 items-center">
                      <input
                        type="radio"
                        name="downloadReportType"
                        value="userReport"
                        onChange={(e) => setDownloadReport("userReport")}
                        checked={downloadReport === "userReport"}
                      />
                      Portal User Report
                    </label>
                  </div>
                  <div className="flex flex-row w-full">
                    <Button
                      className="darkBlue"
                      text="Download"
                      onClick={() => {
                        handleGenerateReport();
                        setForceClose((fc) => ++fc);
                      }}
                      disabled={!downloadReport}
                    />
                  </div>
                </div>
              }
            />
          </div>
            <div className="flex flex-col md:flex-row grow gap-2 lg:gap-4">
                <Button className="darkBlue" textClassName="leading-5 content-center" text="Add New User" onClick={() => setSearchUsers(true)} />
            </div>
          </div>
      </div>
      <hr className="m-0 text-[#B3B3B3]" />
      <TabbedSections sections={sections}
                  data={(users ?? []).filter(sectionFilter).filter(filterUsers)}
                  columns={columns}
                  sortValues={sortableProperties}
                  accordionTitle={u => `${u.fName} ${u.lName}`}
                  filterContent={<Filters filters={filters} onFiltersUpdated={setFilters} />}
                  filterCount={filterCount}
                  filters={<FilterPills filters={filters} onFiltersUpdated={setFilters} />} />
  </MainContainer>;
};

const SearchUsersModal = ({onCreateUser, onClose, addUserError, setAddUserError, clearErrors}: {onCreateUser: (u: User) => void, onClose: () => void, addUserError: ShowErrorInterface, setAddUserError: (e: ShowErrorInterface) => void,  clearErrors: () => void,}) => {
  const [emailAddress, setEmailAddress] = useState<string>('');
  const {refetch: searchEmail, isLoading, error, data: foundUser, isError, isFetched, remove: resetSearchQuery} = useSearchUserByEmail(emailAddress);
  const {isMobile} = useWindowSize();
      
  let width: number|undefined;
  width = isMobile ? 300 : 700;
  let footer: string|ReactElement = '';
  if (isLoading) {
      footer = <div className="flex flex-row grow justify-center"><img src={spinner} alt="loading" className="text-center" /></div>;
  }

  useEffect(() => {
      if (isError && isFetched && !addUserError.isError) {
          setAddUserError({
              title: "Please check the email address and try again. For all other questions, please contact Mitsubishi Power IT.",
              isError: true,
              ErrorType: 'danger',
          })
      }
  }, [isError, isFetched, addUserError.isError, setAddUserError]);

  if (isFetched) {
      footer = (
          <div className="flex flex-row !pb-0 gap-4">
              <Button className="darkBlue" textClassName="leading-5 content-center" text="Add User" disabled={isError || isLoading || addUserError.isError} onClick={() => foundUser && onCreateUser(foundUser)} />
              <Button className="whiteBtn" textClassName="leading-5 content-center" text="Cancel" onClick={onClose} />
          </div>
      );
  }

  return <EmptyModal body={<div className="flex flex-col items-start gap-6">
      <span className="self-stretch">
          To add a new user to Insurance Portal, please search Active Directory with the user's email address (provided by Mitsubishi Power IT).
      </span>
      <div className="flex flex-row items-start gap-6 self-stretch">
          <div className="flex flex-col items-start gap-2 grow">
              <span className="font-bold">Search Active Directory by Email Address</span>
              <div className="flex flex-col md:flex-row grow w-full !pb-0 items-center gap-3 wrap">
                  <div className="relative flex flex-col md:flex-row w-full wrap">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                          <img src={magnifyGlassBlack} alt="Search" className="h-4 w-4 mx-1" />
                      </div>
                      <input 
                          type="email" 
                          className="input border border-grey !outline-none rounded flex flex-row w-full grow block text-black text-sm pl-8 p-2" 
                          placeholder="Email address"
                          value={emailAddress}
                          onChange={e => {
                                  clearErrors();
                                  resetSearchQuery();
                                  setEmailAddress(e.target.value);
                              }
                          }
                      />
                  </div>
                  <div className="flex wrap">
                      <Button className="whiteBtn" textClassName="leading-5 content-center" disabled={!emailAddress.length} text="Search" onClick={() => { clearErrors(); searchEmail(); }} />
                  </div>
              </div>
          </div>
      </div>
      {foundUser? (
          <div className="flex flex-col items-start gap-4">
              <span>User found:</span>
              <span>
                  {foundUser.fName} {foundUser.lName}<br />
                  {foundUser.email}<br />
                  {foundUser.aDuid}
              </span>
          </div>
      ) : (isFetched? 
          <span>User not found.</span> : '')
      }
      {addUserError.isError && 
          <div className="flex flex-col w-full !p-0">
              <Alert dismissible={false} type="error" onClose={() => {
                  resetSearchQuery();
                  clearErrors();
                  }}>
                      {addUserError.title}
              </Alert>
          </div>
      }
  </div>} width={width} footer={footer} heading="Add New User" onClose={onClose} shown={true} />
};

const UserCreatedModal = ({user, onClose, onConfigure}: {user: User, onClose: () => void, onConfigure: (u: User) => void}) => {
  let modalBody: ReactElement|undefined;
  let modalFooter: ReactElement|undefined;
  const {isMobile} = useWindowSize();
  let width: number|undefined;
  width = isMobile ? 300 : 564;
  modalBody = (
      <div className="flex flex-col items-center gap-4 wrap">
          <div className="text-center">{`"${user.fName} ${user.lName}" was successfully added to the Insurance Portal user list. By default, they were assigned standard user permissions to all portal content. Would you like to view this user’s details and configuration at this time?`}</div>
      </div>
  );

  modalFooter = (
      <div className="flex gap-4" style={{justifyContent: "center"}}>
          <Button className="darkBlue" textClassName="leading-5 content-center" text="Configure User" onClick={() => onConfigure(user)} />
          <Button className="whiteBtn" textClassName="leading-5 content-center" text="Not Now" onClick={onClose} />
      </div>
  );
  return <EmptyModal 
              body={modalBody}
              footer={modalFooter} 
              heading="User Successfully Added" onClose={onClose} shown={true} width={width} headingClass="text-center" />
};

const Filters = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {

  const [status, setStatus] = useState<FilterStatus>('any');
  useEffect(() => {
    if (filters.status) {
      setStatus(filters.status);
    }
  }, [filters]);

  const applyFilters = useCallback(() => {
    onFiltersUpdated({...filters, status: status});
  }, [filters, status, onFiltersUpdated]);

  const filtersChanged = useMemo(() => {
    const obj1 = Object.entries({
      status: filters.status
    });
    const obj2 = Object.entries({
      status: status
    });
    return obj1.toString() !== obj2.toString();
  }, [filters, status]);

  const canApplyFilters = ((status !== 'any') && filtersChanged);
  
  return (
    <div className="flex flex-col items-start p-4 gap-6 whitespace-nowrap">
      <div className="flex flex-col items-start gap-2">
        <span className="font-bold">Account Status:</span>
        <label className="flex flex-row !pb-0 gap-2 items-start">
          <input type="radio"
                  name="status"
                  value="none"
                  onChange={e => setStatus('active')}
                  checked={status === 'active'} 
          />
          Active
        </label>
        <label className="flex flex-row !pb-0 gap-2 items-start">
          <input type="radio"
                  name="status"
                  value="some"
                  onChange={e => setStatus('inactive')}
                  checked={status === 'inactive'} 
          />
          Deactivated
        </label>
        <div className={`${classes.filter_tooltip_field}`}>
          <Button
            text="Apply"
            className="darkBlue"
            onClick={applyFilters}
            disabled={!canApplyFilters}
          />
        </div>
      </div>
    </div>
  );
};

const FilterPills = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {
  const statusMap = {
      active: 'Active',
      inactive: 'Deactivated'
  };
  return (
      <div className={classes.filter_pill_container}>
          {filters.status !== 'any'? (
          <Pill dismissible={true} className={classes.filter_pill}
                  onDismiss={() => onFiltersUpdated({...filters, status: 'any'})}
                  type="default">
              {statusMap[filters.status]}
          </Pill>
          ) : ''}
      </div>
  );
};