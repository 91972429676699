import React, { FunctionComponent, ReactElement, CSSProperties } from "react";
// import { Link } from 'react-router-dom';
import "./Button.css";

interface buttonProps {
  className: string;
  style?: CSSProperties;
  textClassName?: string;
  text: string|ReactElement;
  preImg?: ReactElement;
  img?: ReactElement;
  disabled?: boolean;
  onClick?: () => void;
  type?: "button" | "submit";
}



const Button: FunctionComponent<buttonProps> = (props: buttonProps) => {
  return (
    <button
      disabled={props.disabled}
      type={props.type}
      className={"primaryBtn " + props.className}
	  style={props.style} 
      onClick={(e) => {
        e.preventDefault();
        props.onClick && props.onClick();
      }}
    >
      {props.preImg && props.preImg} {" "}
      <span className={props.textClassName && props.textClassName}> {props.text}</span>
      {props.img && props.img}
    </button>
  );
}

Button.defaultProps = {
  type: "button"
}

export default Button;
