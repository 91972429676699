import React, { useContext, useEffect, useMemo, useState } from "react";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import RelatedLinks, { RelatedLinkTarget } from "../../components/RelatedLinks/RelatedLinks";
import SectionCard from "../../components/SectionCard/SectionCard";
import { useOnError } from "../../customHooks/useOnError";
import { AnnouncementData, CPApiResponse, GroupContactData, PortalUpdateData, PublishedLinksData } from "../../utils/interface";
import { Announcements } from "./Announcements/Announcements";
import "./Home.css";
import Contacts from "./Resources/Contacts/Contacts";
import PublishedLinks from "./Resources/PublishedLinks/PublishedLinks";
import Updates from "./Resources/Updates/Updates";

import { CloseAllModal } from "../../utils/CloseAllModal";

import { AxiosError } from "axios";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import { useIsInternalAdmin } from "../../customHooks/useIsInternalAdmin";
import useWindowSize from "../../customHooks/useWindowSize";
import { TermsOfUse, Confidentiality } from "../../utils/globalVariables";
import { useGetAll_Announcements } from "./HttpServices/AnnouncementHttpServices";
import { useGetAll_GroupContacts } from "./HttpServices/GroupContactHttpServices";
import { useGetAll_PortalUpdates } from "./HttpServices/PortalUpdatesHttpServices";
import { useGetAll_PublishedLinks } from "./HttpServices/PublishedLinksHttpServices";

function Home() {
  const { showError, isModal, setIsModal } = useContext(Store);

  const setErrorData = useOnError();
  const [announcements, setAnnouncements] = useState<AnnouncementData[] | []>([]);
  const [contacts, setContacts] = useState<GroupContactData[] | []>([]);
  const [publishedLinks, sePublishedLinks] = useState<PublishedLinksData[] | []>([]);
  const [portalUpdate, setPortalUpdate] = useState<PortalUpdateData[] | []>([]);
  const { isDesktop, isTablet, isMobile } = useWindowSize();
  const isInternalAdmin = useIsInternalAdmin();

  // -----------------------------------------------------------------//

  useGetAll_Announcements({
    onSuccess: (AnnouncementData: CPApiResponse<AnnouncementData[]>) => {
      setAnnouncements(AnnouncementData.data.sort((a,b) =>  a.displayOrder - b.displayOrder));
    },
    onError: (error: unknown) => {
      setAnnouncements([]);
      setErrorData(error);
    },
  });

  // -----------------------------------------------------------------//

  useGetAll_GroupContacts({
    onSuccess: (GroupContactData: CPApiResponse<GroupContactData[]>) => {
      setContacts(GroupContactData.data.sort((a,b) =>  a.displayOrder - b.displayOrder));
    },
    onError: (error: unknown) => {
      setContacts([]);
      setErrorData(error);
    },
  });

  // -----------------------------------------------------------------//
  // -----------------------------------------------------------------//

  useGetAll_PublishedLinks({
    onSuccess: (PublishedLinksData: CPApiResponse<PublishedLinksData[]>) => {
      sePublishedLinks(PublishedLinksData.data.sort((a,b) =>  a.displayOrder - b.displayOrder));
    },
    onError: (error: unknown) => {
      sePublishedLinks([]);
      setErrorData(error);
    },
  });

  // -----------------------------------------------------------------//

  useGetAll_PortalUpdates({
    onSuccess: (PortalUpdateData: CPApiResponse<PortalUpdateData[]>) => {
      setPortalUpdate(PortalUpdateData.data.sort((a,b) =>  a.displayOrder - b.displayOrder));
    },

    onError: (error: unknown) => {
      setPortalUpdate([]);
      setErrorData(error);
    },
  });

  // -----------------------------------------------------------------//

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleClick = () => {
    alert("clicked");
  };

  const relatedData = {
    relatedTitle: "Mitsubishi Insurance Community Website Terms of Use",
    relatedSections: [
      {
        title: "",
        description: "Click here to view the Mitsubishi Power Insurance Community Website Terms of Use.",
        buttonLink: "View Terms of Use",
        route: "https://www.mhi.com/conditions.html",
        target: RelatedLinkTarget._blank,
        onClick: handleClick,
      },
    ],
  };

  const handleAddAnnouncement = () => {
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isAdd: {
          ...isModal.isAdd,
          isAnnouncement: true,
        },
      });
  };

  return (
    <>
      <div className="column flex-grow-1">
        <div className="content">
          {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={handleRefresh} ErrorType={"danger"} />}
          <div style={{marginBottom:"10px"}}>
            <h1 className="login-header">Insurance Portal</h1>            
          </div>
          <div className="w-full md:w-2/3">
              This portal provides information for Insurance Community members interested in Mitsubishi Power’s power generation products and services. Use the links in the Resources section to reach contacts, find published articles and papers, or review updates. Use the links in the footer to access Mitsubishi Power’s websites, which contain a wide variety of company and product information including news releases and other announcements.
          </div>
          <div className="flex" style={{ alignItems: "center" }}>
            <div className="section-header">Announcements</div>
            {isInternalAdmin && (
              <div style={{ marginLeft: "auto" }}>
                <Button className="primaryBtn whiteBtn" onClick={handleAddAnnouncement} text={"Post Announcement"} />
              </div>
            )}
          </div>
          <div className="card issue_prt_tech_wrp section-cards">
            <Announcements announcements={announcements} />
          </div>
          <div className="section-header">Resources</div>
          <div className="card section-cards MPR-margin">
            <SectionCard>
              <Contacts contacts={contacts} />
            </SectionCard>
            <SectionCard>
              <PublishedLinks publishedLinks={publishedLinks} />
            </SectionCard>
            <SectionCard>
              <Updates updates={portalUpdate} />
            </SectionCard>
          </div>
        </div>
        <div className={`relatedLinks`} style={{margin: isDesktop ? 48 : 20}}>
          <div className='relatedLinks_header'>
            Mitsubishi Power Insurance Community Website Terms of Use, Confidential and Proprietary Information
          </div>
          <div>
            <div className='relatedLinks_paragraph'>
              Click here to view the Mitsubishi Power Insurance Community Website Terms of Use, Confidential and Proprietary Information
            </div>
            <div className='flex gap-4'>
              <Button className="whiteBtn"
                      style={{width: isMobile ? "100%" : isTablet ? 300 : "unset"}}
                      text="View Terms of Use"
                      onClick={() => window.open(TermsOfUse, '_blank', 'noopener,noreferrer')} />
              <Button className="whiteBtn"
                      style={{width: isMobile ? "100%" : isTablet ? 300 : "unset"}}
                      text="View Confidential and Proprietary Information"
                      onClick={() => window.open(Confidentiality, '_blank', 'noopener,noreferrer')} />
            </div>
          </div>
        </div>
        <div className="content content-footer flex-grow-0 flex-end">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Home;
