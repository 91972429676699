import React from 'react';

import ellispis from "../assets/3_dots_horizontal.svg";
import logo from "../assets/MP_logo_horizontal_1.svg";
import tooltip from '../assets/Tool-Tip-icon.svg';
import arrowDown from "../assets/accordian-down.svg";
import add from "../assets/add.svg";
import alarmWarningBlack from "../assets/alarm-warning-black.svg";
import alarmWarning from "../assets/alarm-warning.svg";
import downArrow from '../assets/arrow-down.svg';
import { default as Arrow, default as menuOpen } from "../assets/arrow-right.svg";
import { default as attachment, default as attachmentIcon } from '../assets/attachment.svg';
import blueTick from '../assets/blue_tick.svg';
import calendar from "../assets/calendar_icon.svg";
import checkbox from "../assets/checked_checkbox.svg";
import menuCloseIcon from "../assets/close-menu.svg";
import closeImg from '../assets/close.svg';
import closeError from '../assets/close_error.svg';
import { default as copyLink, default as copy_link } from '../assets/copy_link.svg';
import dangerIcon from '../assets/danger_icon.svg';
import disabled_checkbox from "../assets/disabled_checkbox.svg";
import docDummy from '../assets/doc-dummy.jpg';
import documentSearchBlue from "../assets/document-search-blue.svg";
import documentSearch from "../assets/document-search.svg";
import documentWarn from "../assets/document-warning-1.svg";
import documentIcon from "../assets/document.svg";
import download from '../assets/download.svg';
import dragReorder from '../assets/drag_reorder.svg';
import pencilWhite from "../assets/editWhite.svg";
import excelDummy from '../assets/excel-dummy.jpg';
import plant from "../assets/factory-grey.svg";
import fileTab from "../assets/file-tab.svg";
import file_edit from '../assets/file_edit.svg';
import filter from '../assets/filter.svg';
import gearTime from '../assets/gear-time.svg';
import gear from '../assets/gear.svg';
import gpsPin from '../assets/gps-pin.svg';
import bellIcon from "../assets/grey-bell-icon.svg";
import help from '../assets/help.svg';
import home from "../assets/home.svg";
import homeBlue from "../assets/home_blue.svg";
import info from '../assets/info.svg';
import documentWarnBlue from "../assets/issue_report_blue.svg";
import linkout from "../assets/linkout.svg";
import link from "../assets/links_icon.svg";
import spinner from "../assets/loader.gif";
import lockUnlock from '../assets/lock-unlock.svg';
import lock from "../assets/lock_icon.svg";
import magnifyGlassBlack from "../assets/magnifyGlass_black.svg";
import mic from "../assets/mic.svg";
import openClose from "../assets/nav_icon.svg";
import menuOpenIcon from "../assets/open-menu.svg";
import partsNservices from "../assets/parts-services-1.svg";
import pencil from "../assets/pencil.svg";
import factory from '../assets/plant.svg';
import plantBlue from "../assets/plant_info_blue.svg";
import print from '../assets/print.svg';
import remoteMonitoring from '../assets/remote-monitoring.svg';
import magnifyGlass from "../assets/search-loupe.svg";
import settings from "../assets/settings.svg";
import share from '../assets/share.svg';
import success from '../assets/success.svg';
import support from "../assets/support.svg";
import avatar from "../assets/temp/Avatar.png";
import nameplate_attachmentIMG from "../assets/temp/nameplate_attachment.jpg";
import SolarIsmg from "../assets/temp/solar-img.png";
import SteamIsmg from "../assets/temp/steam-img.png";
import trash from '../assets/trash.svg';
import unchecked_checkbox from "../assets/unchecked_checkbox.svg";
import update from "../assets/update_icon.svg";
import userGroupIcon from "../assets/user-group-icon.svg";
import userGroup2 from '../assets/user-group.svg';
import userGroup from '../assets/userGroup.svg';

const ImageImports = {
  calendar,
  docDummy,
  excelDummy,
  link,
  update,
  lock,
  alarmWarningBlack,
  mic,
  blueTick,
  success,
  info,
  add,
  spinner,
  avatar,
  arrowDown,
  fileTab,
  nameplate_attachmentIMG,
  print,
  download,
  file_edit,
  trash,
  Arrow,
  ellispis,
  SolarIsmg,
  SteamIsmg,
  closeError,
  dangerIcon,
  pencilWhite,
  pencil,
  factory,
  gpsPin,
  downArrow,
  linkout,
  alarmWarning,
  menuOpenIcon,
  bellIcon,
  menuCloseIcon,
  logo,
  tooltip,
  home,
  closeImg,
  homeBlue,
  plant,
  plantBlue,
  documentWarn,
  documentWarnBlue,
  partsNservices,
  documentSearch,
  documentSearchBlue,
  support,
  settings,
  menuOpen,
  openClose,
  magnifyGlass,
  magnifyGlassBlack,
  filter,
  gear,
  dragReorder,
  userGroup,
  attachment,
  copyLink,
  help,
  remoteMonitoring,
  gearTime,
  userGroup2,
  userGroupIcon,
  documentIcon,
  attachmentIcon,
  copy_link,
  lockUnlock,
  share,
  checkbox,
  unchecked_checkbox,
  disabled_checkbox,
}
export default ImageImports