
import React, {useState, useLayoutEffect, useRef, MouseEventHandler} from 'react';
import classes from "./EmptyModal.module.css";
import ImageImports from "../../utils/ImageImports";
import useWindowSize from '../../customHooks/useWindowSize';
const {closeImg} = ImageImports;

function EmptyModal({shown, heading, body, footer, width: w, footerClass, headingClass, onClose}: EmptyModalProps) {
    const [top, setTop] = useState<string>('50px');
    const [left, setLeft] = useState<string>('50px');
    const ref = useRef<HTMLDivElement>(null);
    const {isMobile} = useWindowSize();

    useLayoutEffect(() => {
        setTop(`calc(50% - ${ref.current?.clientHeight}px/2)`);
        setLeft(`calc(50% - ${ref.current?.clientWidth}px/2)`);
    }, [heading, body, footer]);

    const width = isMobile? '100%' : `${w}px`;
    return (
        <>
            {shown && (
                <>
                    <div className={classes.Backdrop} onClick={onClose}></div>
                    <div ref={ref} className={`${classes.ModalContainer} ${isMobile? classes.IsMobile : ''}`} style={{width, top, left}}>
                        <div className={classes.ModalHeader}>
                            <span className={`${classes.HeadingText} ${headingClass ?? ''}`}>{heading}</span>
                            <div className={classes.CloseIcon} onClick={onClose}>
                                <img src={closeImg} alt="Close" />
                            </div>
                        </div>
                        {body}
                        <div className={`${classes.ModalFooter} ${footerClass ?? ''}`}>
                            {footer}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

interface EmptyModalProps {
    shown: boolean,
    heading: React.ReactElement | string,
    body: any,
    footer: any,
    onClose: MouseEventHandler,
    width?: number,
    footerClass?: string,
    headingClass?: string
}

EmptyModal.defaultProps = {
    width: 700,
    footerClass: '',
    headingClass: ''
};

export default EmptyModal;