import React from "react";
import Button from "../../../../components/Button/Button";
import ImageImport from "../../../../utils/ImageImports";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import classes from "./Announcement.module.css";
import { AnnouncementProps } from "../../../../utils/interface";
import Moment from "react-moment";
import { useIsInternalAdmin } from "../../../../customHooks/useIsInternalAdmin";
import { useNavigate } from "react-router-dom";

const Announcement = ({ id, title, description, date, handleEditAnnouncement }: AnnouncementProps) => {
  const { mic, pencil } = ImageImport;

  const navigate = useNavigate();
  
  const isInternalAdmin = useIsInternalAdmin();

  return (
    <SectionCard>
      <div className={classes.flex}>
        <div className={`${classes.header} ${classes.mb24}`}>
          <div className="">
            <img src={mic} className={classes.icon1} alt="" />
          </div>
          {isInternalAdmin && (
            <div className="">
              <img src={pencil} onClick={() => handleEditAnnouncement(id)} style={{ width: "15px", cursor: "pointer" }} alt="" />
            </div>
          )}
        </div>
        <div className={`${classes.row} ${classes.mb24}`}>
          <h2 className={classes.title}>{title} </h2>
        </div>
        <div className={`${classes.row} ${classes.mb8}`}>
          <div className={classes.date}>
            <Moment date={date} format={"MM/DD/YYYY"} />
          </div>
        </div>
        <div className={`${classes.row} ${classes.mb30}`}>
          <p className={classes.desc}>{description}</p>
        </div>
        <div className={`${classes.row} ${classes.mb8}`}>
          <span className="grow">
            <Button className="darkBlue" text="View All Documents" onClick={() => navigate(`documents/Documents`)} />
          </span>
        </div>
      </div>
    </SectionCard>
  );
};

export default Announcement;
