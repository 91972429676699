import axios, { AxiosResponse } from "axios";
import { UseQueryOptions, useQuery, useMutation } from "react-query";
import { apiAnnouncement, apiGetAllannouncement } from "../../../endpoints/endpoints";
import { queryKeys } from "../../../react-query/queryKeys";
import { CPApiResponse, AnnouncementData, FormDataAnnouncement, deleteInterface } from "../../../utils/interface";
import { UnitApi } from "../../PlantInformation/UnitHttpServices";

interface AnnouncementCrudMethods<A, B, D> {
  get: (id: number) => Promise<B>;
  getAll: () => Promise<A>;
  create: (formdata: any) => Promise<B>;
  update: (formdata: any) => Promise<B>;
  delete: (id: number) => Promise<D>;
}

export const AnnouncementApi: AnnouncementCrudMethods<CPApiResponse<AnnouncementData[]>, CPApiResponse<AnnouncementData>, deleteInterface> = {
  get: async (id: number): Promise<CPApiResponse<AnnouncementData>> => {
    const response = await axios({
      url: `${apiAnnouncement}/${id}`,
      method: "GET",
    });
    return response.data;
  },
  getAll: async (): Promise<CPApiResponse<AnnouncementData[]>> => {
    const response = await axios({
      url: `${apiGetAllannouncement}`,
      method: "GET",
    });
    return response.data;
  },
  create: async (data: FormDataAnnouncement): Promise<CPApiResponse<AnnouncementData>> => {
    const response = await axios({
      url: `${apiAnnouncement}`,
      method: "POST",
      data: data,
    });
    return response.data;
  },
  update: async (data: FormDataAnnouncement): Promise<CPApiResponse<AnnouncementData>> => {
    const response = await axios({
      url: `${apiAnnouncement}/${data.id}`,
      method: "PUT",
      data: data,
    });
    return response.data;
  },
  delete: async (id: number): Promise<deleteInterface> => {
    const response = await axios({
      url: `${apiAnnouncement}/${id}`,
      method: "DELETE",
    });
    return response.data;
  },
};

export const useGet_Announcements = (id: number, options: Omit<UseQueryOptions<CPApiResponse<AnnouncementData>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<AnnouncementData>>([queryKeys.AnnouncementApi, id], () => AnnouncementApi.get(id), {
    ...options,
  });
};
export const useGetAll_Announcements = (options: Omit<UseQueryOptions<CPApiResponse<AnnouncementData[]>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<AnnouncementData[]>>(queryKeys.AnnouncementApi, AnnouncementApi.getAll, {
    ...options,
  });
};

export const useCreate_Announcements = () => {
  return useMutation(AnnouncementApi.create);
};

export const useUpdate_Announcements = () => {
  return useMutation(AnnouncementApi.update);
};
export const useDelete_Announcements = () => {
  return useMutation(AnnouncementApi.delete);
};

