
import {Outlet, useLocation, useOutletContext} from 'react-router-dom';
import {DocumentsOutletInterface} from './DocumentsContainer';
import classes from './Documents.module.css';
import {DocumentModuleInterface} from './DocumentsContainer';
import { DocumentGroup } from '../../utils/interface';
import useWindowSize from '../../customHooks/useWindowSize';



export interface DocumentsModuleOutletInterface extends DocumentsOutletInterface {
    documentModule: DocumentModuleInterface;
    documentGroups: DocumentGroup[];
    setDocumentGroups: React.Dispatch<React.SetStateAction<DocumentGroup[]>>;
    refetch: any
}
function DocumentsModuleContainer () {
    const outletContext = useOutletContext<DocumentsModuleOutletInterface>();
    const {documentGroups, setDocumentGroups, refetch} = outletContext;
    const {isMobile} = useWindowSize();
	const location = useLocation();

    return (
        <div className={`${classes.module_container} ${classes.flex_column} ${isMobile? classes.is_mobile : ''}`}>
			{outletContext.documentModule && !location.pathname.endsWith("manage-groups") && (
				<>
					<div className={`${classes.top_section} ${classes.flex_column}`}>{outletContext.documentModule.moduleHeaderContent}</div>
					<div className={`${classes.line}`} />
				</>
			)}
            <Outlet context={{...outletContext, documentGroups, setDocumentGroups, refetch}} />
        </div>
    );
}

export default DocumentsModuleContainer;