import React, { CSSProperties, FunctionComponent, MouseEvent, ReactElement, useContext, useEffect, useRef, useState } from "react";

import "react-dates/initialize";
import { Store } from "../../Store";
import Arrow from "../../assets/arrow-right.svg";
import Button from "../../components/Button/Button";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import usePagination from "../../customHooks/usePagination";
import { Document, DocumentGroup, PlantPermissionedReports as PlantPermissionsReports } from "../../utils/interface";
import DocumentItemAccordion from "./DocumentItemAccordion";
import DocumentItemRow from "./DocumentItemRow";
import classes from "./DocumentList.module.css";

import { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import "react-dates/lib/css/_datepicker.css";
import Moment from "react-moment";
import { useMutation, useQuery } from "react-query";
import Dropdown from "../../components/Dropdown/Dropdown";
import Loader from "../../components/Loader/Loader";
import { DownloadMultipleFilesModal } from "../../components/Modals/DownloadMultipleFilesModal";
import Pill from "../../components/Pill/Pill";
import DateRangePicker from "../../components/UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import { RadioButtonGroup } from "../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import ToggleSwitch from "../../components/UI/ToggleSwitch/ToggleSwitch";
import { downloadMultipleFiles } from "../../customHooks/FileHttpServices";
import useFileViewer from "../../customHooks/useFileViewer";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import { FilterDateComparisons } from "../../utils/Sortutils";
import { DocumentModuleInterface } from "../Documents/DocumentsContainer";
import { DocumentsModuleOutletInterface } from "../Documents/DocumentsModuleContainer";
import DeleteDocumentConfirmation from "./DeleteDocumentConfirmation";
import DeleteDocumentModal from "./DeleteDocumentModal";
import MoveDocumentConfirmation from "./MoveDocumentConfirmation";
import MoveDocumentModal from "./MoveDocumentModal";

export interface ColumnDefinition {
  title: string;
  key: keyof Document;
  style: CSSProperties;
  component?: FunctionComponent<{ doc: Document; tooltip?: ReactElement<any, any> }>;
  className?: string;
}

const { magnifyGlassBlack, filter } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

interface ReportsProps {
  documentModule: DocumentModuleInterface;
  documentGroups: DocumentGroup[];
}

const sortOptions = [
  {
    id: 1,
    name: "Modified: Newest to Oldest",
    key: "updated",
    direction: "desc",
  },
  {
    id: 2,
    name: "Modified: Oldest to Newest",
    key: "updated",
    direction: "asc",
  },
  {
    id: 3,
    name: "Year: Newest to Oldest",
    key: "year",
    direction: "desc",
  },
  {
    id: 4,
    name: "Year: Oldest to Newest",
    key: "year",
    direction: "asc",
  },
  {
    id: 5,
    name: "Name: A to Z",
    key: "displayName",
    direction: "asc",
  },
  {
    id: 6,
    name: "Name: Z to A",
    key: "displayName",
    direction: "desc",
  },
  {
    id: 7,
    name: "Clear Sort",
    key: "updated",
    direction: "desc",
  },
];

const DocumentList: FunctionComponent<ReportsProps> = ({ documentModule, documentGroups }) => {
  const stateContext = useContext(Store);
  const [searchText, setSearchText] = useState("");
  const { isDesktop, isMobile, isTablet, isTabletLandscape } = useWindowSize();
  const [checkedRows, setCheckedRows] = useState<Document[]>([]);
  const { setShowError, plantConfig, setPagePermissions, setDocument } = stateContext;
  const [tableData, setTableData] = useState<Document[]>([]);
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || "");
  const setErrorData = useOnError();
  const [filters, setFilters] = useState<Filters>({});
  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();
  const [closeFilters, setCloseFilters] = useState<number>(0);
  const tooltipPortalRef = useRef(null);
  const [enableClickWatcher, setEnableClickWatcher] = useState<boolean>(true); // deleting documents
  const [documentsToDelete, setDocumentsToDelete] = useState<Document[]>();
  const [deletionCount, setDeletionCount] = useState<number>(0);
  const [allVersionsDownload, setAllVersionsDownload] = useState<number>();
  // moving documents
  const [documentsToMove, setDocumentsToMove] = useState<Document[]>();
  const [moveConfirmationMeta, setMoveConfirmationMeta] = useState<{ count: number; from: string; to: string }>();
  // copy document link
  const [copyLinkModal, setCopyLinkModal] = useState<ReactElement>();
  const { handleFileView, isLoading: isLoadingFile } = useFileViewer();
 
  const {
    header: {
      data: { user },
    },
  } = useContext(Store);

  useEffect(() => {
    setSortDirection("desc");
    setSortKey("updated");
  }, []);

  useEffect(() => {
    setFilters({ ...filters, isDeleted: user?.portalAdmin ? ["Show Deleted"] : undefined });
  }, [user]);

  const handleFile = (report: Document) => {
    const filetype = report.fileModel.fileType.contentType;
    const filePreviewTypes = ["image/jpeg", "application/pdf", "image/jpeg", "image/png"];
    const isValid = filePreviewTypes.indexOf(filetype);
    if (isValid === -1) {
      handleFileView(report.fileModel.id, report.globalItemId, { isDownload: true, fileName: report.displayName! });
    } else {
      handleFileView(report.fileModel.id, report.globalItemId);
    }
  };

  let tableColumns: ColumnDefinition[];
  tableColumns = [
    {
      title: "Name",
      key: "displayName",
      component: ({ doc }) => {
        if (doc.displayName) {
          return (
            <div className="inline-block">
              <span onClick={() => handleFile(doc)} className="text-ocean no-underline hover:cursor-pointer">{doc.displayName} {doc.isDeleted && "(Deleted)"}</span>
            </div>
          );
        }
        return <>N/A</>;
      },
      className: classes.document_name,
      style: {
        width: "73%",
      },
    },
    {
      title: "Year",
      key: "year",
      style: {
        width: "5%",
      },
    },
    {
      title: "Last Modified",
      key: "updated",
      component: ({ doc }) => {
        if (doc.updated) return <Moment date={doc.updated} format="MM/DD/YYYY hh:mm A" />;
        return <>N/A</>;
      },
      style: {
        width: "15%",
        whiteSpace: "nowrap",
      },
    },
  ];

  const { mutate: deleteMutation } = useMutation((ids: number[]) => documentModule.documentApi.bulkDelete(ids, "User Deleted", group?.accessType), {
    onSuccess: (data: boolean) => {
      refetchReports();
    },
    onError: (error: AxiosError<string>) => {
      setShowError &&
        setShowError({
          isError: true,
          title: error.message,
          ErrorType: "danger",
        });
    },
  });
  const { mutate: moveMutation } = useMutation((data: { ids: number[]; newItemTypeId: number }) => documentModule.documentApi.bulkMove(data.ids, data.newItemTypeId, group?.accessType), {
    onSuccess: (data: boolean) => {
      refetchReports();
    },
    onError: (error: AxiosError<string>) => {
      setErrorData(error);
    },
  });

  const group = documentGroups.find((dg) => dg.id === groupId);
  const {
    isLoading,
    isFetching,
    refetch: refetchReports,
  } = useQuery(
    ["documents", "getDocuments", plantConfig.data.baseOrgId, group?.id],
    () => {
      return documentModule.documentApi.getAll(group);
    },
    {
      onSuccess: (data: PlantPermissionsReports) => {
        /* setPagePermissions?.({
          canAdd: data.canAdd,
          canAdmin: data.canAdmin,
          canDelete: data.canDelete,
          canEdit: data.canEdit,
          canView: data.canView,
          pageKey: `${documentModule.name}.${group?.name}`,
        }); */
        setTableData(data.items);
        setData(data.items);
      },
      enabled: !!group,
      onError: (error: any) => {
        setShowError?.({
          isError: true,
          title: error.response?.data?.message || error.message,
          ErrorType: "danger",
        });
      },
    }
  );

  const confirmMove = (from: string, to: DocumentGroup) => {
    if (documentsToMove) {
      moveMutation({ ids: documentsToMove.map((d) => d.globalItemId), newItemTypeId: to.id });
      setMoveConfirmationMeta({
        count: documentsToMove.length,
        from,
        to: to.name,
      });
    }
    setCheckedRows([]);
    setDocumentsToMove(undefined);
  };

  const confirmDelete = () => {
    if (documentsToDelete) {
      deleteMutation(documentsToDelete.map((d) => d.globalItemId));
      setDeletionCount(documentsToDelete.length);
    }
    setCheckedRows([]);
    setDocumentsToDelete(undefined);
  };

  const tooltipActions: tooltipActionItem[] = [
    {
      text: "Download",
      // img: download,
      showOnlyOn: ["ItemActions"],
      async onClick(event: MouseEvent, d: Document[]) {
        try {
          const fileIds = d.map((d) => d.globalItemId);
          setAllVersionsDownload(fileIds.length);
          await downloadMultipleFiles(fileIds);
          setAllVersionsDownload(undefined);          
        } catch (error) {
          setAllVersionsDownload(undefined);
          setErrorData(error);
        }
      },
    },
  ];
  if (user?.mpInternal && user?.portalAdmin) {
    tooltipActions.push({
      text: "Edit Details",
      onClick: (event: MouseEvent, data: Document[]) => {
        if (data.length < 0) return;
        setDocument?.(data[0]);
        navigate("edit");
      },
      removeWhenDeleted: false
    });
  }
  if (user?.mpInternal && user?.portalAdmin) {
    tooltipActions.push({
      text: "Delete",
      onClick: (event: MouseEvent, data: Document[]) => {
        setDocumentsToDelete(data);
      },
      removeWhenDeleted: true
    });
  }

  const toggleItemChecked = (row: Document): void => {
    let tmpCheckedRows = [...checkedRows],
      index = tmpCheckedRows.findIndex((item) => item.globalItemId === row.globalItemId);
    if (index > -1) {
      tmpCheckedRows.splice(index, 1);
    } else {
      tmpCheckedRows.push(row);
    }
    setCheckedRows(tmpCheckedRows);
  };

  useEffect(() => {
    setCloseFilters((f) => f + 1);
  }, [filters]);

  useEffect(() => {
    setData([]);
    setTableData([]);
  }, [groupId]);

  const filterReports = (doc: Document): boolean => {
    let matches = true;
    if (filters.dateStartDate) {
      matches = dayjs(doc.created).isAfter(filters.dateStartDate);
    }
    if (matches && filters.dateEndDate) {
      matches = dayjs(doc.created).isBefore(filters.dateEndDate);
    }
    if (matches && user?.portalAdmin && !filters.isDeleted?.includes("Show Deleted")) {
      matches = doc.isDeleted === false;
    }
    return matches;
  };

  const {
    pagedData,
    setData,
    data,
    totalRecords,
    setTotalRecords,
    pageCount,
    filterFn,
    setFilter,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection,
  } = usePagination<Document>(16, ['displayName', 'year']);

  useEffect(() => {
    setData(tableData.filter(filterReports));
    setTotalRecords(tableData.filter(filterReports).length);
  }, [tableData.filter(filterReports).length]);

  useEffect(() => {
    if (searchText.trim().length > 0) {
      setFilter(searchText.trim());
    } else {
      setFilter("");
    }
  }, [searchText]);

  const handleSort = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>, option?: any) => {
    setSortKey(option.key);
    setSortDirection(option.direction);
  };

  let filterCount = 0;

  if (filters.dateFilter) {
    filterCount++;
  }
  if (user?.portalAdmin && filters.isDeleted?.length) {
    filterCount += filters.isDeleted?.length;
  }

  return (
    <div className={`${classes.flex_column} ${isDesktop ? classes.is_desktop : ""} ${isMobile ? classes.is_mobile : ""} ${isTablet ? classes.is_tablet : ""}`} style={{ flexGrow: 1 }}>
      {/* Delete document modals */}
      {documentsToDelete && <DeleteDocumentModal group={group} documents={documentsToDelete} onClose={() => setDocumentsToDelete(undefined)} confirm={confirmDelete} />}
      {deletionCount > 0 && <DeleteDocumentConfirmation documentModule={documentModule} deletionCount={deletionCount} onClose={() => setDeletionCount(0)} />}
      {/* Move document modals */}
      {documentsToMove && (
        <MoveDocumentModal documentGroupName={group?.name || ""} documentGroups={documentGroups} documents={documentsToMove} onClose={() => setDocumentsToMove(undefined)} move={confirmMove} />
      )}
      {moveConfirmationMeta && <MoveDocumentConfirmation moveConfirmationMeta={moveConfirmationMeta} onClose={() => setMoveConfirmationMeta(undefined)} />}
      {allVersionsDownload !== undefined && allVersionsDownload > 0 && <DownloadMultipleFilesModal type="Discussion" fileCount={allVersionsDownload} />}

      <div className={`${classes.report_container} ${classes.flex_column}`}>
        <div className={`${isMobile ? classes.flex_column : classes.flex_row} ${classes.options} gap-5`}>
          <div className={`${classes.flex_row} ${classes.options_left}`}>
            {isDesktop && !isTabletLandscape && (
              <div className="nameplate-dropdown">
                <Dropdown
                  dropdowntype={"Sort by:"}
                  handleDropDownSelect={handleSort}
                  selectValue={sortOptions[0].name}
                  selectedOption={sortSelectedOption}
                  setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                  options={sortOptions}
                />
              </div>
            )}
            <div className={`${classes.flex_row} ${classes.search_keyword_container}`}>
              <img src={magnifyGlassBlack} className="" alt="keyword search" />
              <input className={classes.search_keyword} value={searchText} placeholder="Search documents" onChange={(e) => setSearchText(e.target.value)} />
            </div>
            {!isMobile && (
              <div>
                {totalRecords} {totalRecords === 1 ? "Result" : "Results"}
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <ButtonWithTooltip
              className="whiteBtn"
              text={
                <>
                  {filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                </>
              }
              img={<img src={filter} alt="filter" className="bottom pl-2" />}
              textClassName="filter-text"
              forceClose={closeFilters}
              toggleClickWatcher={enableClickWatcher}
              tooltipContent={<FilterTooltipContent onFiltersApplied={setFilters} filters={filters} user={user!} />}
            />
          </div>
        </div>

        {filterCount > 0 && (
          <div className={classes.filter_pill_container}>
            {filters.dateFilter && (filters.dateFilter !== "datecustom" || filters.dateFilter === "datecustom") && (
              <Pill className={classes.filter_pill} dismissible={true} onDismiss={() => setFilters({ ...filters, dateFilter: undefined, dateStartDate: undefined })} type="default">
                {DatePublishedFilters.find((d) => d.value === filters.dateFilter)?.label}
              </Pill>
            )}

            {user?.portalAdmin &&
              filters.isDeleted?.map((status) => (
                <Pill
                  className={classes.filter_pill}
                  key={status}
                  dismissible={true}
                  onDismiss={() =>
                    setFilters((filters) => {
                      return {
                        ...filters,
                        isDeleted: filters.isDeleted?.filter((d) => d !== status),
                      };
                    })
                  }
                  type="default"
                >
                  {status}
                </Pill>
              ))}
          </div>
        )}
        {(isLoading || isFetching || isLoadingFile) ? (
          <Loader />
        ) : (
          <div className={`${classes.results_container} ${classes.flex_column}`}>
            {isDesktop ? (
              <>
                <table className={classes.report_table}>
                  <thead>
                    <tr>
                      {tableColumns.map((col) => (
                        <th key={col.title} style={col.style}>
                          {col.title}
                        </th>
                      ))}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagedData.filter(filterReports).map((row) => (
                      <DocumentItemRow
                        key={row.globalItemId}
                        unread={false}
                        data={row}
                        columnDefinitions={tableColumns}
                        tooltipActions={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("ItemActions"))}
                        isChecked={checkedRows.map((item) => item.globalItemId).includes(row.globalItemId)}
                        onRowChecked={() => toggleItemChecked(row)}
                      />
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <div className={`${classes.flex_row} ${classes.accordion_heading}`}></div>
                {pagedData.map((row) => (
                  <DocumentItemAccordion
                    unread={false}
                    key={row.globalItemId}
                    data={row}
                    isChecked={checkedRows.map((item) => item.globalItemId).includes(row.globalItemId)}
                    onRowChecked={() => toggleItemChecked(row)}
                    columnDefinitions={tableColumns.filter((column) => column.key !== "displayName")}
                    tooltipPortalRef={tooltipPortalRef}
                    tooltipActions={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("ItemActions"))}
                  />
                ))}
              </>
            )}
            {totalRecords > 0 && (
              <div className={`${classes.pagination} ${isDesktop || isTablet ? classes.flex_row : classes.flex_column}`}>
                <div className={`${classes.flex_row} ${classes.pagination_left}`}>
                  <span className={classes.result_count}>
                    {Math.min(resultsPerPage, pagedData.filter(filterReports).length)} of {totalRecords} Result{totalRecords !== 1 && "s"}
                  </span>
                  {resultsPerPage < totalRecords && (
                    <Button className="whiteBtn show_all" text={"Show All"} onClick={() => setResultsPerPage(totalRecords)} style={{ order: isMobile ? "-1" : "unset" }} />
                  )}
                </div>
                <div className={`${classes.flex_row} ${classes.page_numbers}`}>
                  <img
                    src={Arrow}
                    className="pagination-left-arrow"
                    alt="arrow"
                    style={hasPreviousPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)" } : {}}
                    onClick={() => {
                      if (hasPreviousPage) {
                        previousPage();
                      }
                    }}
                  />
                  {pageButtonNumbers.map((pageNumber) => (
                    <span
                      key={pageNumber}
                      className={`${classes.page_number} ${pageNumber === currentPageNumber ? classes.active : ""}`}
                      onClick={() => {
                        setCurrentPageNumber(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </span>
                  ))}
                  <img
                    src={Arrow}
                    className="pagination-right-arrow"
                    style={hasNextPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)" } : {}}
                    alt="arrow"
                    onClick={() => {
                      if (hasNextPage) {
                        nextPage();
                      }
                    }}
                  />
                </div>
              </div>
            )}
            {totalRecords <= 0 && <div>No documents to display.</div>}
          </div>
        )}
      </div>
      <div ref={tooltipPortalRef} />

      {copyLinkModal}
    </div>
  );
};

export default DocumentList;

const DatePublishedFilters = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "datecustom",
  },
];
interface Filters {
  dateStartDate?: Dayjs;
  dateEndDate?: Dayjs;
  dateFilter?: string;
  isDeleted?: string[];
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
  user: any;
}
const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({ onFiltersApplied, filters, user }) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const { selectedPlant, plantConfig } = useContext(Store);
  const [dateStartDate, setDateStartDate] = useState(dayjs(null));
  const [dateEndDate, setDateEndDate] = useState(dayjs(null));
  const [dateFilter, setDateFilter] = useState<string>();
  const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
  const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || "");
  const { documentModule, documentGroups } = useOutletContext<DocumentsModuleOutletInterface>();
  const [iStatus, setIStatus] = useState<{ value: boolean; name: string }[]>([]);
  const group = documentGroups.find((dg) => dg.id === groupId);
  const [isDeleted, setIsDeleted] = useState<{ value?: boolean; name: string }[]>([
    {
      value: filters?.isDeleted?.includes("Show Deleted"),
      name: "Show Deleted",
    },
  ]);

  useQuery(
    [plantConfig.data.baseOrgId, "documents", group?.id, "getDocuments"],
    () => {
      return documentModule.documentApi.getAll(group);
    },
    {
      onSuccess: (data: PlantPermissionsReports) => {
        if (documentModule.id !== 2) return;

        var uniqueArray = data.items.map((r) => {
          return { value: false, name: r.implementationStatus };
        });

        if (!uniqueArray) return;
        uniqueArray = uniqueArray.filter((v, i, a) => a.findIndex((d) => d.name === v.name) === i);
      },
      enabled: !!group,
    }
  );

  useEffect(() => {
    if (filters.dateFilter) {
      setDateFilter(filters.dateFilter);
      if (filters.dateFilter === "datecustom") {
        if (filters.dateStartDate) {
          setDateStartDate(dayjs(filters.dateStartDate).startOf("day"));
        }
        if (filters.dateEndDate) {
          setDateEndDate(dayjs(filters.dateEndDate).startOf("day"));
        }
      }
    }

    setIsDeleted(
      isDeleted.map((s) => {
        var fil = filters.isDeleted?.filter((p) => p === s.name);
        if (fil?.length && fil?.length > 0) s.value = true;
        return s;
      })
    );
  }, [filters]);

  const applyFilters = () => {
    setFiltersChanged(false);

    const filters: Filters = {};

    if (dateFilter) {
      if (dateFilter === "datecustom" && dateStartDate && dateEndDate) {
        filters.dateStartDate = dayjs(dateStartDate.startOf("day"));
        filters.dateEndDate = dayjs(dateEndDate.endOf("day"));
      } else {
        filters.dateStartDate = FilterDateComparisons(dateFilter);
      }
      filters.dateFilter = dateFilter;
    }
    if (user.portalAdmin) {
      if (isDeleted?.find((s) => s.value === true)) {
        filters.isDeleted = isDeleted.filter((s) => s.value).map((s) => s.name);
      } else {
        filters.isDeleted = undefined;
      }
    }

    onFiltersApplied(filters);
  };

  const canApplyFilters = (dateFilter && !(dateFilter === "datecustom" && (!dateStartDate || !dateEndDate))) || filtersChanged || isDeleted.find((s) => s.value !== undefined);

  const selectOptionD = (selectOption: string) => {
    const newState = isDeleted.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsDeleted(newState);
  };

  const selectOptionE = (selectOption: string) => {
    const newState = iStatus.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIStatus(newState);
  };

  return (
    <div className={`${classes.flex_column} ${classes.filter_tooltip_content}`}>
      {user?.portalAdmin && (
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Show Deleted:</label>
          <div className={classes.checkboxWrapper}>
            {isDeleted.map((status, index) => (
              <div className={classes.checkboxRow} key={index} onClick={() => selectOptionE(status.name)}>
                <ToggleSwitch isChecked={status.value || false} label={status.name} onChange={() => selectOptionD(status.name)} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Date Published:</label>
        <RadioButtonGroup onChange={setDateFilter} name="date_published" options={DatePublishedFilters} value={dateFilter} />
      </div>
      {dateFilter === "datecustom" && (
        <div className={classes.date_range_filter}>
          <DateRangePicker
            startDate={dateStartDate}
            endDate={dateEndDate}
            showEndCalendar={showEndCalendar}
            showStartCalendar={showStartCalendar}
            startName="startDate"
            endName="endDate"
            startDateChange={setDateStartDate}
            endDateChange={setDateEndDate}
            setToggleEndCalendarDisplay={setShowEndCalendar}
            setToggleStartCalendarDisplay={setShowStartCalendar}
          />
        </div>
      )}
      <div className={`${classes.filter_tooltip_field}`}>
        <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
      </div>
    </div>
  );
};
