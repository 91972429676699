import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import Dropdown from "../../components/Dropdown/Dropdown";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import useWindowSize from "../../customHooks/useWindowSize";
import { HeaderData } from "../../utils/interface";
import classes from "./Navbar.module.css";

function AccountSettingsModal({ headerData, closeModal }: { headerData: HeaderData; closeModal: () => void }) {
	const {
		accountSettings: { data: accountSettings },
	} = useContext(Store);

	const [optedOut, setOptedOut] = useState<boolean>(accountSettings.optedOut);

	const { isDesktop } = useWindowSize();

	return (
		<ModalGlobal modalSize="md" modaltitle={`Account & Settings`} isModalHeader={true} modalClose={closeModal}>
			<div className={`${classes.accountModalBody}`}>
				<div>
					<strong>Account</strong>
				</div>
				<div className={`${classes.accountModalCard}`}>
					<div className="nav-item login-details" style={{ justifyContent: "flex-start" }}>
						<div className="initials">
							<img src={headerData.user.pictureUrl} alt="" />
						</div>
						<div>
							<div className="name">
								{accountSettings.fName} {accountSettings.lName}
							</div>
						</div>
					</div>
					<div style={{ marginTop: 16 }}>{accountSettings.email}</div>
					<div style={{ marginTop: 16 }}>
            If you have any questions or difficulties updating your information, <a href={`mailto:ugportaladmin@amer.mhps.com`} className="no-underline text-ocean">contact</a> the Users' Group Administrator for assistance.
					</div>
				</div>
				<div style={{ marginTop: 40 }}>
					<div className={`${isDesktop ? classes.flex_row : ""}`} style={{ margin: "30px 0px 0px 0px" }}>
						<Button className={`darkBlue`} disabled={false} text={"Dismiss"} onClick={closeModal} />
					</div>
				</div>
			</div>
		</ModalGlobal>
	);
}
export default AccountSettingsModal;
