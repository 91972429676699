import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { GroupContactData } from "../../../../../utils/interface";
import classes from "./ContactToolTipData.module.css";

interface ToolTipDataProps {
  data: GroupContactData;
}

const ContactToolTipData = ({ data }: ToolTipDataProps) => {
  return (
    <div className={classes.wrp}>
      <div className={`${classes.row} ${classes.mb26}`}>
        <h2 className={classes.title}>
          {data.firstName} {data.lastName}
        </h2>
      </div>

      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.label}>E-mail Address</div>
        <div className={classes.details}>{data.email} </div>
      </div>
      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.label}>Job Title</div>
        <div className={classes.details}> {data.jobTitle} </div>
      </div>
      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.label}>Primary Phone ({data.primaryPhoneType})</div>
        <div className={classes.details}>{data.primaryPhone}</div>
      </div>
      {data.secondaryPhone && (
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Secondary Phone ({data.secondaryPhoneType})</div>
          <div className={classes.details}>{data.secondaryPhone}</div>
        </div>
      )}

      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.label}>Address</div>
        <div className={classes.details}>
          <div>{data.streetAddress}</div>
          <div>{data.city}</div>
          <div>{data.postalCode}</div>
          <div>{data.country}</div>
        </div>
      </div>
    </div>
  );
};

export default ContactToolTipData;
