import React, { ReactElement, useEffect, useState } from "react";
import classes from "./Documents.module.css";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { DocumentsModuleOutletInterface } from "./DocumentsModuleContainer";
import Link from "../../components/Link/Link";
import useWindowSize from "../../customHooks/useWindowSize";
import DocumentList from "../DocumentList/DocumentList";
import Dropdown from "../../components/Dropdown/Dropdown";

function DocumentsGroupContainer() {
  const outletContext = useOutletContext<DocumentsModuleOutletInterface>();
  const { documentModule, documentGroups, refetch } = outletContext;
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || '');
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const [selectedReportType, setSelectedReportType] = useState<string | null>(null);

  const handleGroupType = (e: React.MouseEvent<HTMLDivElement>) => {
    const groupId = e.currentTarget.getAttribute("data-id");
    if(!groupId) return;
    const filteredGroupId = documentGroups.filter((e) => e.id === parseInt(groupId));
    navigate(`../${filteredGroupId[0].id}`);
  };

  useEffect(() => {
    setSelectedReportType(documentGroups.find(d => d.id === groupId)?.name || null);
  })

  useEffect(() => {
    if ((documentGroups.length > 0 && documentGroups.findIndex((g) => g.id === groupId) === -1)) {
      navigate(`../${documentGroups[0].id}`);
    }
  }, [groupId, documentGroups]);

  useEffect(() => {
    refetch();
  }, [])

  let documentGroupSelection: ReactElement = <></>;
  if (documentGroups) {
    if (isMobile || isTablet) {
      documentGroupSelection = (
        <div className={classes.flex_column}>
          Report Type
          <div className="borderDropdown">
            <Dropdown         
              style={{width:'100%'}}  
              handleDropDownSelect={handleGroupType}
              selectedOption={selectedReportType}
              setSelectedOption={setSelectedReportType}
              options={documentGroups}
            />
          </div>          
        </div>
      );
    } else {
      documentGroupSelection = (
        <div className={`${classes.flex_column} ${classes.module_list}`}>
          {documentGroups.map((documentGroupItem) => (
            <Link
              className={`${classes.document_group_link} ${groupId === documentGroupItem.id ? classes.active : ""}`}
              key={documentGroupItem.id}
              to={`../${documentGroupItem.id}`}
            >
              {documentGroupItem.name}
            </Link>
          ))}
        </div>
      );
    }
  }

  return (
	<div className={`${isMobile || isTablet ? classes.flex_column : classes.flex_row} ${classes.module_information}`}>
		{documentGroupSelection}
		<DocumentList documentModule={documentModule} documentGroups={documentGroups} />
		<Outlet context={outletContext} />
	</div>
  );
}

export default DocumentsGroupContainer;
