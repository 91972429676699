import { ReactNode, useContext } from "react";
import { Store } from "../../../Store";
import Button from "../../../components/Button/Button";
import classes from "./ResourcesCard.module.css";

const ResourcesCard = ({ children,onClick,buttonName,title }: ResourcesCardInterface) => {
    const { header:{data:{user}} } = useContext(Store);
    return (
        <div className={classes.flex}>
            <div>
                <div className={`${classes.row} ${classes.mb26}`}>
                    <h2>{title}</h2>
                </div>
                <div className={`${classes.contentWrp}`}>
                    {children}
                </div>
            </div>
            <div className={`${classes.row} ${classes.mb8}`}>
                <span className="grow">
                    {
                        user.portalAdmin && user.mpInternal && <Button className="primaryBtn whiteBtn" onClick={onClick} text={buttonName}/>
                    }
                    
                </span>
            </div>
        </div>
    )

}
interface ResourcesCardInterface {
    children: ReactNode;
    onClick(): void;
    title:string;
    buttonName:string;
}
export default ResourcesCard;