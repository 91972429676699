import React, { useEffect, useState } from "react";
import type { Dayjs } from "dayjs";
import { DatePickerCalendar } from "./DatePickerCalendar/DatePickerCalendar";
import "./DatePicker.css";
import { DatePickerSelector } from "./DatePickerMonthSelector/DatePickerSelector";
import dayjs from "dayjs";

export interface IDatePickerProps {
    // date selected in calendar/input
    selectedDate: Dayjs;
    openPicker: boolean;
    input?: JSX.Element;
    onChange: (newDate: Dayjs) => void;
}

export const CustomSingleDatePicker: React.FC<IDatePickerProps> = ({
    selectedDate,
    input,
    openPicker,
    onChange
}) => {
    // date shown in month selector
    const [shownDate, setShownDate] = useState(dayjs(selectedDate).clone());

    // needs to only run against selectedDate
    useEffect(() => {

        if (selectedDate.toString() !== 'Invalid Date' && !dayjs(shownDate).isSame(selectedDate, 'day')) setShownDate(selectedDate)
        else if (selectedDate.toString() === 'Invalid Date') setShownDate(dayjs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])


    return (
        <>
            {input}
            {openPicker && <div className={"DatePicker"}>
                <DatePickerSelector
                    shownDate={shownDate}
                    setShownDate={setShownDate}
                    />
                <DatePickerCalendar
                    selectedDate={selectedDate}
                    shownDate={shownDate}
                    onChange={onChange}
                />
            </div>}
        </>
    );
};
