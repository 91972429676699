import { CSSProperties, MouseEventHandler, MutableRefObject, useContext, useEffect, useId, useState } from "react";
import Accordion from "../../components/Accordian/Accordion";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import useWindowSize from "../../customHooks/useWindowSize";
import { Document } from "../../utils/interface";
import { ColumnDefinition } from "./DocumentList";
import classes from './DocumentList.module.css';
import ImageImports from "../../utils/ImageImports";
import ReactDOM from 'react-dom';
import { Store } from "../../Store";
import ReportsToolTipData from "./ReportsToolTipData/ReportsToolTipData";

const { ellispis } = ImageImports;


interface ReportRowAccordionProps {
    data: Document;
    columnDefinitions: ColumnDefinition[];
    tooltipPortalRef: MutableRefObject<null>;
    tooltipActions: tooltipActionItem[];
    isChecked: boolean;
    onRowChecked: MouseEventHandler;
    unread: boolean;
}

function DocumentItemAccordion({data, columnDefinitions, tooltipPortalRef, tooltipActions, isChecked, onRowChecked, unread}: ReportRowAccordionProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {isMobile} = useWindowSize();
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
    
    const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
        const willBeVisible = !isTooltipVisible;
        const rect = e.currentTarget.getBoundingClientRect();
        setIsTooltipVisible(willBeVisible);
      };
      
      const { toggleTooltip, setToolTipContent } = useContext(Store);
      const tooltipId = useId().replaceAll(":", "_");

    const Tooltip = () => {
        return (
          <div
            className="row-item text-l cursor-pointer"
            style={{color: "#00749E"}}
            onBlur={() => {
              if (toggleTooltip !== undefined) {
                toggleTooltip(false);
              }
            }}
            tabIndex={1}
            onClick={() => {
              if (toggleTooltip !== undefined) {
                toggleTooltip(true);
                setToolTipContent && setToolTipContent(<ReportsToolTipData data={data} />);
              }
            }}
          >
            <strong>View</strong>
          </div>
        );
      };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setIsTooltipVisible(false);
    };

    useEffect(() => {
        const closeTooltip = () => setIsTooltipVisible(false);
        window.addEventListener('resize', closeTooltip);
        return () => window.removeEventListener('resize', closeTooltip);
    }, []);

    return (
        <Accordion isDeleted={data.isDeleted} titleContent={<ReportRowAccordionTitle unread={unread} isChecked={isChecked} reportName={data.displayName} onRowChecked={onRowChecked} />}
                   isOpen={isOpen}
                   headerStyle={{}}
                   contentStyle={{}}
                   toggleOpen={toggleOpen}>
            <div className={`${classes.result_container} ${classes.flex_column} ${unread? classes.unread : ''}`}>
                <table className={`${classes.report_table} ${classes.report_table_accordion}`}>
                    <tbody>
                        {columnDefinitions.map(columnDefinition => (
                            <tr key={columnDefinition.key} className={classes.data_point}>
                                <th>{columnDefinition.title}</th>
                                <td>
                                    {columnDefinition.component? (<columnDefinition.component doc={data} tooltip={<Tooltip />} />) : (<div><>{data[columnDefinition.key]}</></div>)}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td>
                                <div id={tooltipId} className={`${classes.flex_row} ${classes.more_actions}`} onClick={showTooltip}>
                                    <img src={ellispis} alt="More Actions" />{' '}
                                    More Actions
                                    {tooltipPortalRef.current && (
                                        ReactDOM.createPortal((
                                            <BulkActionTooltip shown={isTooltipVisible} setShown={setIsTooltipVisible} parentId={tooltipId} listItems={tooltipActions} data={[data]} />
                                        ), tooltipPortalRef.current)
                                    )}
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Accordion>
    );
}

export default DocumentItemAccordion;

interface AccordionTitleProps {
    isChecked: boolean;
    reportName: string;
    onRowChecked: MouseEventHandler;
    unread: boolean;
}

function ReportRowAccordionTitle({isChecked, reportName, onRowChecked, unread}: AccordionTitleProps) {
    const checkRow: MouseEventHandler = (e) => {
        e.stopPropagation();
        onRowChecked(e);
    };
    return (
        <div className={`${classes.flex_row} ${classes.accordion_title} ${unread? classes.unread : ''}`}>
            {/* <div className={classes.checkbox} style={{flexBasis: '18px'}}>
                <input type="checkbox" checked={isChecked} onChange={() => {}} />
                <span className={classes.checkmark} onClick={checkRow} />
            </div> */}
            {reportName} 
        </div>
    );
}