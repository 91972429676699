import { useCallback, useMemo, useState } from "react";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";
import { ItemListProps } from "./ItemList";
import ImageImports from "../../utils/ImageImports";

const { downArrow } = ImageImports;

type TableListProps<T> = Pick<ItemListProps<T>, "bulkActions" | "itemActions" | "itemActionText" | "readItemKey" | "columns" | "data"> & {
  selectedItems: T[];
  onItemSelectionChange: (item: T) => void;
  onSelectAllItems: () => void;
};

export const TableList = <T extends { id: number }>({
  bulkActions,
  itemActions,
  itemActionText,
  columns,
  data,
  readItemKey,
  selectedItems,
  onItemSelectionChange,
  onSelectAllItems,
}: TableListProps<T>) => {
  const [forceClose, setForceClose] = useState<number>(0);
  const ItemActionTooltip = useMemo(() => {
    if (itemActionText) {
      return withTooltip(itemActionText);
    }
  }, [itemActionText, itemActions]);

  const BulkActionTooltip = useMemo(() => {
    if (bulkActions) {
      return withTooltip(() => (
        <button className="border-none bg-transparent cursor-pointer">
          <img src={downArrow} alt="Bulk Actions" className="h-6 w-6 mb-[-8px]" />
        </button>
      ));
    }
  }, [bulkActions]);

  const isItemRead = useCallback(
    (item: T) => {
      return !readItemKey || !item[readItemKey];
    },
    [readItemKey]
  );



  return (
    <table className="w-full border-collapse">
      <thead>
        <tr>
          {bulkActions?.length && (
            <th className="bg-[#F2F2F2] p-3 text-left">
              <div className="flex flex-row !pb-0 items-center shrink">
                <Checkbox isChecked={data.length === selectedItems.length && data.length > 0} onChange={onSelectAllItems} />
                {BulkActionTooltip && bulkActions && (
                  <BulkActionTooltip
                    forceClose={forceClose}
                    tooltipContent={
                      <div className="p-6 rounded-lg">
                        <div className="flex flex-col items-start gap-6">
                          {bulkActions.map((action, i) => (
                            <span
                              key={`action-${i}`}
                              onClick={(e) => {
                                action.onClick(e, selectedItems);
                                setForceClose((fc) => ++fc);
                              }}
                            >
                              {action.text}
                            </span>
                          ))}
                        </div>
                      </div>
                    }
                  />
                )}
              </div>
            </th>
          )}
          {columns.map((column) => (
            <th key={column.key as string} className="bg-[#F2F2F2] font-bold p-3 text-left">
              {column.title}
            </th>
          ))}
          {itemActions?.length && <th className="bg-[#F2F2F2] font-bold p-3 text-left"></th>}
        </tr>
      </thead>
      <tbody>
                {data.map((item, i) => (
                    <tr key={item.id}>
                        {bulkActions?.length && (
                            <td className={`${isItemRead(item)? '' : 'bg-[#00749e]/5'} p-3`}>
                                <Checkbox isChecked={selectedItems.map(d => d.id).includes(item.id)} onChange={() => onItemSelectionChange(item)} />
                            </td>
                        )}
                        {columns.map((column, ci) => (
                            <td key={column.key as string} className={`p-3 text-left text-sm ${i > 0? 'border-0 border-t border-solid border-[#F2F2F2]' : ''} ${isItemRead(item)? '' : 'bg-[#00749e]/5'}  ${!isItemRead(item) && ci === 0? 'font-bold' : ''}`}>
                                {column.component ? <column.component data={item} /> : <div className={column.className ?? ''}><>{item[column.key]}</></div>}
                            </td>
                        ))}
                        {ItemActionTooltip && itemActions && (
                            <td className={`p-3 text-left text-sm ${i > 0? 'border-0 border-t border-solid border-[#F2F2F2]' : ''} ${isItemRead(item)? '' : 'bg-[#00749e]/5'}`}>
                                <ItemActionTooltip forceClose={forceClose} tooltipContent={<div className="p-6 rounded-lg">
                                    <div className="flex flex-col items-start gap-6">
                                        {itemActions.map((action, i) => (
                                            <span key={`action-${i}`} onClick={(e) => {
                                                action.onClick(e, item);
                                                setForceClose(fc => ++fc);
                                            }}>{action.text}</span>
                                        ))}
                                    </div>
                                </div>} />
                            </td>
                        )}
                        {itemActions?.length && <td></td>}
                    </tr>
                ))}
            </tbody>
    </table>
  );
};
