import React, { useState, ReactElement, useEffect } from "react";
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from "../../../components/Modals/EmptyModal";
import Button from "../../../components/Button/Button";
import useWindowSize from "../../../customHooks/useWindowSize";
import { useOutletContext } from "react-router-dom";
import { DocumentsModuleOutletInterface } from "../../Documents/DocumentsModuleContainer";
import MiniTooltip from "../../../components/UI/MiniTooltip/MiniTooltip";
import ImageImports from "../../../utils/ImageImports";
import Dropdown from "../../../components/Dropdown/Dropdown";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import { Store } from "../../../Store";
import { DocumentGroup } from "../../../utils/interface";

interface AddNewDocumentGroupModalProps {
	shown: boolean;
	onCreate: (name: string, type: number) => void;
	onClose: () => void;
	accessType: any;
	setAccessType: React.Dispatch<React.SetStateAction<any>>;
	accessTypes: any[];
	groups: DocumentGroup[];
}

function AddNewDocumentGroupModal(props: AddNewDocumentGroupModalProps) {
	const [documentGroupName, setDocumentGroupName] = useState<string>("");
	const [errorMsg, setErrorMsg] = useState<string>("");
	const { isMobile } = useWindowSize();
	const { tooltip } = ImageImports;
	const { documentModule } = useOutletContext<DocumentsModuleOutletInterface>();

	useEffect(() => {
		setErrorMsg("");
	}, [documentGroupName]);

	let width: number | undefined;
	if (isMobile) {
		width = 300;
	}

	let modalBody: ReactElement | undefined;
	let modalFooter: ReactElement | undefined;
	if (props.shown) {
		modalBody = (
			<>
				{errorMsg && <ErrorCard ErrorMessage={errorMsg} ErrorType={"danger"} closeBtn={false} />}
				<span className={``}>
					Would you like this new document group to be accessible to all Insurance Portal users, or limited to a select group of users? Only one document group can be considered limited
					access at a time.
				</span>
				<div className={`${classes.modal_body_input_group} ${classes.flex_row}`}>
					<span className={classes.input_label}>Document Group Access* &nbsp;&nbsp;&nbsp;</span>
					<div className={`${classes.modal_body_input_container} ${classes.flex_column}`}>
						<Dropdown
							style={{ width: "100%", borderBottom: "1px solid lightgray", fontSize: 16 }}
							placeholder="ex. General Access"
							dropdownStyle={{ backgroundColor: "white" }}
							handleDropDownSelect={(e, option) => props.setAccessType(option)}
							selectedOption={props.accessType?.name || null}
							setSelectedOption={() => {}}
							options={props.accessTypes}
						/>
					</div>
				</div>
				{props.accessType && (
					<div className={`${classes.modal_body_input_group} ${classes.flex_row}`}>
						<span className={classes.input_label}>
							Document Group Name*
							<span className={classes.cursor_pointer}>
								<MiniTooltip text={"Please enter the document group name here."} space={15} placement={"top"}>
									<img src={tooltip} style={{ maxWidth: "16px", marginLeft: "6px" }} className={classes.tooltip_icon} alt="tool tip" />
								</MiniTooltip>
							</span>
						</span>
						<div className={`${classes.modal_body_input_container} ${classes.flex_column}`}>
							<input
								type="text"
								required
								className={classes.modal_body_input}
								placeholder="ex. Yearly Updates"
								value={documentGroupName}
								onChange={(e) => setDocumentGroupName(e.target.value)}
							/>
						</div>
					</div>
				)}
			</>
		);

		modalFooter = (
			<>
				<div className={`${classes.modal_button_group} ${classes.flex_row}`}>
					<button className="darkBlue primaryBtn" type="submit" disabled={!(documentGroupName.length > 0)}>
						Add Document Group
					</button>
					<Button className={`whiteBtn`} text="Cancel" onClick={props.onClose} />
				</div>
				<span className={classes.modal_footer_required_fields}>*Required Fields</span>
			</>
		);
	}

	return (
		<form
			style={{ position: "fixed" }}
			onSubmit={(e) => {
				e.preventDefault();
				if (props.groups.find((group) => group.name === documentGroupName)) {
					setErrorMsg("The document group cannot be added because the group name already exists. Please rename the document group and try again.");
				} else {
					props.onCreate(documentGroupName, props.accessType.id);
					props.onClose();
					setDocumentGroupName("");
				}
			}}
		>
			<EmptyModal shown={props.shown} body={modalBody} footer={modalFooter} heading="Add New Document Group" width={width} onClose={props.onClose} footerClass={classes.modal_footer} />
		</form>
	);
}

export default AddNewDocumentGroupModal;
