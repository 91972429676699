import React, { Dispatch, useContext, useEffect, useState } from "react";

import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Store } from "../../../Store";

import { CloseAllModal } from "../../../utils/CloseAllModal";
import { AnnouncementData, CPApiResponse, deleteInterface, FormDataAnnouncement } from "../../../utils/interface";

import { useIsFetching } from "react-query";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import SimpleDelete from "../../../components/FormsPages/FormDelete/SimpleDelete";
import Loader from "../../../components/Loader/Loader";
import TextAreaField from "../../../components/UI/Form/TextAreaField/TextAreaField";
import TextInputField from "../../../components/UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../../../components/UI/Modal/ModalGlobal";
import { useOnError } from "../../../customHooks/useOnError";
import useWindowSize from "../../../customHooks/useWindowSize";
import { queryKeys } from "../../../react-query/queryKeys";
import { queryClient } from "../../../react-query/useQueryClientGet";
import classes from "../Announcements/Announcement/Announcement.module.css";
import {
  useCreate_Announcements,
  useDelete_Announcements,
  useGet_Announcements,
  useUpdate_Announcements,
} from "../HttpServices/AnnouncementHttpServices";

const AddEditAnnouncement = ({ isEdit, modaltitle, modaltitlename, id, setAnnouncementData, announcementData }: AddEditAnnouncementProps) => {
  const { isModal, setIsModal, showError } = useContext(Store);
  const { isMobile } = useWindowSize();
  const setErrorData = useOnError();

  const [announcementID, setAnnouncementId] = useState<number>(0);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const isFetching = useIsFetching([queryKeys.AnnouncementApi]);

  const { mutate: CreateAnnouncement, isLoading: isCreating } = useCreate_Announcements();
  const { mutate: UpdateeAnnouncement, isLoading: isUpdating } = useUpdate_Announcements();
  const { mutate: DeleteAnnouncement, isLoading: isDeleting } = useDelete_Announcements();

  const handleDeleteAnnouncement = () => {
    DeleteAnnouncement(announcementData?.id, {
      onSuccess: (responseData: deleteInterface) => {
        queryClient.invalidateQueries(queryKeys.AnnouncementApi);
        setIsModal!({
          ...CloseAllModal,
          isDeleteSuccess: {
            ...isModal.isDeleteSuccess,
            isAnnouncement: true,
          },
        });
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };

  useEffect(() => {
    if (id && id !== announcementID) {
      setAnnouncementId(id);
    }
  }, [id, announcementID]);

  // --------------------------------------------------------//

  useGet_Announcements(announcementID, {
    enabled: !!announcementID && isEdit && !isDelete,
    onSuccess: (AnnouncementData: CPApiResponse<AnnouncementData>) => {
      // console.log("AnnouncementData", AnnouncementData);
      setAnnouncementData(AnnouncementData.data);
   
    },
    onError: (error: unknown) => {
      setErrorData(error);
    },
  });

    // --------------------------------------------------------//

  const handleOnSubmit = (values: FormDataAnnouncement, formikHelpers: FormikHelpers<FormDataAnnouncement>) => {
    if (!isEdit) {
      CreateAnnouncement(values, {
        onSuccess: (responseData: CPApiResponse<AnnouncementData>) => {
          setAnnouncementData(responseData.data);
          queryClient.invalidateQueries(queryKeys.AnnouncementApi);

          formikHelpers.setSubmitting(false);
          setIsModal!({
            ...CloseAllModal,
            isAddedSuccess: { ...isModal.isAddedSuccess, isAnnouncement: true },
          });
        },
        onError: (error: unknown) => {
          formikHelpers.setSubmitting(false);
          setErrorData(error);
        },
      });
    } else {
      UpdateeAnnouncement(values, {
        onSuccess: (responseData: CPApiResponse<AnnouncementData>) => {
          setAnnouncementData(responseData.data);
          queryClient.invalidateQueries(queryKeys.AnnouncementApi);
          formikHelpers.setSubmitting(false);
          setIsModal!({
            ...CloseAllModal,
            isEditedSuccess: { ...isModal.isEditedSuccess, isAnnouncement: true },
          });
        },
        onError: (error: unknown) => {
          formikHelpers.setSubmitting(false);
          setErrorData(error);
        },
      });
    }
  };

  let initialValues: FormDataAnnouncement;

  if (announcementData) {
    initialValues = {
      id: announcementData?.id,
      title: announcementData?.title,
      messageContent: announcementData?.messageContent,
      displayOrder: announcementData?.displayOrder,
    };
  } else {
    initialValues = {
      title: "",
      messageContent: "",
      displayOrder: 1,
    };
  }

  if (!isEdit) {
    initialValues = {
      title: "",
      messageContent: "",
      displayOrder: initialValues.displayOrder || 1,
    };
  }

  return (
    <ModalGlobal modaltitle={isDelete ? "Delete Announcement" : modaltitle} modaltitlename={modaltitlename} isModalHeader={true}>
      {!isDelete ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={Yup.object({
            title: Yup.string().required("This field is required"),
            messageContent: Yup.string().required("This field is required"),
            displayOrder: Yup.number().positive().min(1).required("This field is required"),
          })}
        >
          {(formikProps) => (
            <>
              {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
              <Form>
                {isFetching > 0 || isUpdating || isCreating ? (
                  <Loader />
                ) : (
                  <>
                    <div>
                      <TextInputField
                        name="title"
                        labelName="Announcement Title*"
                        placeholder="ex. Insurance Community Conference"
                        className=""
                        tooltip={{
                          text: "Please enter the title of your announcement here.",
                        }}
                      />
                    </div>
                    <div className={classes.Announcement_TextArea}>
                      <TextAreaField
                        tooltip={{
                          text: "Please describe your announcement here.",
                        }}
                        name={"messageContent"}
                        height={!isMobile ? 69 : 100}
                        className={""}
                        placeholder="A message about the conference."
                        labelName={"Announcement Message*"}
                        display={"float-tooltip"}
                      />
                    </div>
                    <div>
                      <TextInputField
                        name="displayOrder"
                        type="number"
                        labelName="Display Order*"
                        className=""
                        placeholder="ex. 1"
                        tooltip={{
                          text: "Please enter the numeric placement for the Announcement to be displayed.",
                        }}
						            min={1}
                        onnKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} 
                      />
                    </div>
                  </>
                )}

                <div className="flex flex-col md:flex-row items-center">
                  <div className="flex-grow-1 flex flex-col md:flex-row gap-x-4">
                    {isEdit ? (
                      <>
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                          Save Changes
                        </button>
                        <button type="button" onClick={() => setIsDelete(true)} className="primaryBtn redDelete">
                          Delete
                        </button>
                      </>
                    ) : (
                      <>
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                          Post Announcement
                        </button>
                      </>
                    )}
                  </div>
                  <span className="justify-center md:justify-end text-sm font-light">*Required field</span>
                </div>
              </Form>
            </>
          )}
        </Formik>
      ) : (
        <SimpleDelete
          id={announcementData.id}
          subDescription={announcementData.title}
          description={
            "Are you sure you want to delete this announcement? This announcement will no longer be visible to Insurance Portal users. This cannot be undone."
          }
          handleDelete={handleDeleteAnnouncement}
          isDeleting={isDeleting}
        />
      )}
    </ModalGlobal>
  );
};

interface AddEditAnnouncementProps {
  modaltitle: string;
  modaltitlename: string;
  isEdit: boolean;
  id?: number;
  setAnnouncementData: Dispatch<React.SetStateAction<AnnouncementData>>;
  announcementData: AnnouncementData;
}

export default AddEditAnnouncement;
