import React from "react";
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { AuthorizationProvider } from "../components/Auth/AuthorizationProvider";
import { PrivateRoutes } from "../components/Auth/PrivateRoutes";
import { Forbidden } from "../components/ErrorPage/Forbidden";
import FileReader from "../components/FileReader/FileReader";
import { SvgViewer } from "../components/UI/SvgViewer";
import { ManageUserContainer } from "../containers/Administration/ManageUserContainer";
import { UserManagementContainer } from "../containers/Administration/UserManagementContainer";
import ManageDocumentGroups from "../containers/DocumentList/ManageReportGroups/ManageDocumentGroups";
import AddDocumentContainer from "../containers/Documents/AddDocumentContainer";
import DocumentsContainer from "../containers/Documents/DocumentsContainer";
import DocumentsGroupContainer from "../containers/Documents/DocumentsGroupContainer";
import DocumentsModuleContainer from "../containers/Documents/DocumentsModuleContainer";
import DocumentsModuleIndex from "../containers/Documents/DocumentsModuleIndex";
import Home from "../containers/Home_IP/Home";
import PortalAccessRequest from "../containers/PortalAccess/PortalAccessRequest";
import { PortalAccessRequestSubmitted } from "../containers/PortalAccess/PortalAccessRequestSubmitted";
import SplashPage from "../containers/Welcome/SplashPage/SplashPage";
import Search from "../insurance-containers/Search/Search";
import SearchContainer from "../insurance-containers/Search/SearchContainer";
import { msalFeatureFlag } from "../utils/Auth/msalConfig";

export const Router = ({ providers }: { providers: React.ReactElement }) => {
  const routes = [
    <Route element={<AuthorizationProvider />}>
      <Route element={msalFeatureFlag? <PrivateRoutes /> : <Outlet />}>
        <Route path="/" element={providers} >
            
            <Route index element={<Home />} />
            <Route path="/administration/user-management" element={<UserManagementContainer section="default" />} />
            <Route path="/administration/user-management/:userId" element={<ManageUserContainer />} />
            <Route path="/administration/user-management/internal" element={<UserManagementContainer section="internal" />} />
            <Route path="/administration/user-management/external" element={<UserManagementContainer section="external" />} />
            <Route path={"/shared-document/:fileId"}>
              <Route index element={<FileReader />} />
              <Route path=":fileURI" element={<FileReader />} />
            </Route>
            <Route path="/search" element={<SearchContainer />} > 
              <Route path=":searchText" element={<Search />} />
            </Route>
            <Route path="/documents" element={<DocumentsContainer />}>
              <Route path=":moduleName" element={<DocumentsModuleContainer />}>
                <Route path=":groupId" element={<DocumentsGroupContainer />}>
                <Route path="add" element={<AddDocumentContainer isEdit={false} />} />
                <Route path="edit" element={<AddDocumentContainer isEdit={true} />} />
              </Route>
              <Route index element={<DocumentsModuleIndex />} />
              <Route path="manage-groups" element={<ManageDocumentGroups />} />
            </Route>
          </Route>
          <Route path="/forbidden" element={<Forbidden />} />
        </Route>
      </Route>,
      <Route path="/splash" element={<SplashPage />} />,
      <Route path="/portal-access" element={<PortalAccessRequest />} />,
      <Route path="/portal-access/submitted" element={<PortalAccessRequestSubmitted />} />
    </Route>
  ];
  if (process.env.NODE_ENV === 'development') {
    routes.push(<Route path="/svgs" element={<SvgViewer />} />);
  }
  return createBrowserRouter(createRoutesFromElements(routes));
};
