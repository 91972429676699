import { FunctionComponent, MouseEvent, ReactElement, useCallback, useEffect, useState } from "react";
import usePagination from "../../customHooks/usePagination";
import ImageImports from '../../utils/ImageImports';
import Button from "../Button/Button";
import useWindowSize from "../../customHooks/useWindowSize";
import { DropDownOptionItem, PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import Pill from "../Pill/Pill";
import { Pagination } from "./Pagination";
import { TableList } from "./TableList";
import { AccordionList } from "./AccordionList";

const { magnifyGlassBlack, filter } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

export interface ItemListColumnDefinition<T> {
    title: string;
    key: keyof T;
    component?: FunctionComponent<{data: T}>;
    className?: string;
}

export interface SortablePropertiesDefinition<T> {
    key: keyof T;
    label: string;
    direction: 'asc' | 'desc';
    default?: boolean;
}

export interface BulkItemActionDefinition<T> {
    text: string|ReactElement;
    onClick: (event: MouseEvent, data: T[]) => void;
}

export interface ItemActionDefinition<T> {
    text: string|ReactElement;
    onClick: (event: MouseEvent, data: T) => void;
}

export type PropertiesOfType<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];
export interface ItemListProps<T> {
    columns: ItemListColumnDefinition<T>[];
    data: T[];
    sortValues?: SortablePropertiesDefinition<T>[];
    bulkActions?: BulkItemActionDefinition<T>[];
    itemActions?: ItemActionDefinition<T>[];
    itemActionText?: FunctionComponent;
    deletedItemKey?: PropertiesOfType<T, boolean>;
    readItemKey?: PropertiesOfType<T, boolean>;
    accordionTitle?: (data: T) => string;
    filterContent?: ReactElement;
    filterCount?: number;
    filters?: ReactElement;
    searchBoxPlaceholder?: string
}

export const ItemList = <T extends {id: number}>({
    data,
    columns,
    sortValues,
    bulkActions,
    itemActions,
    itemActionText,
    deletedItemKey,
    readItemKey,
    accordionTitle,
    filterContent,
    filterCount,
    filters,
    searchBoxPlaceholder
}: ItemListProps<T>) => {
    const [sortSelectedOption, setSortSelectedOption] = useState<number|false>(false);
    const [sortOptions, setSortOptions] = useState<DropDownOptionItem[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const { isMobile, isTablet, isDesktop } = useWindowSize();
    const [closeFilters, setCloseFilters] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const {
        pagedData,
        setData,
        totalRecords,
        setFilter: setPaginationFilter,
        pageButtonNumbers,
        hasPreviousPage,
        previousPage,
        hasNextPage,
        nextPage,
        setCurrentPageNumber,
        currentPageNumber,
        resultsPerPage,
        setResultsPerPage,
        setSortKey,
        setSortDirection,
    } = usePagination<T>();

    useEffect(() => {
        setData(data);
        setSelectedItems([]);
    }, [data]);

    useEffect(() => {
        setPaginationFilter(filterText.trim());
    }, [filterText]);

    const handleSort = useCallback((item: SortablePropertiesDefinition<T> & {id: number|false}) => {
        setSortKey(item.key);
        setSortDirection(item.direction);
        setSortSelectedOption(item.id)
    }, []);

    useEffect(() => {
        const options: typeof sortOptions & SortablePropertiesDefinition<T>[] = [];
        if (sortValues) {
            options.push(...sortValues.map((d, i) => ({ ...d, id: i, name: d.label })));
        }
        setSortOptions(options);
        if (options.length > 0) {
            if (!!(sortValues?.filter(opt => opt.default).length)) {
                handleSort(options[sortValues?.findIndex(val => val.default)])
            } else handleSort(options[0]);
        }
    }, [handleSort, sortValues]);

    const toggleSelectedItem = useCallback((selectedItem: T) => {
        const items = [...selectedItems];
        const i = items.findIndex(d => d.id === selectedItem.id);
        if (i >= 0) {
            items.splice(i, 1);
        } else {
            items.push(selectedItem);
        }
        setSelectedItems(items);
    }, [selectedItems]);

    const toggleAllItems = () => {
        if (selectedItems.length === pagedData.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(pagedData);
        }
    };

    return (
        <div className="flex flex-col gap-4 items-start grow">
            <div className="flex flex-row gap-6 !pb-0 self-stretch items-center">
                <div className="flex flex-row items-start gap-4 !pb-0">
                    {isDesktop && <PlainDropDown
                        onSelection={(e: unknown) => handleSort(e as SortablePropertiesDefinition<T> & {id: number|false})}
                        options={sortOptions}
                        value={sortSelectedOption}
                        selectClass="flex flex-row pt-1 pr-2 !pb-1 pl-4 border border-solid border-[#999] rounded select cursor-pointer"
                        optionsClass="p-4 bg-white rounded flex-column select_options" />
                    }
                    <div className="flex flex-row sm:h-12 lg:h-auto items-center pr-4 border border-[#999] border-solid !pb-0 rounded self-stretch">
                        <img src={magnifyGlassBlack} className="px-2" alt="Keyword search" />
                        <input
                            className="outline-none border-none text-sm"
                            value={filterText}
                            placeholder={searchBoxPlaceholder}
                            onChange={(e) => setFilterText(e.target.value)}
                            />
                    </div>
                </div>
                <div className="flex flex-row items-start gap-[10px] grow !pb-0 text-sm">
                    {totalRecords} {totalRecords === 1 ? "Result" : "Results"}
                </div>
                {(filterContent || !isDesktop) && <div className="flex flex-row items-start gap-[10px]">
                    <ButtonWithTooltip
                        className="whiteBtn"
                        text={
                            <>
                                {filterCount && filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                            </>
                        }
                        img={<img src={filter} alt="filter" className="bottom pl-2"/>}
                        textClassName="filter-text"
                        forceClose={closeFilters}
                        tooltipContent={isDesktop && filterContent? filterContent : isDesktop? <></> : (
                            <div className="flex flex-col">
                                <PlainDropDown
                                    onSelection={(e: unknown) => handleSort(e as SortablePropertiesDefinition<T> & {id: number|false})}
                                    options={sortOptions}
                                    value={sortSelectedOption}
                                    selectClass="flex flex-row pt-1 pr-2 !pb-1 pl-4 border border-solid border-[#999] rounded"
                                    optionsClass="p-4 bg-white rounded" />
                                {filterContent}
                            </div>
                        )}
                    />
                </div>}
            </div>
            {filters}
            <div className="flex flex-col gap-6 self-stretch items-stretch">
                {isDesktop? 
                    <TableList data={pagedData} columns={columns} bulkActions={bulkActions} itemActions={itemActions} itemActionText={itemActionText} readItemKey={readItemKey} selectedItems={selectedItems} onItemSelectionChange={toggleSelectedItem} onSelectAllItems={toggleAllItems} />
                    : <AccordionList data={pagedData} accordionTitle={accordionTitle} columns={columns} bulkActions={bulkActions} itemActions={itemActions} selectedItems={selectedItems} onItemSelectionChange={toggleSelectedItem} onSelectAllItems={toggleAllItems} />
                }
            </div>
            {data.length > 0 ? (
                <Pagination
                    currentPage={currentPageNumber}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    nextPage={nextPage}
                    pageNumbers={pageButtonNumbers}
                    previousPage={previousPage}
                    resultCount={pagedData.length}
                    resultsPerPage={resultsPerPage}
                    setCurrentPage={setCurrentPageNumber}
                    setResultsPerPage={setResultsPerPage}
                    totalRecords={totalRecords} />
            ) : ''}
        </div>
    );
};

ItemList.defaultProps = {
    searchBoxPlaceholder: 'Search Users'
};