import { PromptState } from "msal/lib-commonjs/utils/Constants";
import { ReactElement, ReactNode, useContext } from "react";
import { Link } from "react-router-dom";
import ErrorCard from "../components/ErrorCard/ErrorCard";
import Footer from "../components/Footer/Footer";
import RelatedLinks, { RelatedLinksData } from "../components/RelatedLinks/RelatedLinks";
import { Store } from "../Store";

declare type BreadCrumbItem = string | ReactElement;

interface MainContentProps {
    title: string;
    breadCrumbs: BreadCrumbItem[];
    relatedData?: RelatedLinksData;
    children?: ReactNode;
    titleStyle?: React.CSSProperties
}
export const MainContainer = ({title, breadCrumbs, relatedData, children, titleStyle}: MainContentProps) => {
    const {showError} = useContext(Store);
    return (
        <div className="flex flex-col grow" style={titleStyle}>
            <div className="content">
                {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={window.location.reload} ErrorType={showError.ErrorType} />}
            
                <div className="path breadcrumb">
                    <Link to={"/"} className="link_text">
                        Home
                    </Link>{" / "}
                    {breadCrumbs.join(' / ')}
                </div>
                <div className="login-header">
                    {title}
                </div>
                <div className="flex flex-col p-6 gap-6 self-stretch bg-white rounded" style={{boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.05)'}}>
                    {children}
                </div>
            </div>
            <div className="h-6 pt-6"></div>
            {relatedData && <RelatedLinks data={relatedData} />}
            <div className="content flex-grow-0 flex-end">
                <Footer />
            </div>
        </div>
    );
};