
import axios, { AxiosResponse } from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { apiFile, apiMessageAttachment } from "../endpoints/endpoints";
import { queryKeys } from "../react-query/queryKeys";

export const downloadFile = (globalItemId: number): Promise<void> => {
    return downloadFileByUrl(`${apiFile}/DownloadFile/${globalItemId}`)
};

export const downloadMessageAttachment = (messageAttachmentId?: number): void => {
    if (messageAttachmentId) {
        downloadFileByUrl(`${apiMessageAttachment}/Download/${messageAttachmentId}`);
    }
};

export const downloadMultipleFiles = (globalItemIds: number[]): Promise<void[]> => {
    const promises: Promise<void>[] = [];
    for (let i = 0; i < globalItemIds.length; i++) {      
        promises.push(downloadFile(globalItemIds[i]));
    }
    return Promise.all(promises);
};

export const downloadFileByUrl = async (url: string): Promise<void> => {
    const a = document.createElement('a');
    a.style.display = 'none';
    const response: AxiosResponse<Blob> = await axios(url, {
        responseType: 'blob'
    });

    const contentDisposition = response.headers['content-disposition'];
    const idIndex = url.split('/').length - 1;
    const globalIdFromUrl = url.split('/')[idIndex];
    const defaultFileName = `${globalIdFromUrl}.${response?.data?.type.split('/')[1]}`;

    let fileName;
    if (contentDisposition) {
        fileName = contentDisposition.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
        fileName = decodeURI(fileName);
    }
        
    if (response?.data?.size > 0 && response?.data?.type) {
        a.href = URL.createObjectURL(response.data);
        a.download = fileName || defaultFileName;
        document.body.append(a);
        a.click();
    }
};


export const downloadFileByFileId = (fileId: number): Promise<void> => {
    return downloadFileByUrl(`${apiFile}/DownloadFileByFileId/${fileId}`);
};
export const downloadMultipleFilesByFileId = (fileIds: number[]): Promise<void[]> => {
    const promises: Promise<void>[] = [];
    for (let i = 0; i < fileIds.length; i++) {
        promises.push(downloadFileByFileId(fileIds[i]));
    }
    return Promise.all(promises);
};

export interface FileRequestCrudMethods<A> {
  getFileAsAxiosResponseBlob: (id: number) => Promise<A>;
}

export const FileRequestCrudHttpServices: FileRequestCrudMethods<
  AxiosResponse<Blob>
> = {
  getFileAsAxiosResponseBlob: async (id: number): Promise<AxiosResponse<Blob>> => {
    const response = await axios({
      url: `${apiFile}/DisplayFileByFileId/${id}`,
      method: "GET",
      responseType: "blob",
    });
    
    return response;
  }
};

export const useGetFileAxiosResponseBlob = (id: number, options: Omit<UseQueryOptions<AxiosResponse<Blob>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<AxiosResponse<Blob>>([queryKeys.File, id], () => FileRequestCrudHttpServices.getFileAsAxiosResponseBlob(id), {
    ...options,
  });
};