import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import classes from "./Navbar.module.css";

function SignOutModal({ closeModal }: { closeModal: () => void }) {
	const { instance, inProgress } = useMsal();
	return (
		<ModalGlobal modalSize="sm" modaltitle={`Are you sure you want to sign out of Insurance Portal?`} isModalHeader={true} modalClose={closeModal} titleClassName="centeredText">
			<div className={`${classes.accountModalBody}`}>
				<div style={{ marginTop: 40, display: "flex", justifyContent: "center" }} className={classes.flex_row}>
					{inProgress === InteractionStatus.Logout ? <Loader/> : <>
						<Button
							className={`darkBlue`}
							disabled={false}
							text={"Sign Out"}
							onClick={() => instance.logoutRedirect()}
						/>
						&nbsp;&nbsp;&nbsp;
						<Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={closeModal} />
					</>}
				</div>
			</div>
		</ModalGlobal>
	);
}
export default SignOutModal;
