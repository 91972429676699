import classes from "../Updates.module.css";
import ImageImport from "../../../../../utils/ImageImports";
import { apiPortalUpdate } from "../../../../../endpoints/endpoints";
import { Store } from "../../../../../Store";
import { useContext } from "react";
const Update = ({ id, title, handleEditInsuranceUpdate }: { id: number; title: string; handleEditInsuranceUpdate(id: number): void }) => {
	const { pencil, update } = ImageImport;
	const { header: {data: { user },} } = useContext(Store);

	return (
		<div className={classes.Update}>
			<div className={`${classes.header} ${classes.mb20}`}>
				<div>
					<a className={classes.inlineflex} href={`/shared-document/${id}/${encodeURIComponent(`${apiPortalUpdate}/GetFile/${id}`)}`} target="_blank" style={{ color: "#00749E", textDecoration: "none" }} rel="noreferrer">
						<span className={classes.iconwrapper}>
							<img src={update} alt="link" />
						</span>
						<span className={classes.title}>{title}</span>
					</a>
				</div>
				<div className="">
					{
						user.portalAdmin && user.mpInternal && <img src={pencil} onClick={() => handleEditInsuranceUpdate(id)} style={{ width: "15px", cursor: "pointer" }} alt="edit" />
					}
				</div>
			</div>
		</div>
	);
};
export default Update;
