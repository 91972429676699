import { TypeDTO } from "../../utils/interface";
import {
  apiEquipmentAttachmentTypes,
  apiUnitConfigurationTypes,
  apiManufacturerTypes,
  apiPlantFuelTypes,  
  apiProductTypes,
  apigetEquipmentModelNameTypes,
} from "../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";

export interface PlantInfoGetTypesMethods<T> {
  getManuFacturerTypes: () => Promise<T>;
  getProductTypes: () => Promise<T>;
  getPlantFuelTypes: () => Promise<T>;
  getEquipmentAttachmentTypes: () => Promise<T>;
  getUnitConfigurationTypes: () => Promise<T>;
  getEquipmentModelNameTypes: () => Promise<T>;
}

export const TypesHttpServices: PlantInfoGetTypesMethods<AxiosResponse<TypeDTO>> = {
  getManuFacturerTypes: async (): Promise<AxiosResponse<TypeDTO>> => {
    const response = await axios({
      url: `${apiManufacturerTypes}`,
      method: "GET",
    });   

    if(response){
      response.data.data.push({
        id: -1,
        name: "Other OEM",
      })
    }

    return response.data;
  },
  getProductTypes: async (): Promise<AxiosResponse<TypeDTO>> => {
    const response = await axios({
      url: `${apiProductTypes}`,
      method: "GET",
    });
    return response.data;
  }, 
  getPlantFuelTypes: async (): Promise<AxiosResponse<TypeDTO>> => {
    const response = await axios({
      url: `${apiPlantFuelTypes}`,
      method: "GET",
    });
    return response.data;
  },
  getEquipmentAttachmentTypes: async (): Promise<AxiosResponse<TypeDTO>> => {
    const response = await axios({
      url: `${apiEquipmentAttachmentTypes}`,
      method: "GET",
    });
    return response.data;
  },
  getUnitConfigurationTypes: async (): Promise<AxiosResponse<TypeDTO>> => {
    const response = await axios({
      url: `${apiUnitConfigurationTypes}`,
      method: "GET",
    });
    return response.data;
  },
  getEquipmentModelNameTypes: async (): Promise<AxiosResponse<TypeDTO>> => {
    const response = await axios({
      url: `${apigetEquipmentModelNameTypes}`,
      method: "GET",
    });
    return response.data;
  },
};

export const useApiGetManuFacturerTypes = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.manufacturerType, TypesHttpServices.getManuFacturerTypes, {
    staleTime: 100000,
    cacheTime: 1000000,
    onSuccess,
    onError,
  });
};

export const useApiProductType = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.productType, TypesHttpServices.getProductTypes, {
    staleTime: 100000,
    cacheTime: 1000000,
    onSuccess,
    onError,
  });
};

export const useApiPlantFuelType = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.plantFuelTypes, TypesHttpServices.getPlantFuelTypes, {
    staleTime: 100000,
    cacheTime: 1000000,
    onSuccess,
    onError,
  });
};

export const useApiEquipmentAttachmentType = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.equipmentAttachmentType, TypesHttpServices.getEquipmentAttachmentTypes, {
    staleTime: 100000,
    cacheTime: 1000000,
    onSuccess,
    onError,
  });
};

export const useApiUnitConfigurationTypes = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.unitConfigurationTypes, TypesHttpServices.getUnitConfigurationTypes, {
    staleTime: 100000,
    cacheTime: 1000000,
    onSuccess,
    onError,
  });
};

export const useApiEquipmentModelNameTypes = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.equipmentModelNameTypes, TypesHttpServices.getEquipmentModelNameTypes, {
    staleTime: 100000,
    cacheTime: 1000000,
    onSuccess,
    onError,
  });
};
