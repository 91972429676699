import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, generatePath, useLocation } from "react-router-dom";
import { Store } from "../../Store";
import { useIsInternalAdmin } from "../../customHooks/useIsInternalAdmin";
import ImageImports from "../../utils/ImageImports";
import AccountSettingsModal from "../Navbar/AccountSettingsModal";
import SignOutModal from "../Navbar/SignOutModal";
import "./MobileMenu.css";
import MobileMenuHeader from "./MobileMenuHeader/MobileMenuHeader";
import MobileSubmenuLink from "./MobileSubmenuLink/MobileSubmenuLink";

const {
  homeBlue,
  lockUnlock,
  documentSearchBlue,} = ImageImports

const mobileMenuRoutes = [
  {
    id: "home",
    title: "Home",
    path: "/",
    icon: homeBlue,
    cName: "mobile-nav-links",
  },
  {
    id: "techDoc",
    title: "Documents",
    path: generatePath('/documents/:moduleName', {moduleName: 'Documents'}),
    icon: documentSearchBlue,
    cName: "mobile-nav-links"
  },
  {
    id: "administration",
    title: "Administration",
    path: '',
    icon: lockUnlock,
    cName: "mobile-nav-links",
    subRoutes: [
      { route: '/administration/user-management', title: "User Management" },
    ],
    requiresAdmin: true
  }
];

function MobileMenu() {  

    const navHeaderHeight = useRef<HTMLDivElement>(null); 
    const {menuOpen} = ImageImports;
    const [navScollHeight,setNavScollHeight] = useState(0);
    const location = useLocation();
    const stateContext = useContext(Store);
    const [modal, setModal] = useState<ReactElement | undefined>();
   
    useEffect(() => {
      let navh = window.innerHeight;
      let navhh  = navHeaderHeight.current && navHeaderHeight.current.clientHeight;
      if(navh && navhh){
        setNavScollHeight(navh - navhh)
     
      }
    },[navScollHeight]);
    
    const {mobileNavToggle,toggleMobileNav,header: { data },accountSettings: { data: accountSettings }} = stateContext;

    const modals = {
      account: <AccountSettingsModal headerData={data} closeModal={() => setModal(undefined)} />,
      signOut: <SignOutModal closeModal={() => setModal(undefined)} />
    };
    
    const isInternalAdmin = useIsInternalAdmin();
    const adminFilter = useCallback(({requiresAdmin}: {requiresAdmin?: boolean}): boolean => {
      if (requiresAdmin === undefined || requiresAdmin === false) {
        return true;
      }
      return isInternalAdmin;
    }, [isInternalAdmin]);

    return (
      
      <div className={mobileNavToggle ? "sidebar-mobile-wrapper active" : "sidebar-mobile-wrapper"}>
        <div ref={navHeaderHeight}>
          <MobileMenuHeader  />
        </div>
        <nav  style={{'height': navScollHeight - 30}}>
          {mobileMenuRoutes.filter(adminFilter).map((menuItem,index) => {
            if(menuItem.subRoutes){
              
              return <MobileSubmenuLink  
              index= {index}
              key={menuItem.id}
              adminFilter={adminFilter}
              {...menuItem}           
               />
            }
            
            return (
              <div className="mobile-nav-item" key={menuItem.id}>
                
                <Link
                  to={menuItem.path}
                  className={
                    location.pathname === menuItem.path ? "active-menu mobile-nav-links" : "mobile-nav-links"
                  }
                  onClick={() => {                    
                    toggleMobileNav && toggleMobileNav(!mobileNavToggle);
                  }}
                >
                  <div className="menu-icon">
                    <img src={menuItem.icon} alt={menuItem.title} />
                  </div>
                  <div>{menuItem.title}</div>
                  {menuItem.subRoutes && (
                    <img className="menu-toggle-icon" src={menuOpen} alt="open" />
                  )}
                </Link>
              </div>
            );
          })}
          <hr className="mobile-hr" />
          <div className="mobile-flex-column ">
            <div className="mobile-flex-row mobile-setting-links">
              <div className="initials bold">
                <img src={data.user.pictureUrl} alt="" />
              </div> 
              <div className="mobile-grow bold">
                {accountSettings.fName} {accountSettings.lName}
              </div>
            </div>
            <div className="cursor-pointer mobile-setting-links home-blue paddingL-12" onClick={() => { toggleMobileNav && toggleMobileNav(!mobileNavToggle); setModal(modals.account);} }>Account & Settings</div>
            <div className="cursor-pointer mobile-setting-links home-blue paddingL-12" onClick={() => { toggleMobileNav && toggleMobileNav(!mobileNavToggle); setModal(modals.signOut);} }>Sign Out</div>
          </div>
        </nav>
	      {modal}
      </div>
    );
}

export default MobileMenu;