import { Document } from "../../utils/interface";
import { ColumnDefinition } from "./DocumentList";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import { MouseEventHandler, useContext, useId, useState } from "react";
import classes from "./DocumentList.module.css";
import ImageImports from "../../utils/ImageImports";
import ReportsToolTipData from "./ReportsToolTipData/ReportsToolTipData";
import { Store } from "../../Store";

interface ReportRowTableProps {
  data: Document;
  columnDefinitions: ColumnDefinition[];
  tooltipActions: tooltipActionItem[];
  isChecked: boolean;
  onRowChecked: MouseEventHandler;
  unread: boolean;
}

function DocumentItemRow({ data, columnDefinitions, tooltipActions, isChecked, onRowChecked, unread }: ReportRowTableProps) {
  const { ellispis, tooltip: tooltipImage } = ImageImports;
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
    const willBeVisible = !isTooltipVisible;
    const rect = e.currentTarget.getBoundingClientRect();
    setIsTooltipVisible(willBeVisible);
  };

  const { toggleTooltip, setToolTipContent } = useContext(Store);
  const tooltipId = useId().replaceAll(":", "_");

  const Tooltip = () => {
    return (
      <div
        className="row-item text-l cursor-pointer"
        style={{color: "#00749E"}}
        onBlur={() => {
          if (toggleTooltip !== undefined) {
            toggleTooltip(false);
          }
        }}
        tabIndex={1}
        onClick={() => {
          if (toggleTooltip !== undefined) {
            toggleTooltip(true);
            setToolTipContent && setToolTipContent(<ReportsToolTipData data={data} />);
          }
        }}
      >
         <strong>View</strong>
      </div>
    );
  };

  return (
    <tr className={unread? classes.unread: ''}>
      {/* <td>
        <div className={classes.checkmark_container}>
          <div className={classes.checkbox}>
            <input type="checkbox" checked={isChecked} onChange={() => {}} />
            <span className={classes.checkmark} onClick={onRowChecked} />
          </div>
        </div>
      </td> */}
      {columnDefinitions.map((columnDefinition) => (
        <td key={columnDefinition.key} className={`${classes.data_point} ${columnDefinition.className}`} style={columnDefinition.style}>
          {columnDefinition.component ? <columnDefinition.component doc={data} tooltip={<Tooltip />} /> : <div><>{data[columnDefinition.key]}</></div>}
        </td>
      ))}
      <td>
        <div id={tooltipId} className={`${classes.flex_row} ${classes.more_actions}`} onClick={showTooltip}>
          <img src={ellispis} alt="More Actions" />
          <BulkActionTooltip shown={isTooltipVisible} parentId={tooltipId} setShown={setIsTooltipVisible} listItems={tooltipActions.filter(d => !d.removeWhenDeleted || !data.isDeleted)} data={[data]} />
        </div>
      </td>
    </tr>
  );
}

export default DocumentItemRow;
