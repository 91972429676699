import { AxiosError } from "axios";
import React, { FunctionComponent, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { useOnError } from "../../customHooks/useOnError";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { ModuleIDs } from "../../utils/moduleIDs";
import { useCreateNewDocument, useCreateNewOMManual, useUpdateDocument, useUpdateOMManual } from "../DocumentList/ReportsHttpServices";
import classes from "./Documents.module.css";
import { DocumentsModuleOutletInterface } from "./DocumentsModuleContainer";
import Loader from "../../components/Loader/Loader";

interface AddDocumentContainerProps{
  isEdit:boolean;
}

const AddDocumentContainer: FunctionComponent<AddDocumentContainerProps> = ({isEdit}) => {
  const { documentModule, documentGroups } = useOutletContext<DocumentsModuleOutletInterface>();

  const navigate = useNavigate();
  const { addDocumentModalBodyContent: DocumentModalBodyContent } = documentModule;
  const { setIsModal, setShowError, document } = useContext(Store);
  const { data: siteUnits } = {data:undefined}; // usePlantSiteUnits();
  const queryClient = useQueryClient();
  const setErrorData = useOnError();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    mutate: AddDocument,
    isSuccess,
    data: reportData,
    reset,
  } = (documentModule.id === ModuleIDs.PowerReports ? useCreateNewDocument : useCreateNewOMManual)({
    onMutate: () => {
      setIsLoading(true);
    },
    onError: (error: unknown) => {
      setErrorData(error);
      setIsLoading(false);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["documents"]);
      setIsLoading(false);
    },
  });

  const {
    mutate: UpdateDocument,
    isSuccess: isEdited,
    data: editedReportData
  } = (documentModule.id === ModuleIDs.PowerReports ? useUpdateDocument : useUpdateOMManual)({
    onMutate: () => {
      setIsLoading(true);
    },
    onError: (error: unknown) => {
      setErrorData(error);
      setIsLoading(false);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["documents"]);
      setIsLoading(false);
    }
  });

  const { mutate: DeleteMutation, isSuccess: isDeleted } = useMutation((ids: number[]) => documentModule.documentApi.bulkDelete(ids, "User Deleted", documentGroups.find((dg) => dg.id === document.itemTypeId)?.accessType), {
    onSuccess: (data: boolean) => {
      queryClient.invalidateQueries(["documents"]);
    },
    onError: (error: AxiosError<string>) => {
      setShowError &&
        setShowError({
          isError: true,
          title: error.message,
          ErrorType: "danger",
        });
    },
  });

  const handleCloseModal = () => { 
    setIsModal && setIsModal!({ ...CloseAllModal });
    navigate("..");
  };

  return (
    <>
      {(isLoading) &&
        <div className="fixed top-0 left-0 w-full h-screen z-40 !overflow-hidden opacity-50 bg-gray-100 flex flex-col items-center justify-center">
          <Loader />
        </div>
      }

      <ModalGlobal
        modaltitle={isSuccess || isEdited || isDeleted ? `${isDelete ? "Document Successfully Deleted" : isEdit ? "Changes Saved" : "Document Successfully Added"}` : `${isDelete ? 'Delete' : isEdit ? "Edit" : "Add a"} ${documentModule.addEditHeaderName}${isDelete ? "?" : ""}`}
        isModalHeader={true}
        modalClose={handleCloseModal}
        isTitleCenter={isSuccess || isEdited || isDeleted}
        modalSize="sm"
        modalClass={isSuccess || isEdited || isDeleted ? "max-w-[564px]" : ""}
      >
        {!isEdit && documentModule.addModalHeaderContent.length > 0 && (
          <div>
            {documentModule.addModalHeaderContent.map((e, i) => {
              return (
                <p key={i} className={classes.modal_body_text}>{e}</p>
              )
            })}
          </div>
        )}
        {isSuccess && reportData && (
          <div className="flex flex-col gap-6">
            <div className="text-center text-base mb-5">
              The {reportData.displayName} has been added to the Insurance Community.
            </div>
            <div className={`flex flex-row gap-x-4 justify-center items-center`}>
              <Button className={`darkBlue`} text="Add Another Document" onClick={reset} />
              <Button className={`whiteBtn`} text="Done" onClick={handleCloseModal} />
            </div>
          </div>
        )}
        {isEdited && editedReportData && (
          <div className="flex flex-col gap-6">
            <div className="text-center text-base mb-5">
              The "{editedReportData.displayName}" document has been successfully updated.
            </div>
            <div className={`flex flex-row justify-center items-center`}>
              <Button className={`darkBlue`} text="Done" onClick={handleCloseModal} />
            </div>
          </div>
        )}

        {isDeleted && (
          <div className="flex flex-col gap-6">
            <div className="text-center text-base mb-5">
              You successfully deleted the {document.displayName} document.
            </div>
            <div className={`flex flex-row justify-center items-center`}>
              <Button className={`darkBlue`} text="Done" onClick={handleCloseModal} />
            </div>
          </div>
        )}

        {isDelete && !isDeleted && (
          <div className="flex flex-col gap-6">
            <div className="text-base">Are you sure you want to delete this document from the {documentGroups.find((dg) => dg.id === document.itemTypeId)?.name} document group? This document will no longer be accessible to Insurance Community users. This cannot be undone.</div>
            <div className="text-base text-gray-60 mb-5">{document.displayName}</div>
            <div className="flex flex-col md:flex-row items-center">
              <div className="flex-grow-1 flex flex-col md:flex-row gap-x-4">
                <Button className={`redDelete`} text="Yes, Delete" onClick={() => DeleteMutation([document.globalItemId])} />
                <Button className={`whiteBtn`} text="Cancel" onClick={handleCloseModal} />
              </div>
            </div>
          </div>
        )}
        {!isSuccess && !isEdited && !isDelete && (
          <DocumentModalBodyContent documentModule={documentModule} isEdit={isEdit} documentGroups={documentGroups} onSave={isEdit? UpdateDocument : AddDocument} siteUnits={siteUnits ?? []} setDeleteDocument={setIsDelete} handleCloseModal={handleCloseModal} />
        )}
      </ModalGlobal>
    </>
  );
};

export default AddDocumentContainer;
