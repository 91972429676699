import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import DarkLinks from "../../../components/RelatedLinks/DarkLinks";
import ImageImports from "../../../utils/ImageImports";

const {remoteMonitoring, gearTime, userGroup2, logo} = ImageImports;


export const SplashPage: FunctionComponent = () => {
  return (
  <div className="">
    <div className="pt-6 pb-10 px-8 md:p-6 lg:py-10 lg:px-[162px] flex flex-col gap-x-8 relative bg-primary-20 rounded-top">
      <div>
        <Link className="link_text" to="/home">
          <img src={logo} className="nopointer w-40 mb-2" alt="logo" />
        </Link>
      </div>
      <div>
          <h1 className="text-2xl lg:text-40 pt-4 font-bold">Welcome, Insurance Community Members</h1>
          <p className="text-sm lg:text-2xl mblx-2">Mitsubishi Power has established this web portal to provide information to members of the Insurance industry interested in the power generation, energy storage and digital solutions products and related services of Mitsubishi Power.</p>
      </div>
    </div>   
    <div className="pt-6 pb-10 px-8 md:p-6 lg:py-10 lg:px-[162px] relative bg-white rounded-bottom" >
        {/* Bottom Section of the Splash Page */}
        <div className="flex flex-col justify-center">
          <div className="">
            <div className="mx-0">
                <div>
                  <h1 className="text-xl lg:text-2xl font-bold">Sign into the Portal</h1>
                    <p className="text-base mblx-2">You will be prompted to sign in once with your corporate account that you selected to access the Mitsubishi Power Web Portal. If you require access, you can submit a request <Link to="/portal-access" className="no-underline text-ocean">here</Link>.</p>
                </div>
                  <DarkLinks data={relatedData} />
            </div>
          </div>
        </div>
    </div>
  </div>    
  );
};

const relatedData = {
  relatedTitle: "",
  relatedSections: [
    {
      IconUrl: gearTime,
      title: "Insurance Portal",
      description: "Contains presentations made at Mitsubishi Power Insurance Community Conferences, experience lists, technical papers, fleet operating data, links to published material and Mitsubishi Power contact information.",
      buttonLink: "Go To Insurance Portal",
      route: "/"
    }
  ]
};

export default SplashPage;
