import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";
import React, { useMemo } from "react";
import { getCalendarRows } from "../utils";
import "./DatePickerCalendar.css";

export interface IDatePickerCalendarProps {
    shownDate: Dayjs;
    selectedDate: Dayjs;
    onChange: (newDate: Dayjs) => void;
}

export const DatePickerCalendar: React.FC<IDatePickerCalendarProps> = ({
    shownDate,
    selectedDate,
    onChange
}) => {
    const rows = useMemo(() => {
        if (dayjs(shownDate, 'MM/DD/YYYY', true).isValid()) return getCalendarRows(dayjs(shownDate))
    }, [shownDate]);

    return (
        <>
            <div className={"DatePickerCalendar__header"}>
                {rows && rows[0].map(({ value }, i) => (
                    <div key={i} className={"DatePickerCalendar__cell"}>
                        {value.format("dd").split('')[0]}
                    </div>
                ))}
            </div>

            {rows && rows.map((cells, rowIndex) => (
                <div key={rowIndex} className={"DatePickerCalendar__row"}>
                    {cells.map(({ text, value }, i) => (
                        <div
                            key={`${text} - ${i}`}
                            className={clsx(
                                "DatePickerCalendar__cell",
                                "DatePickerCalendar__dayCell",
                                {
                                    DatePickerCalendar__dayCell_selected:
                                        dayjs(value).isSame(selectedDate, 'day')
                                }
                            )}
                            onClick={() => onChange(value)}
                        >
                            {text}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};
