import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Link from "../../../components/Link/Link";
import { useItemTypeCatalog } from "../../../customHooks/useItemTypeCatalog";
import useWindowSize from "../../../customHooks/useWindowSize";
import { IPItemTypes } from "../../../utils/IPItemTypes";
import { DocumentGroup } from "../../../utils/interface";
import { DocumentsModuleOutletInterface } from "../../Documents/DocumentsModuleContainer";
import AddNewDocumentGroupModal from "./AddNewDocumentGroupModal";
import DeletedGroupConfirmationModal from "./DeletedGroupConfirmationModal";
import classes from "./ManageDocumentGroups.module.css";
import NewGroupConfirmationModal from "./NewGroupConfirmationModal";
import ReportTypeListItem from "./ReportTypeListItem";

function ManageDocumentGroups() {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const accessTypes = [
	{ id: IPItemTypes.IPDocument, name: "General Access" },
	{ id: IPItemTypes.IPDocumentLA, name: "Limited Access" },
  ];
  const [accessType, setAccessType] = useState<any>();
  const [showAddNewGroupModal, setShowAddNewGroupModal] = useState<boolean>(false);
  const [showNewGroupConfirmationModal, setShowNewGroupConfirmationModal] = useState<boolean>(false);
  const [showDeletedGroupConfirmationModal, setShowDeletedGroupConfirmationModal] = useState<DocumentGroup | null>(null);
  const [documentTypes, setDocumentTypes] = useState<DocumentGroup[]>([]);
  const [selectedReportType, setSelectedReportType] = useState<DocumentGroup | null>(null);
  const [openEdits, setOpenEdits] = useState<number>(0);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const { documentModule, documentGroups, setDocumentGroups } = useOutletContext<DocumentsModuleOutletInterface>();

  const { reorder: reorderDocumentGroups } = useItemTypeCatalog();
  const {
    create: createDocumentType,
    update: updateDocumentType,
    del: deleteDocumentType,
  } = documentModule.documentGroupApi;

  useEffect(() => {
    setDocumentTypes(documentGroups);
  }, [documentGroups]);

  const addNewDocumentGroup = async (name: string, accessType: number) => {
	const newReportType = {...(await createDocumentType({
		name,
		accessType
	})), accessType: accessType};
	setDocumentTypes([...documentTypes, newReportType]);
	setDocumentGroups([...documentTypes, newReportType]);
	setSelectedReportType(newReportType);
	setShowNewGroupConfirmationModal(true);
  };

  const saveDocumentType = async (reportType: DocumentGroup): Promise<void> => {
 
    const savedReportType = await updateDocumentType(reportType);
    // replace this the corresponding reportType in the state
    // get the index of the saved reportType by the itemTypeId
    const index = documentTypes.findIndex((reportType) => reportType.id === savedReportType.id);
    // if we found the itemTypeId in reportTypes, remove 1 element at that index and add the saved reportType at index spot
    // then reset reportTypes in the state
    if (index > -1) {
      let tmpReportTypes = [...documentTypes];
      tmpReportTypes.splice(index, 1, savedReportType);
      setDocumentTypes(tmpReportTypes);
    }
  };

  const deleteReportType = async (deleteReportType: DocumentGroup): Promise<void> => {
   
    const wasSuccessfull = await deleteDocumentType(deleteReportType);
    // remove this report type from the ui
    if (wasSuccessfull) {
      const index = documentTypes.findIndex((reportType) => reportType.id === deleteReportType.id);

      // if we found the itemTypeId in reportTypes, remove 1 element at that index
      // then reset reportTypes in the state
      if (index > -1) {
        let tmpReportTypes = [...documentTypes];
        tmpReportTypes.splice(index, 1);
        setDocumentTypes(tmpReportTypes);
        setDocumentGroups(tmpReportTypes);
      }
      setShowDeletedGroupConfirmationModal(deleteReportType);
    }
  };


  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = documentTypes.findIndex(({ id }) => id === active.id);
      const newIndex = documentTypes.findIndex(({ id }) => id === over.id);
      let tmpReportTypes: DocumentGroup[] = arrayMove(documentTypes, oldIndex, newIndex);

      // now we need to update the orders..
      // loop through report types and reassign orders
      for (let i = 0, displayOrder = 1; i < tmpReportTypes.length; i++, displayOrder++) {
        tmpReportTypes[i].displayOrder = displayOrder;
      }
      // update the database too
      reorderDocumentGroups(tmpReportTypes.map(({ id, displayOrder }) => ({ id, displayOrder })));
      setDocumentTypes(tmpReportTypes);
    }
  };

  return (
    <div
      className={`${classes.manage_document_groups} ${classes.flex_column} ${isMobile ? classes.is_mobile : ""} ${isTablet ? classes.is_tablet : ""}`}
    >
      {showAddNewGroupModal && (
        <AddNewDocumentGroupModal shown={showAddNewGroupModal} onCreate={addNewDocumentGroup} onClose={() => setShowAddNewGroupModal(false)} accessType={accessType} setAccessType={setAccessType} accessTypes={accessTypes} groups={documentTypes}/>
      )}
      {showNewGroupConfirmationModal && (
        <NewGroupConfirmationModal
		  type={accessType.name}
          shown={showNewGroupConfirmationModal}
          onClose={() => {
            setShowNewGroupConfirmationModal(false);
            setSelectedReportType(null);
          }}
          addNewGroup={() => {
            setShowNewGroupConfirmationModal(false);
            setShowAddNewGroupModal(true);
          }}
          addNewDocument={() => {
            setShowNewGroupConfirmationModal(false);
            navigate(`../${selectedReportType?.id}/add`);
          }}
          reportType={selectedReportType}
        />
      )}
      {showDeletedGroupConfirmationModal && (
        <DeletedGroupConfirmationModal
          shown={showDeletedGroupConfirmationModal !== null}
          reportType={showDeletedGroupConfirmationModal}
          onClose={() => setShowDeletedGroupConfirmationModal(null)}
        />
      )}
      <div className={`${classes.intro} ${classes.flex_column}`}>
        <Link className={`${classes.back_container} ${classes.flex_row}`} to="..">
          &lt; &nbsp;&nbsp;&nbsp; Back to {documentModule.name}
        </Link>
        <div className={`${classes.section_title_cta} ${classes.flex_row}`}>
          <div className={`${classes.section_title}`}>
            <span className={`${classes.section_heading}`}>Manage Document Groups</span>
            <span className={`${classes.section_description}`}>
			Document groups cannot be deleted until the files within it have been moved to another group. Files can be moved on the previous {documentModule.name} page.
            </span>
          </div>
          <div className={`${classes.cta_container}`}>
            <Button className={`darkBlue`} disabled={openEdits > 0} text="Add Document Group" onClick={() => setShowAddNewGroupModal(true)} />
          </div>
        </div>
      </div>

      <div className={`${classes.report_types_container} ${classes.flex_column}`}>
        <DndContext
          onDragEnd={handleDragEnd}
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext strategy={verticalListSortingStrategy} items={documentTypes}>
            {documentTypes.map((reportType) => (
              <ReportTypeListItem
                reportType={reportType}
                key={reportType.id}
                onSave={saveDocumentType}
                editToggled={(isEditing) => {
                  isEditing ? setOpenEdits(openEdits + 1) : setOpenEdits(openEdits - 1);
                }}
                onDelete={deleteReportType}
				accessTypes={accessTypes} 
			  />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}

export default ManageDocumentGroups;
