import { useContext, useState } from "react";
import FormSuccess from "../../../../components/FormsPages/FormSuccess/FormSuccess";
import { Store } from "../../../../Store";
import { CloseAllModal } from "../../../../utils/CloseAllModal";
import { GroupContactData, ContactsArray } from "../../../../utils/interface";
import AddEditInsuranceContacts from "../../Forms/AddEditInsuranceContacts";
import ResourcesCard from "../ResourcesCard";
import Contact from "./Contact/Contact";

const Contacts = ({ contacts }: ContactsArray) => {
  const { showError, isModal, setIsModal } = useContext(Store);
  const [contactID, setContactID] = useState<number>(0);

  const [contactData, setContactData] = useState<GroupContactData>({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    primaryPhone: "",
    primaryPhoneTypeId: 0,
    primaryPhoneType: "",
    secondaryPhone: "",
    secondaryPhoneTypeId: 0,
    secondaryPhoneType: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    displayOrder: 0,
    created: "",
    createdBy: "",
    modified: "",
    modifiedBy: "",
    userGroupId: 0,
  });

  const handleAddContact = () => {
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isAdd: {
          ...isModal.isAdd,
          isInsuranceContacts: true,
        },
      });
  };

  const handleEditContact = (id: number) => {
    setContactID(id);
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isEdit: {
          ...isModal.isEdit,
          isInsuranceContacts: true,
        },
      });
  };

  return (
    <ResourcesCard onClick={handleAddContact} title={"Contacts"} buttonName={"Add New Contact"}>
      {contacts.map((c) => (
        <Contact key={c.id} contact={c} handleEditContact={handleEditContact} />
      ))}

      {/* Insurance Contacts Modals START */}
      {isModal.isAdd.isInsuranceContacts && (
        <AddEditInsuranceContacts
          modaltitle={"Add New Contact"}
          modaltitlename={""}
          setContactData={setContactData}
          contactData={{...contactData, displayOrder: contacts.length + 1}}
          isEdit={false}	
        />
      )}
      {isModal.isAddedSuccess.isInsuranceContacts && (
        <FormSuccess
          modaltitlename={"Contact Successfully Added"}
          modaltitle={""}
          isTitleCenter={true}
          onClick={handleAddContact}
          buttonName="Add Another Contact"
          comment={`${contactData.firstName} ${contactData.lastName} has been added to the Insurance Community contacts.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={true}
        />
      )}
      {isModal.isEdit.isInsuranceContacts && (
        <AddEditInsuranceContacts
          id={contactID}
          modaltitle={"Edit Contact"}
          modaltitlename={""}
          isEdit={true}
          setContactData={setContactData}
          contactData={contactData}
        />
      )}
      {isModal.isEditedSuccess.isInsuranceContacts && (
        <FormSuccess
          modaltitlename={"Changes Saved"}
          modaltitle={""}
          isTitleCenter={true}
          comment={`The contact details for ${contactData.firstName} ${contactData.lastName}  have been successfully updated.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {isModal.isDeleteSuccess.isInsuranceContacts && (
        <FormSuccess
          modaltitlename={"Contact Successfully Deleted"}
          modaltitle={""}
          isTitleCenter={true}
          comment={`You successfully deleted ${contactData.firstName} ${contactData.lastName}  from the Insurance Portal contacts.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {/* Insurance Contacts Modals END */}
    </ResourcesCard>
  );
};

export default Contacts;
