import React from 'react';
import useWindowSize from '../../customHooks/useWindowSize';
import Button from '../Button/Button';
import { useNavigate } from "react-router-dom";

import './RelatedLinks.css'

export enum RelatedLinkTarget {
	_self,
	_blank,
	_parent, 
	_top,
}

export interface RelatedLinksData {
  relatedTitle: string
  relatedSections: Array<{
    title: string,
    description: string,
    buttonLink: string,
    route: string,
	target?: RelatedLinkTarget,
  }>
};

interface RelatedLinksProps {
  data: RelatedLinksData
}

function RelatedLinks(props: RelatedLinksProps) {
  const navigate = useNavigate();
    const {isDesktop, isMobile, isTablet} = useWindowSize();

	return (
    <div className={`relatedLinks`} style={{margin: isDesktop ? 48 : 20}}>
      <div className='relatedLinks_header'>
        {props.data.relatedTitle}
      </div>
      <div className='relatedLinks_content' style={{flexFlow: (isMobile || isTablet) ? 'column' : 'row'}}>
        {
          props.data.relatedSections.map((obj, i) => {
            return (
              <div className='column relatedLinks_item' key={i} style={{flexGrow: 1, flexBasis: 0}}>
                <div className='relatedLinks_title'>{obj.title}</div>
                <div className='relatedLinks_paragraph'>{obj.description}</div>
                <div className=''>
                  <Button className="whiteBtn" style={{width: isMobile ? "100%" : isTablet ? 300 : "unset"}} text={obj.buttonLink} onClick={() => obj.target === RelatedLinkTarget._blank ? window.open(obj.route, '_blank', 'noopener,noreferrer') : navigate(obj.route)} />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>

  );
}

export default RelatedLinks;
