import { Form, Formik, FormikHelpers } from "formik";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { useIsFetching, useQueryClient } from "react-query";
import * as Yup from "yup";
import { Store } from "../../../Store";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import SimpleDelete from "../../../components/FormsPages/FormDelete/SimpleDelete";
import Loader from "../../../components/Loader/Loader";
import TextInputField from "../../../components/UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../../../components/UI/Modal/ModalGlobal";
import { useOnError } from "../../../customHooks/useOnError";
import { queryKeys } from "../../../react-query/queryKeys";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import { CPApiResponse, PublishedLinksData, PublishedLinksFormData, deleteInterface } from "../../../utils/interface";
import {
  useCreate_PublishedLinks,
  useDelete_PublishedLinks,
  useGet_PublishedLink,
  useUpdate_PublishedLinks,
} from "../HttpServices/PublishedLinksHttpServices";

const AddEditPublishedLink = ({ isEdit, modaltitle, modaltitlename, publishLinkData, setPublishLinkData, id }: PublishedLinkAddEditProps) => {
  const {
    isModal,
	
    setIsModal,
    showError,
  } = useContext(Store);
  const queryClient = useQueryClient();

  const setErrorData = useOnError();

  const [publishedLinkID, setPublishedLinkId] = useState<number>(0);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const isFetching = useIsFetching([queryKeys.PublishLinksApi]);

  const { mutate: CreatePublishedLink, isLoading: isCreating } = useCreate_PublishedLinks();
  const { mutate: UpdateePublishedLink, isLoading: isUpdating } = useUpdate_PublishedLinks();
  const { mutate: DeletePublishedLink, isLoading: isDeleting } = useDelete_PublishedLinks();

  const handleDeletePublishedLink = () => {
    DeletePublishedLink(publishLinkData.id, {
      onSuccess: (responseData: deleteInterface) => {
        queryClient.invalidateQueries(queryKeys.PublishLinksApi);
        setIsModal!({
          ...CloseAllModal,
          isDeleteSuccess: {
            ...isModal.isDeleteSuccess,
            isPublishedLink: true,
          },
        });
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };

  useEffect(() => {
    if (id && id !== publishedLinkID) {
      setPublishedLinkId(id);
    }
  }, [id, publishedLinkID]);

  // --------------------------------------------------------//

  useGet_PublishedLink(publishedLinkID, {
    enabled: !!publishedLinkID && isEdit && !isDelete,
    onSuccess: (PublishedLinksData: CPApiResponse<PublishedLinksData>) => {
      setPublishLinkData(PublishedLinksData.data);
    },
    onError: (error: unknown) => {
      setErrorData(error);
    },
  });

  // --------------------------------------------------------//

  const handleOnSubmit = (values: PublishedLinksFormData, formikHelpers: FormikHelpers<PublishedLinksFormData>) => {
    if (!isEdit) {
      CreatePublishedLink(values, {
        onSuccess: (responseData: CPApiResponse<PublishedLinksData>) => {
          setPublishLinkData(responseData.data);
          queryClient.invalidateQueries(queryKeys.PublishLinksApi);

          formikHelpers.setSubmitting(false);
          setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isAddedSuccess: {
                ...isModal.isAddedSuccess,
                isPublishedLink: true,
              },
            });
        },
        onError: (error: unknown) => {
          formikHelpers.setSubmitting(false);
          setErrorData(error);
        },
      });
    } else {
      UpdateePublishedLink(values, {
        onSuccess: (responseData: CPApiResponse<PublishedLinksData>) => {
          setPublishLinkData(responseData.data);
          queryClient.invalidateQueries(queryKeys.PublishLinksApi);

          formikHelpers.setSubmitting(false);
          setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isEditedSuccess: {
                ...isModal.isEditedSuccess,
                isPublishedLink: true,
              },
            });
        },
        onError: (error: unknown) => {
          formikHelpers.setSubmitting(false);
          setErrorData(error);
        },
      });
    }
  };

  let initialValues: PublishedLinksFormData;
  initialValues = {
    id: publishLinkData.id,
    title: publishLinkData.title,
    url: publishLinkData.url,
    displayOrder: publishLinkData.displayOrder,
  };

  if (!isEdit) {
    initialValues = {
      title: "",
      url: "",
      displayOrder: initialValues.displayOrder || 1,
    };
  }

  return (
    <ModalGlobal modaltitle={isDelete ? "Delete Published Link" : modaltitle} modaltitlename={modaltitlename} isModalHeader={true}>
      {!isDelete ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={Yup.object({
            title: Yup.string().required("This field is required"),
            url: Yup.string().url().required("Please enter website"),
            displayOrder: Yup.number().positive().required("This field is required"),
          })}
        >
          {(formikProps) => (
            <>
              {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
              <Form>
                {isFetching > 0 || isUpdating || isCreating ? (
                  <Loader marginTop={"0"} />
                ) : (
                  <>
                    <div>
                      <TextInputField
                        name="title"
                        labelName="Link Title*"
                        placeholder="Title for Published Link"
                        className=""
                        tooltip={{
                          text: "Please enter the title of the information being linked to here.",
                        }}
                      />
                    </div>
                    <div>
                      <TextInputField
                        name="url"
                        labelName="URL*"
                        placeholder="ex. https://website.com"
                        className=""
                        tooltip={{
                          text: "Please enter the URL for the information being linked to here.",
                        }}
                      />
                    </div>

                    <div>
                      <TextInputField
                        name="displayOrder"
                        labelName="Display Order*"
                        type="number"
                        className=""
                        placeholder="ex. 1"
                        tooltip={{
                          text: "The order in which this link displays on the Insurance Portal homepage, relative to other links in the list.",
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="flex flex-col md:flex-row items-center">
                  <div className="flex-grow-1 flex flex-col md:flex-row gap-x-4">
                    {isEdit ? (
                      <>
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                          Save Changes
                        </button>
                        <button type="button" onClick={() => setIsDelete(true)} className="primaryBtn redDelete">
                          Delete
                        </button>
                      </>
                    ) : (
                      <>
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                          Add Link
                        </button>
                      </>
                    )}
                    <button type={"button"} className="primaryBtn whiteBtn" onClick={() => {setIsModal?.({ ...CloseAllModal })}}>
                      Cancel
                    </button>
                  </div>
                  <span className="justify-center md:justify-end text-sm font-light">*Required field</span>
                </div>
              </Form>
            </>
          )}
        </Formik>
      ) : (
        <SimpleDelete
          id={publishLinkData.id}
          subDescription={publishLinkData.title}
          description={`Are you sure you want to delete this published link? This published link will no longer be visible to Insurance Portal users. This cannot be undone.`}
          handleDelete={handleDeletePublishedLink}
          isDeleting={isDeleting}
        />
      )}
    </ModalGlobal>
  );
};

interface PublishedLinkAddEditProps {
  modaltitle: string;
  modaltitlename: string;
  isEdit: boolean;
  id?: number;
  setPublishLinkData: Dispatch<React.SetStateAction<PublishedLinksData>>;
  publishLinkData: PublishedLinksData;
}

export default AddEditPublishedLink;
