import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { MainContainer } from "../MainContainer";
import { ManageUser } from "./ManageUser";

export const ManageUserContainer = () => {
    const {userId} = useParams();

    const nUserId = useMemo(() => {
        return +(userId || 0);
    }, [userId]);

    return <MainContainer breadCrumbs={['Administration', 'User Management']} title="User Management">
        <ManageUser userId={nUserId} />
    </MainContainer>
}