import React, { useContext } from 'react';
import { Store } from "../../Store"
import close from '../../assets/close.svg';
import './ToolTip.css';
import useWindowSize from '../../customHooks/useWindowSize';

interface ToolTipProps{
  headerHeight?:number;
}
function ToolTip({headerHeight}:ToolTipProps) {
  const {toolTip, toggleTooltip, toolTipContent} = useContext(Store);
  const {isDesktop} = useWindowSize();
 

  return (
    <div className='tooltip-wrapper'
         tabIndex={1}
         onMouseEnter={(e) => {
          e.currentTarget.focus({preventScroll: true});
          toggleTooltip && toggleTooltip(true);
        }}
        onBlur={() => toggleTooltip && toggleTooltip(false)}>
      <div   style={{paddingTop: !isDesktop ? '50px' : 0}} className={toolTip ? "tooltip active" : "tooltip"}>
        <nav  className={toolTip ? "tooltip-menu active" : "tooltip-menu"}>
          <div className='tooltip-menu-items'>

              <div className={toolTip ? "active close" : "close"}
              onClick={() => toggleTooltip && toggleTooltip(!toolTip)}>
                  <img src={close} className='close-icon' alt='close icon' />
              </div>

            <div className={"tooltip-content-wrapper"}>
              {toolTipContent}
            </div>
          </div>
        </nav>
      </div>

    </div>
  );
}

export default ToolTip;