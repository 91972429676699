import React, { MouseEventHandler, MutableRefObject, useCallback, useContext, useEffect, useId, useMemo, useState } from "react";
import Accordion from "../Accordian/Accordion";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import { Store } from "../../Store";
import { ItemListProps, PropertiesOfType } from "./ItemList";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";

const { ellispis } = ImageImports;

interface AccordionListItemProps<T> {
    data: T;
    columns: ItemListProps<T>['columns'];
    bulkActions?: ItemListProps<T>['bulkActions'];
    itemActions?: ItemListProps<T>['itemActions'];
    deletedItemKey?: ItemListProps<T>['deletedItemKey'];
    readItemKey?: ItemListProps<T>['readItemKey'];
    accordionTitle?: ItemListProps<T>['accordionTitle'];

    tooltipPortalRef: MutableRefObject<null>;
    isChecked: boolean;
    onRowChecked: MouseEventHandler;
}

export const AccordionListItem = <T extends {id: number}>({data, columns, deletedItemKey, tooltipPortalRef, bulkActions, isChecked, onRowChecked, readItemKey, accordionTitle, itemActions}: AccordionListItemProps<T>) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const getDataBooleanValue = useCallback(<K extends PropertiesOfType<T, boolean>>(d: T, k: K): boolean => !!d[k], []);
    const isRead = useMemo(() => {
        return readItemKey === undefined || getDataBooleanValue(data, readItemKey);
    }, [data, readItemKey]);

    const ItemActionTooltip = useMemo(() => {
            return withTooltip(() => <div className="flex flex-row !pb-0 items-center gap-2 cursor-pointer">
                <img src={ellispis} alt="More Actions" />{' '}
                More Actions
        </div>);
    }, [itemActions]);

    return (
        <Accordion isDeleted={deletedItemKey !== undefined && getDataBooleanValue(data, deletedItemKey)}
                   titleContent={<AccordionTitle unread={!isRead} isChecked={isChecked} reportName={typeof accordionTitle === 'function'? accordionTitle(data) : ''} onRowChecked={onRowChecked} hasBulkActions={bulkActions !== undefined && bulkActions.length > 0} />}
                   isOpen={isOpen}
                   headerStyle={{}}
                   contentStyle={{
                    overflow: isOpen? 'visible' : 'hidden'
                   }}
                   toggleOpen={toggleOpen}>
            <div className={`flex flex-col ${!isRead? 'unread' : ''}`}>
                <table className={``}>
                    <tbody>
                        {columns.map(columnDefinition => (
                            <tr key={columnDefinition.key as string} className={``}>
                                <th className="text-left">{columnDefinition.title}</th>
                                <td>
                                    {columnDefinition.component? (<columnDefinition.component data={data} />) : (<div><>{data[columnDefinition.key]}</></div>)}
                                </td>
                            </tr>
                        ))}
                        {itemActions !== undefined && itemActions.length > 0? (
                            <tr>
                                <td>
                                    <span className="flex">
                                        <ItemActionTooltip tooltipContent={<div className="p-6 rounded-lg">
                                            <div className="flex flex-col items-start gap-6">
                                                {itemActions.map((action, i) => (
                                                    <span key={`action-${i}`} onClick={(e) => {
                                                        action.onClick(e, data);
                                                    }}>{action.text}</span>
                                                ))}
                                            </div>
                                        </div>} />
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                        ) : ''}
                    </tbody>
                </table>
            </div>
        </Accordion>
    );
}

interface AccordionTitleProps {
    isChecked: boolean;
    reportName: string;
    onRowChecked: MouseEventHandler;
    unread: boolean;
    hasBulkActions: boolean;
}

function AccordionTitle({isChecked, reportName, onRowChecked, unread, hasBulkActions}: AccordionTitleProps) {
    const checkRow: MouseEventHandler = (e) => {
        e.stopPropagation();
        onRowChecked(e);
    };
    return (
        <div className={`flex flex-row !p-3 items-center ${unread? 'font-bold' : ''}`}>
            {hasBulkActions && (
                <div className="pr-2" style={{flexBasis: '18px'}} onClick={checkRow}>
                    <Checkbox isChecked={isChecked} onChange={() => {}} />
                </div>
            )}
            {reportName} 
        </div>
    );
}