import {
  apiAccountSettings,
  apiHeader,
  apiIssueReports,
  apiPartsAndServices,
  apiPlantSiteDetails,
  apiTechnicalDocumentation,
} from "../../endpoints/endpoints";
import {
  AccountSettingsDTO,
  AccountSettingsData,
  DocumentsDTO,
  HeaderDTO,
  IssueReportsDTO,
  PartsAndServicesDTO,
  PlantSiteDetailsDTO
} from "../../utils/interface";

import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { QueryFunctionContext, useQuery } from "react-query";
import { Store } from "../../Store";
import { queryKeys } from "../../react-query/queryKeys";

export interface DashboardCrudMethods<H, A, B, C, D, E> {
  getHeader: () => Promise<H>;
  getAccountSettings: () => Promise<D>;
  updateAccountSettings: (data: AccountSettingsData) => Promise<E>;
  getTD: () => Promise<A>;
  getPS: () => Promise<B>;
  getIR: () => Promise<C>;
}

export const DashboardApi: DashboardCrudMethods<
  AxiosResponse<HeaderDTO>,
  AxiosResponse<DocumentsDTO>,
  AxiosResponse<PartsAndServicesDTO>,
  AxiosResponse<IssueReportsDTO>,
  AxiosResponse<AccountSettingsDTO>, 
  AccountSettingsDTO
> = {
  getHeader: async (): Promise<AxiosResponse<HeaderDTO>> => {
    const response = await axios(`${apiHeader}`);
    return response.data;
  },
  getAccountSettings: async (): Promise<AxiosResponse<AccountSettingsDTO>> => {
	const response = await axios(`${apiAccountSettings}`);
	return response.data;
  },
  updateAccountSettings: async (data: AccountSettingsData): Promise<AccountSettingsDTO> => {
    const response = await axios({
      url: `${apiAccountSettings}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },     
      data: data,
    });
    return response.data;
  },
  getTD: async (): Promise<AxiosResponse<DocumentsDTO>> => {
    const response = await axios(`${apiTechnicalDocumentation}`);
    return response.data;
  },
  getPS: async (): Promise<AxiosResponse<PartsAndServicesDTO>> => {
    const response = await axios(`${apiPartsAndServices}`);
    return response.data;
  },
  getIR: async (): Promise<AxiosResponse<IssueReportsDTO>> => {
    const response = await axios(`${apiIssueReports}`);
    return response.data;
  },
};

export const useGet_HeaderData = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.header, DashboardApi.getHeader, {
    refetchOnWindowFocus:true,
    onSuccess,
    onError,
  });
};

export const useGet_AccountSettings = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.accountSettings, DashboardApi.getAccountSettings, {
    onSuccess,
    onError,
  });
};

export const useGet_IssueReports = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.issueReports, DashboardApi.getIR, {
    onSuccess,
    onError,
  });
};

export const useGet_PartsAndServices = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.partsAndServices, DashboardApi.getPS, {
    onSuccess,
    onError,
  });
};

export const useGet_Documents = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.documents, DashboardApi.getTD, {
    onSuccess,
    onError,
  });
};


declare type getSelectedPlantDetailsParameters = [string, {plantId: number}];
const getSelectedPlantDetails = async ({queryKey}: QueryFunctionContext<getSelectedPlantDetailsParameters>): Promise<PlantSiteDetailsDTO> => {
    const [_key, {plantId}] = queryKey;
    if (!plantId) {
        return Promise.reject(new Error('Invalid Plant Id'));
    }

    const response: AxiosResponse<PlantSiteDetailsDTO> = await axios(`${apiPlantSiteDetails}/${plantId}`);
    return response.data;
};

export const useSelectedPlantDetails = (onSuccess: any, onError: any) => {
  const {selectedPlant} = useContext(Store);
  
  return useQuery<PlantSiteDetailsDTO, unknown, PlantSiteDetailsDTO, getSelectedPlantDetailsParameters>([queryKeys.plantSiteDetails, {plantId: selectedPlant.plantId}], getSelectedPlantDetails, {
    onSuccess,
    onError,
    enabled: selectedPlant.plantId > 0
  });
};
