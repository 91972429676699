import { useRef } from "react";
import { AccordionListItem } from "./AccordionListItem";
import { ItemListProps } from "./ItemList";

interface AccordionListProps<T> {
    data: T[];
    columns: ItemListProps<T>['columns'];
    bulkActions?: ItemListProps<T>['bulkActions'];
    itemActions?: ItemListProps<T>['itemActions'];
    deletedItemKey?: ItemListProps<T>['deletedItemKey'];
    readItemKey?: ItemListProps<T>['readItemKey'];
    accordionTitle?: ItemListProps<T>['accordionTitle'];
    selectedItems: T[];
    onItemSelectionChange: (item: T) => void;
    onSelectAllItems: () => void;
}

export const AccordionList = <T extends {id: number}>({data, selectedItems, onItemSelectionChange, onSelectAllItems, ...props}: AccordionListProps<T>) => {
    const tooltipPortalRef = useRef(null);
    
    return <>
        {data.map((row) => (
            <AccordionListItem
                key={row.id}
                data={row}
                {...props}
                onRowChecked={() => onItemSelectionChange(row)}
                isChecked={selectedItems.map(d => d.id).includes(row.id)}
                tooltipPortalRef={tooltipPortalRef}
            />
        ))}
        <div ref={tooltipPortalRef} />
    </>
};