import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useOutletContext } from "react-router-dom";
import { Store } from "../../../Store";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import EmptyModal from "../../../components/Modals/EmptyModal";
import useWindowSize from "../../../customHooks/useWindowSize";
import { IPItemTypes } from "../../../utils/IPItemTypes";
import ImageImports from "../../../utils/ImageImports";
import { DocumentGroup, PlantPermissionedReports } from "../../../utils/interface";
import { DocumentsModuleOutletInterface } from "../../Documents/DocumentsModuleContainer";
import DeleteGroupConfirmationModal from "./DeleteGroupConfirmationModal";
import classes from "./ManageDocumentGroups.module.css";

const { pencil, dragReorder } = ImageImports;

interface ReportTypeListItemProps {
	reportType: DocumentGroup;
	onSave: (reportType: DocumentGroup) => void;
	onDelete: (reportType: DocumentGroup) => void;
	editToggled: (isEditing: boolean) => void;
	accessTypes: any[];
}

function ReportTypeListItem({ reportType, editToggled, onSave, onDelete, accessTypes }: ReportTypeListItemProps) {
	const { isMobile } = useWindowSize();

	const [accessType, setAccessType] = useState<any>(accessTypes.find((access) => access.id === reportType.accessType));
	const [documents, setDocuments] = useState<number>(0);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { documentModule } = useOutletContext<DocumentsModuleOutletInterface>();
	const [name, setName] = useState<string>("");
	const [showDeleteGroupConfirmationModal, setShowDeleteGroupConfirmationModal] = useState<boolean>(false);
	const getReport = documentModule.documentApi.getAll;
	const { plantConfig } = useContext(Store);

	const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({ id: reportType.id });
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const cancelEdit = () => {
		setName(reportType.name);
		setAccessType(accessTypes.find((access) => access.id === reportType.accessType));
		closeEdit();
	};

	const closeEdit = () => {
		setIsEditing(false);
		editToggled(false);
	};

	const startEditing = () => {
		setIsEditing(true);
		editToggled(true);
	};

	const save = (accessChangeConfirmed?: boolean) => {
		onSave({ ...reportType, name: name, ...(accessChangeConfirmed ? {accessType: accessType.id} : {}) });
		closeEdit();
		if (reportType.accessType !== accessType.id && !accessChangeConfirmed) setModal(modals.confirmChangeAccess);
	};

	const deleteReportType = () => {
		setShowDeleteGroupConfirmationModal(false);
		closeEdit();
		onDelete(reportType);
	};

	useQuery(
		["documents", "getReports", plantConfig.data.baseOrgId, reportType.id],
		() => {
			return documentModule.documentApi.getAll(reportType);
		},
		{
			onSuccess: (data: PlantPermissionedReports) => {
				setDocuments(data.items.length);
			},
			enabled: !!reportType.id,
		}
	);

	useEffect(() => {
		setName(reportType.name);
	}, [reportType]);

	const cancelAccessChange = () => {
		setAccessType(accessTypes.find((access) => access.id === reportType.accessType));
		setModal(undefined);
	}

	const [modal, setModal] = useState<ReactElement | undefined>();
	const modals = {
		confirmChangeAccess: (
			<EmptyModal
				shown={true}
				body={
					<div className="flex flex-col gap-4">
						{reportType.accessType === IPItemTypes.IPDocumentLA ? (
							<span>
								Are you sure you want to change this document group's access? Doing so will make all the documents in this group accessible to <strong>all</strong> Insurance Portal
								users.
							</span>
						) : (
							<span>
								Are you sure you want to change this document group's access? Doing so will make all the documents in this group <strong>no longer accessible</strong> to all Insurance Portal users.
							</span>
						)}

            <span className="text-gray-60">{reportType.name}</span>
					</div>
				}
				footer={
					<div className={`${classes.modal_button_group} ${classes.flex_row}`}>
						<Button
							className={`${reportType.accessType === IPItemTypes.IPDocumentLA ? "redDelete" : "darkBlue"}`}
							text={`Yes, ${reportType.accessType === IPItemTypes.IPDocumentLA ? "Disable" : "Enable"}`}
							onClick={() => {
								save(true);
								setModal(undefined);
							}}
						/>
						<Button
							className="whiteBtn"
							text="Cancel"
							onClick={cancelAccessChange}
						/>
					</div>
				}
				heading="Change Document Access Group?"
				onClose={cancelAccessChange}
				width={isMobile ? 300 : 564}
				footerClass={classes.modal_footer}
			/>
		),
	};

	return (
		<div className={`${classes.report_type_list_item} ${classes.flex_row}`} ref={setNodeRef} style={style} {...attributes}>
			<div ref={setActivatorNodeRef} {...listeners} className={`${classes.move_icon} ${classes.flex_row}`}>
				<img src={dragReorder} alt="Reorder Report Groups" />
			</div>
			{showDeleteGroupConfirmationModal && (
				<DeleteGroupConfirmationModal
					shown={showDeleteGroupConfirmationModal}
					onClose={() => setShowDeleteGroupConfirmationModal(false)}
					onDelete={deleteReportType}
					reportType={reportType}
				/>
			)}
			<div className={`${classes.report_type_inner} ${isEditing ? classes.flex_column : classes.flex_row} break-all w-full flex-wrap`}>
				<div className={`${classes.input_wrapper} ${classes.flex_column}`}>
					{isEditing && <span className={`${classes.edit_group_name} ${classes.flex_row}`}>{reportType.name}</span>}
					<input
						type="text"
						className={classes.flex_row}
						value={name}
						readOnly={!isEditing}
						onChange={(e) => setName(e.target.value)}
					/>
					<div className={classes.help_text}>
						{documents} document{documents === 1 ? "" : "s"} {reportType.accessType === IPItemTypes.IPDocumentLA && "(limited access)"}
					</div>
				</div>
				{isEditing && (
          
					<div className={`${classes.input_wrapper} ${classes.flex_column} break-all w-full`}>
						<span>Group Access</span>
						<Dropdown
              className="access-dropdown"
							style={{ width: "100%", border: "1px solid #999999", fontSize: 14, borderRadius: "0.25rem" }}
							placeholder="ex. General Access"
							dropdownStyle={{ backgroundColor: "white" }}
							handleDropDownSelect={(e, option) => setAccessType(option)}
							selectedOption={accessType?.name || null}
							setSelectedOption={() => {}}
							options={accessTypes}
						/>
            <div className={`${classes.help_text} w-full break-words`}>
              {reportType.accessType === IPItemTypes.IPDocumentLA ? "This group will be accessible to only a limited group of Insurance Portal users." : "This group will be visible and accessible to all customers in the Insurance Portal unless set to “Limited Access”."}
            </div>
					</div>
          
          
				)}
				{isEditing ? (
					<>
						<div className={`${classes.edit_button_group} ${classes.flex_row}`}>
							<div className={`${classes.edit_left_button_group} ${classes.flex_row}`}>
								<Button className="darkBlue" text="Save Changes" onClick={save} disabled={reportType.name === name && reportType.accessType === accessType.id} />
								<Button className="redDelete" text="Delete Document Group" disabled={documents > 0} onClick={() => setShowDeleteGroupConfirmationModal(true)} />
							</div>
							<Button className="whiteBtn" text="Cancel" onClick={cancelEdit} />
						</div>
					</>
				) : (
					<div className={`${classes.edit_icon}`} onClick={startEditing}>
						<img src={pencil} alt="Edit" />
					</div>
				)}
			</div>
			{modal}
		</div>
	);
}

export default ReportTypeListItem;
