import React from 'react';
import useWindowSize from '../../customHooks/useWindowSize';
import Button from '../Button/Button';
import './RelatedLinks.css'
import { Link, useNavigate } from 'react-router-dom';

interface RelatedLinksProps {
  data: {
    relatedTitle: string
    relatedSections: Array<{
      IconUrl: string,
      title: string,
      description: string,
      buttonLink: string,
      route: string
    }>
  }
}

function DarkLinks(props: RelatedLinksProps) {
  const navigate = useNavigate();
    const {isMobile, isTablet} = useWindowSize();

	return (
    <div className='flex flex-col mt-5'>
      <div className='text-2xl font-bold mb-8'>
        {props.data.relatedTitle}
      </div>
      <div className='flex justify-between' style={{flexFlow: isMobile ? 'column' : 'row', gap: '24px'}}>
        {
          props.data.relatedSections.map((obj, i) => {
            return (
              <div className='flex-col basis-[310px]' key={i}>
                <div className='text-sm font-bold center w-32 mb-4'>
                  <img src={obj.IconUrl} width="80" height="80" alt="Web free icon" title={obj.buttonLink} />
                </div>
                <div className='text-base font-bold'>{obj.title}</div>
                <div className='text-sm mt-2 mb-4'>{obj.description}</div>
                <div className='no-underline'>
                <Link to={obj.route} className="no-underline">
                    <Button className="bg-dark-blue darkBlue text-sm" text={obj.buttonLink} onClick={() => navigate(obj.route)} />
                </Link>
                  
                </div>
              </div>
            )
          })
        }
      </div>
    </div>

  );
}

export default DarkLinks;
