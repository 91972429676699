
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Store, defaultState } from '../../Store';
import Button from '../../components/Button/Button';
import useWindowSize from '../../customHooks/useWindowSize';
import ImageImports from '../../utils/ImageImports';
import classes from './Documents.module.css';

const {gear} = ImageImports;

interface HeaderButtonsInterface {
    addButtonText: string;
    customClass: string;
    addButtonClass: string;
    manageButtonClass: string;
}

function HeaderButtons(props: HeaderButtonsInterface) {
  const navigate = useNavigate();
  const {isDesktop, isMobile, isTablet} = useWindowSize();
  const {pagePermissions, setDocument} = useContext(Store);
	const location = useLocation();
  const {
      header: {
        data: { user },
      },
    } = useContext(Store);

	return (
        <div className={`${classes.header_buttons} ${isMobile? classes.flex_column : classes.flex_row} ${!isDesktop? classes.isTabletOrMobile : ""}`}>
            <Button className={`whiteBtn manage-button mb-0 ${props.manageButtonClass}`} disabled={!((user?.mpInternal && user?.portalAdmin) || pagePermissions.canAdmin)} text={<div className={classes.manageButtonImage}><img src={gear} alt="Manage Document Groups" /> Manage Document Groups</div>} onClick={() => navigate('./manage-groups')} />
            <Button className={`darkBlue add-button mb-0 ${props.addButtonClass}`} disabled={!((user?.mpInternal && user?.portalAdmin) || pagePermissions.canAdmin)} text={props.addButtonText} onClick={() => { setDocument?.(defaultState.document); navigate(`${location.pathname}/add`); }} style={{order: (isMobile || isTablet) ? '-1' : 'unset'}} />
        </div>
    );
}

HeaderButtons.defaultProps = {
    addButtonText: "Add Document",
    customClass: '',
    addButtonClass: '',
    manageButtonClass: ''
};

export default HeaderButtons;