import axios from "axios";
import { UseQueryOptions, useQuery, useMutation } from "react-query";
import { apiGetAllGroupContact, apiGroupContact } from "../../../endpoints/endpoints";
import { queryKeys } from "../../../react-query/queryKeys";
import { ContactFormInInterface, CPApiResponse, deleteInterface, GroupContactData } from "../../../utils/interface";

interface GroupContactCrudMethods<A, B, D, E> {
  get: (id: number) => Promise<B>;
  getAll: () => Promise<A>;
  create: (formdata: E) => Promise<B>;
  update: (formdata: E) => Promise<B>;
  delete: (id: number) => Promise<D>;
}

export const GroupContactApi: GroupContactCrudMethods<
  CPApiResponse<GroupContactData[]>,
  CPApiResponse<GroupContactData>,
  deleteInterface,
  ContactFormInInterface
> = {
  get: async (id: number): Promise<CPApiResponse<GroupContactData>> => {
    const response = await axios({
      url: `${apiGroupContact}/${id}`,
      method: "GET",
    });
    return response.data;
  },
  getAll: async (): Promise<CPApiResponse<GroupContactData[]>> => {
    const response = await axios({
      url: `${apiGetAllGroupContact}`,
      method: "GET",
    });
    return response.data;
  },
  create: async (data: ContactFormInInterface): Promise<CPApiResponse<GroupContactData>> => {
    const response = await axios({
      url: `${apiGroupContact}`,
      method: "POST",
      data: data,
    });
    return response.data;
  },
  update: async (data: ContactFormInInterface): Promise<CPApiResponse<GroupContactData>> => {
    const response = await axios({
      url: `${apiGroupContact}/${data?.id}`,
      method: "PUT",
      data: data,
    });
    return response.data;
  },
  delete: async (id: number): Promise<deleteInterface> => {
    const response = await axios({
      url: `${apiGroupContact}/${id}`,
      method: "DELETE",
    });
    return response.data;
  },
};

export const useGetAll_GroupContacts = (options: Omit<UseQueryOptions<CPApiResponse<GroupContactData[]>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<GroupContactData[]>>(queryKeys.GroupContactApi, GroupContactApi.getAll, {
    ...options,
  });
};

export const useGet_GroupContact = (id: number, options: Omit<UseQueryOptions<CPApiResponse<GroupContactData>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<GroupContactData>>([queryKeys.GroupContactApi, id], () => GroupContactApi.get(id), {
    ...options,
  });
};


export const useCreate_GroupContacts = () => {
  return useMutation(GroupContactApi.create);
};

export const useUpdate_GroupContacts = () => {
  return useMutation(GroupContactApi.update);
};
export const useDelete_GroupContacts = () => {
  return useMutation(GroupContactApi.delete);
};
